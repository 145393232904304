import { Component, ViewChild } from '@angular/core';
import { AppConfiguration } from './app.configuration';
import {
  ToastController,
  LoadingController,
  Platform,
  NavController,
  IonMenu
} from '@ionic/angular';


import { CartProvider } from "./services/cart/cart.service";
import { ContextProvider } from './services/context/context';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild('sideMenu') sideMenu: IonMenu;
  sideMenuBackground = 'center url(\'./img/sideMenuBg.jpg\') no-repeat';
  hasCitySelection = AppConfiguration.hasCitySelection;
  accessabilityOptions: any = {};
  constructor(
    private toastCtrl: ToastController,
    public cartProvider: CartProvider,
    private contextProvider: ContextProvider,
    private platform: Platform
  ) {


  }

  async ngOnInit() {
    this.accessabilityOptions = this.contextProvider.accessability;
    await this.platform.ready();    
  }

  async presentToast(text) {
    const toast = await this.toastCtrl.create({
      message: text,
      duration: 3000,
    });
    toast.present();
  }
}
