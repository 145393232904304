

export const lang = {
    get: (string,parameters?) => {
        switch(string){
            case 'BONUS_USE': return `${parameters[0]}​​​ test`;
            case 'STOCK_ALERT': return `test ${parameters[0]} test`;
            case 'BOOKING_NO_AVAILABLE_TIMES_1': return `testest ${parameters[0]} test`;
            case 'BOOKING_SMS_TEXT': return `test ${parameters[0]} \n  test ${parameters[1]} test\n ${parameters[2]} test `;
            case 'OFFERS_ACTIVATED': return `test ${parameters[0]}`;
            case 'CART_ITEMS_REMOVE': return `test  ${parameters[0]}?`;
            case 'CART_ALLERGY_CONTACT': return `, test ${parameters[0]}-${parameters[1]}, test`;
            case 'EMAIL_CORRECT': return `test ${parameters[0]} test?`;

        }
    },

    CURRENT:'test',
    NEW_ORDER:'test',
    
    PUSH_TITLE: 'test',
    PUSH_ALERT_TXT: 'test',
    PUSH_ALERT_BTN: 'test',
    PUSH_INFO: 'test',
    PUSH_PERMISSION: 'test',
    PUSH_CHANGE_SELECTION: 'test',
    
    WELCOME: 'test',
    DONE: 'test',
    BACK: 'test',
    OPT_OUT:'test',
    CONFIRM: 'test',
    OK:'test',
    CANCEL: 'test',
    DATE_TIME: 'test',
    SENT: 'test',
    BOOK: 'test',
    SEARCH: 'test',
    ERROR: 'test',
    YES:'test',
    NO:'test',

    CARD_NO:'test',
    ADD:'test',

    NAME:'test',
    PHONE:'test',

    WRITE_TABLENUMBER:'test',
    TABLENUMBER: 'test',

    TIPS:'test',
    PAY: 'test',
    PAYED: 'test',
    SUM:'test',

    CLOSED:'test',

    ACTIVATE:'test',

    DISCOUNT:'test',

    ORDER:'test',

    FORWARD:'test',

    /* 3 letter */
    SUN:'test',
    MON:'test',
    TUE:'test',
    WED:'test',
    THU:'test',
    FRI:'test',
    SAT:'test',
    TODAY:'test',

    /*Table booking*/
    BOOKING_THANKS_1:'test',
    BOOKING_THANKS_2:'test',
    MY_BOOKING: 'test',
    BOOKING_QUESTIONS: 'test',
    BOOKING_CONTACT_US_1: 'test',
    BOOKING_CONTACT_US_2: 'test',
    BOOKING_CONFIRM: 'test',
    BOOKING_APPCODE: 'test',
    BOOKING_ICONS: 'test',
    BOOKING_TITE: 'test',
    BOOKING_NUMBER_GUESTS: 'test',
    BOOKING_LARGER_GROUPS: 'test',
    BOOKING_LARGER_GROUPS_CONTACT: 'test',
    BOOKING_NO_AVAILABLE_TIMES_1: 'test',
    BOOKING_ALLOW_SMS: 'test',
    BOOKING_CANCEL: 'test',
    BOOKING_CONFIRM_CANCEL: 'test',
    BOOKING_CANCELED: 'test',

    STOCK_TITLE: 'test',
    BONUS_POINTS: 'test',
    
    PAYMENT_TITLE: 'test',
    PAYMENT_DELIVERY: 'test',
    PAYMENT_DISCOUNT: 'test',
    PAYMENT_BONUS: 'test',
    PAYMENT_FIRST_DISCOUNT: 'test',
    PAYMENT_EXTRA: 'test',
    PAYMENT_TOTAL: 'test',
    PAYMENT_PAYMENTS: 'test',
    PAYMENT_TABLETAB: 'test',
    PAYMENT_SWISH: 'test',
    PAYMENT_APPLEPAY:'test',
    PAYMENT_SAVED_CARDS:'test',
    PAYMENT_ADD_CARD:'test',
    PAYMENT_SCROLL:'test',
    PAYMENT_PAY:'test',


    PAYMENT_OFFER_ERROR_HEADER: 'test',
    PAYMENT_OFFER_ERROR_TEXT:'test',
    PAYMENT_ERROR_MAINTENANCE:'test',
    PAYMENT_ERROR:'test',
    

    CHARGE_WAIT: 'test',
    CHARGE_TAB_TOAST:'test',
    CHARGE_SWISH_INIT: 'test',
    CHARGE_ERROR:'test',
    CHARGE_VERIFY:'test',

    ADD_CARD_ADDING:'test',
    ADD_CARD_NEW_CARD: 'test',

    ADD_CARD_ERROR_E1:'test',
    ADD_CARD_ERROR_E3:'test',
    ADD_CARD_ERROR_E4:'test',
    ADD_CARD_ERROR_E5:'test',
    ADD_CARD_ERROR_E6:'test',
    ADD_CARD_ERROR_E7:'test',
    ADD_CARD_ERROR_E8:'test',
    ADD_CARD_ERROR_E9:'test',
    ADD_CARD_ERROR_E10:'test',
    ADD_CARD_ERROR_E11:'test',
    ADD_CARD_ERROR_E12:'test',
    ADD_CARD_ERROR_E13:'test',
    ADD_CARD_ERROR_E14:'test',
    ADD_CARD_NICK_TITLE:'test',
    ADD_CARD_NICK_MSG:'test',
    ADD_CARD_NICK_PLACEHOLDER:'test',
    ADD_CARD_SECURITY_INFO:'test',
    
    OFFERS:'test',
    OFFERS_NONE:'test',


    MENU_DRINKS_CLOSED:'test',
    MENU_ORDER_FULL:'test',
    MENU_NOT_AVAILABLE:'test',
    MENU_NO_PRODUCTS:'test',

    ITEM_DESCRIPTION_OUT_OF_STOCK:'test',
    ITEM_DESCRIPTION_SCROLL:'test',
    COMMENT:'test',

    BAB_NAME:'test',
    BAB_ADD:'test',

    BAB_TYPE_SIZE:'test',
    BAB_DRESSING:'test',
    BAB_TOPPINGS:'test',
    BAB_SIDEORDER:'test',
    BAB_EXTRAS:'test',
    BAB_BURGER_NAME:'test',
    BAB_MANY:'test',

    CHOOSE_RESTAURANT: 'test',

    DELIVERY_MY_TABLE:'test',
    DELIVERY_TO_GO:'test',
    DELIVERY_FETCH_BAR:'test',
    DELIVERY_HOME_DELIVERY:'test',
    DELIVERY_OFFER_ERROR:'test',
    DELIVERY_ALCOHOL_TAKE_AWAY:'test',
    DELIVERY_ALCOHOL_DELIVERY:'test',
    DELIVERY_SERVING:'test',
    DELIVERY_QUESTION:'test',

    LOCATION_CHOSE:'test',
    LOCATION_ADRESS:'test',
    LOCATION_QUESTION:'test',
    LOCATION_FEE: 'test',
    LOCATION_COMMENT:'test',
    LOCATION_UNFORTUNATELY_OTHER_ADRESS:'test',
    LOCATION_OTHER_ADRESS:'test',

    CUSTOMER_TABS_ATTENTION_MSG:'test',
    CUSTOMER_TABS_PAY_IN_APP:'test',
    CUSTOMER_TABS_CALL_WAITER:'test',
    CUSTOMER_TABS_TIPS:'test',
    CUSTOMER_TABS_CUSTOM_AM1:'test',
    CUSTOMER_TABS_CUSTOM_AM2:'test',
    CUSTOMER_TAB:'test',

    CUSTOM_TICKET_VALID:'test',

    CLIENT_OPEN_HOURS:'test',

    CART_ITEMS_APPLYING_OFFER:'test',
    CART_ITEM_OFFER_NOT_VALID:'test',
    CART_ITEM_COMMENT_EXAMPLE: "T.ex 'test'",
    CART_ITEM_COMMENT:'test',
    CART_ITEM_REMOVE_CODE:'test',
    CART_ITEM_18Y:'test',
    CART_ITEM_ALCOHOL:'test',

    CART_NO_ACTIVE_TAB:'test',
    CART_GO_TO_MENY:'test',
    CART_PREPARNG_SWISH:'test',
    CART_PAYMENT_ABORTED:'test',
    CART_CREATING_ORDER:'test',
    CART_SOME_ERROR:'test',
    CART_CHECK_CARD:'test',
    CART_WEEK_MENU:'test',
    CART_ORDER_TAKEWAY:'test',
    CART_MY_BOOKING:'test',
    CART_BOOK_TABLE:'test',
    CART_ALLERGIC_CONTACT:'test',
    CART_PERSONNEL_CARD:'test',

    CART_WELCOME_READ:'test',
    CART_WELCOME_OPENING_HOURS:'test',

    CUSTOMER_TAB_KEY_KOD:'test',
    CUSTOMER_TAB_KEY_WRONG_CODE:'test',
    CUSTOMER_TAB_KEY_SAME_CODE:'test',
    CUSTOMER_CODE_TYPE_4_DIGITS:'test',
    
    ACTIVE_ORDER_ONGOING:'test',
    ACTIVE_ORDER_DEL_CUSTOMER:'test',
    ACTIVE_ORDER_WAITING:'test',
    ACTIVE_ORDER_TAKEAWAY:'test',
    ACTIVE_ORDER_TABLE:'test',
    ACTIVE_ORDER_READY:'test',
    ACTIVE_ORDER_FOOD_READY:'test',
    ACTIVE_ORDER_DRINK_READY:'test',
    ACTIVE_ORDER_TICKET:'test',
    ACTIVE_ORDER_SHOW_SCREEN:'test',
    ACTIVE_ORDER_HOME_DELIVERY_OTW:'test',
    ACTIVE_ORDER_TABLE_DELIVERY_OTW:'test',
    ACTIVE_ORDER_RECIEVED_Q:'test',
    ACTIVE_ORDER_CLOSE_WINDOW:"Detta fönster försvinner om du väljer 'test'",

    ACCOUNT_REMOVE_CARD:'test',
    ACCOUNT_REMOVE_CARD_2:'test',
    ACCOUNT:'test',
    LOGOUT:'test',
    CREATE_LOGIN:'test',
    ME:'test',
    BONUSPOINTS:'test',
    HANDLE_NOTIFICATIONS:'test',
    SAVED_CARDS:'test',
    MY_RECIEPTS:'test',
    RECIEPTS:'test',

    PURCHASE_HISTORY_FILL_MAIL:'test',
    PURCHASE_HISTORY_SENDING_RECIEPT:'test',
    PURCHASE_HISTORY_SENDING_SENT:'test',
    PURCHASE_HISTORY_MAIL_RECIEPT:'test',
    PURCHASE_HISTORY_BUY_AT: 'test',
    PURCHASE_HISTORY_PHONE: 'test',
    PURCHASE_HISTORY_VATID: 'test',
    DATE: 'test',
    PURCHASE_HISTORY_ORDER:'test',
    PURCHASE_HISTORY_DISCOUNT:'test',
    PURCHASE_HISTORY_TOTAL:'test',
    PURCHASE_HISTORY_SUM:'test',
    PURCHASE_HISTORY_COLLECTED_DISCOUNT:'test',
    PURCHASE_HISTORY_ROUNDING:'test',
    PURCHASE_HISTORY_CONTROL_UNIT:'test',
    PURCHASE_HISTORY_RECIEPT_NO:'test',
    PURCHASE_HISTORY_VAT:'test',
    PURCHASE_HISTORY_NETTO:'test',
    PURCHASE_HISTORY_Brutto:'test',

    MENU_NOT_UPDATED:'test',
    WEEK: 'test',

    LOGIN:'test',
    LOGIN_PW_6: 'test',
    LOGIN_EMAIL_FORMAT:'test',
    LOGIN_EMAIL_IN_USE:'test',
    LOGIN_NEW_USER:'test',
    LOGIN_FINALIZING:'test',
    LOGIN_CONTACTING_FACEBOOK:'test',
    LOGIN_PHONE:'test',
    LOGIN_APPLE:'test',
    LOGIN_FACEBOOK:'test',
    LOGIN_EMAIL:'test',
    LOGIN_TERMS:'test',
    LOGIN_NO_ACCOUNT_Q:'test',
    LOGIN_CREATE:'test',
    LOGIN_RESET:'test',
    LOGIN_NAME_NEEDED:'test',
    LOGIN_HI:'test',
    LOGGING_IN:'test',
    LOGIN_WAITING_CODE:'test',
    LOGIN_VERIFYI:'test',
    SEND_SMS_CODE:'test',
    LOGGING_IN_2:'test',
    LOGIN_WRITE_EMAIL:'test',
    EMAIL:'test',
    EMAIL_SENT:'test',
    EMAIL_FOLLOW_INSTRUCTIONS:'test',
    WEEKLY_LUNCH:'test',
}
