import { Injectable } from '@angular/core';
import { OrderProvider } from '../order/order';
import { ContextProvider } from '../context/context';

import { Option } from 'src/app/models';
import { CartItem } from 'src/app/cart-items/cart-items';
import { AnalyticsService } from '../analytics/analytics';
import { Guid } from 'guid-typescript';

@Injectable(
  { providedIn: 'root' }
)
export class CartProvider {
  cart = new Cart();
  public cartItemCountIndex = {};
  appInfo;
  fromTakeawayMenu = false;

  defaultDeliveryType = -2;

  public orderId: string = 'or_' + Guid.create().toString().replace('-', '');
  public idempotencyKey = Guid.create().toString().replace('-', '');

  constructor(
    public orderProvider: OrderProvider,
    public contextProvider: ContextProvider,
    private analyticsService: AnalyticsService
  ) {
    this.contextProvider.clientContext.appInfo.subscribe(appInfo => {
      this.appInfo = appInfo;
    });
  }

  setDeliveryType(deliveryType) {
    if (deliveryType !== null && deliveryType !== undefined) {
      this.defaultDeliveryType = deliveryType;
    }
  }

  addItem(itemToAdd) {
    const item: any = {};
    for (const key in itemToAdd) {
      if (Object.prototype.hasOwnProperty.call(itemToAdd, key)) {
        const element = itemToAdd[key];
        item[key] = element;
      }
    }
    delete item.sdStyling;
    delete item.hdStyling;
    delete item.event;
    delete item.existsIn;
    const isFoodItem = this.isFoodItem(item);
    const isDrinkItem = this.isDrinkItem(item);
    if (this.cart.totalItems >= this.appInfo.MaxArticles) {
      return 'CART_FULL';
    }
    if (isFoodItem && !this.appInfo.Context.food.isOpen) {
      return 'FOOD_CLOSED';
    }
    if (isDrinkItem && !this.appInfo.Context.drinks.isOpen) {
      return 'DRINKS_CLOSED';
    }
    if (this.isAlcoholItem(item) && !this.canAddAlcoholItem()) {
      return 'TOO_MUCH_ALCOHOL';
    }

    const costOfItemOptions = this.costOfItemOptions(item);
    this.cart.sum += item.Cost + costOfItemOptions;
    this.cart.totalItems += 1;
    if (isFoodItem) {
      this.cart.sumFood += item.Cost;
      this.cart.sumFood += costOfItemOptions;
      this.cart.totalFoodItems += 1;

    } else if (isDrinkItem) {
      this.cart.sumDrinks += item.Cost;
      this.cart.sumDrinks += costOfItemOptions;
      this.cart.totalDrinkItems += 1;
    }
    const cartItem = this.findSameItemInCart(item);

    if (cartItem != null) {
      cartItem.NrOfItems += 1;
      this.addToItemCountIndex(cartItem);
      this.analyticsService.trackEvent('CART_UPDATE_ITEM', { item: cartItem });
      return cartItem.NrOfItems;
    }
    const newItem = JSON.parse(JSON.stringify(item));
    newItem.NrOfItems = 1;
    newItem.cartIndex = this.getNextCartIndex();
    this.cart.items.push(newItem);
    this.updateHasAlcohol();
    this.addToItemCountIndex(newItem);
    this.analyticsService.trackEvent('CART_ADD_ITEM', { item: newItem });

    return newItem.NrOfItems;
  }
  canAddAlcoholItem(nrToAdd?: number) {
    nrToAdd = typeof nrToAdd !== "undefined" ? nrToAdd : 1;
    let maxAlcoholItems = this.contextProvider.clientContext.appInfo.value.Context.maxAlcoholItemsPerOrder;
    if (typeof maxAlcoholItems !== "undefined") {
      let itemmmms = this.cart.items.filter(x => { return this.isAlcoholItem(x) });
      let currentNrOfAlcoholItemsInCart = itemmmms.map(x => x.NrOfItems).reduce((sum, a) => sum + a, 0);
      if (maxAlcoholItems < currentNrOfAlcoholItemsInCart + nrToAdd)
        return false;
    }
    return true;
  }
  updateOrderId(): string {
    this.orderId = 'or_' + Guid.create().toString().replace('-', '');

    return this.orderId;
  }

  updateIdempotencyKey(): string {
    this.idempotencyKey = Guid.create().toString().replace('-', '');

    return this.idempotencyKey;
  }

  addToItemCountIndex(newItem) {
    if (this.cartItemCountIndex[newItem.Name]) {
      this.cartItemCountIndex[newItem.Name] += 1;
    } else {
      this.cartItemCountIndex[newItem.Name] = 1;
    }
  }
  removeFromItemCountIndex(newItem) {
    if (this.cartItemCountIndex[newItem.Name]) {
      this.cartItemCountIndex[newItem.Name] -= 1;
    }

  }
  getNextCartIndex() {
    return this.cart.items
      .map(x => x.cartIndex)
      .reduce((a, b) => Math.max(a, b), 0) + 1;
  }
  costOfItemOptions(item): number {
    if (!item.options) {
      return 0;
    }
    const selectedBoolOptions = item.options.filter(x => x.selected === true).reduce((a, b) => {
      return a + b.cost;
    }, 0);
    const selectedMultipleOptions = item.options.filter(x => {
      if (x.selected && x.selected.cost) {
        return true;
      }
      return false;
    }).reduce((a, b) => {
      return a + b.selected.cost;
    }, 0);

    return selectedBoolOptions + selectedMultipleOptions;
  }

  updateHasAlcohol() {
    this.cart.hasAlcohol = this.cart.items.some(this.isAlcoholItem);
  }

  isAlcoholItem(item) {

    const categoryId = item.Category && item.Category[0] ? item.Category[0] as number : null;
    if (!categoryId) {
      return item.type.containsAlcohol;
    }
    return categoryId > 1 && categoryId < 7;
  }
  private isFoodItem(item) {
    return (item.Category && item.Category.startsWith('0')) || (item.type && item.type.productTypeId === 1);
  }
  private isDrinkItem(item) {
    return (item.Category && !item.Category.startsWith('0')) || (item.type && item.type.productTypeId === 2);
  }
  findSameItemInCart(newItem: CartItem) {
    return this.cart.items.find(item => {
      const itemInfoSame = newItem.Name === item.Name && newItem.Cost === item.Cost;
      const newItemOptions = newItem.options != null ? newItem.options.filter(x => x.selected) : [];
      const itemOptions = item.options != null ? item.options.filter(x => x.selected) : [];
      const optionsSame = newItemOptions.length === itemOptions.length && newItemOptions.every(newItemOption => {
        return itemOptions.some(itemOption => {
          return this.matchingOption(newItemOption, itemOption);
        });
      });
      const commentSame = item.Comment === newItem.Comment;
      return itemInfoSame && optionsSame && commentSame;
    });
  }
  private matchingOption(newItemOption: Option, itemOption: Option) {
    const optionInfoSame = newItemOption.text === itemOption.text &&
      newItemOption.type === itemOption.type &&
      newItemOption.cost === itemOption.cost;

    const specificOptionDataSame = itemOption.type === 'boolean' ?
      this.matchingBooleanOption(newItemOption.selected, itemOption.selected) :
      this.matchingMultipleOption(newItemOption.selected, itemOption.selected);

    return optionInfoSame && specificOptionDataSame;
  }
  private matchingBooleanOption(newOption: Option, oldOption: Option): boolean {
    return newOption === oldOption;
  }
  private matchingMultipleOption(newOption: Option, oldOption: Option): boolean {
    return newOption.text === oldOption.text &&
      newOption.cost === oldOption.cost;
  }
  generateOrderId() {
    return Math.random().toFixed(6).substring(2);
  }
  removeItem(item) {
    const isFoodItem = this.isFoodItem(item);
    const isDrinkItem = this.isDrinkItem(item);
    const costOfItemOptions = this.costOfItemOptions(item);
    this.cart.sum -= item.Cost.toFixed(2);
    this.cart.sum -= costOfItemOptions;
    this.cart.totalItems -= 1;
    if (isFoodItem) {
      this.cart.sumFood -= item.Cost;
      this.cart.sumFood -= costOfItemOptions;
      this.cart.totalFoodItems -= 1;

    } else if (isDrinkItem) {
      this.cart.sumDrinks -= item.Cost;
      this.cart.sumDrinks -= costOfItemOptions;
      this.cart.totalDrinkItems -= 1;
    }
    let deletedItems = 0;

    if (item.NrOfItems <= 1) {
      const index = this.cart.items.indexOf(item);
      this.cart.items.splice(index, 1);
    } else {
      item.NrOfItems -= 1;
      deletedItems = item.NrOfItems;
    }
    this.updateHasAlcohol();
    this.removeFromItemCountIndex(item);
    this.analyticsService.trackEvent('CART_REMOVE_ITEM', { item });
    return deletedItems;
  }
  removeFirstItemOfName(name: string) {
    const itemToRemove = this.cart.items.find((item) => {
      return item.Name === name;
    });
    if (itemToRemove) {
      this.removeItem(itemToRemove);
    }
  }
  empty() {
    this.cart.items.length = 0;
    this.cart.sum = 0;
    this.cart.sumFood = 0;
    this.cart.sumDrinks = 0;
    this.cart.totalItems = 0;
    this.cart.totalFoodItems = 0;
    this.cart.totalDrinkItems = 0;
    this.cartItemCountIndex = {};
  }
  copyCart() {
    const copiedArray = [];
    for (let i = 0; i < this.cart.items.length; i++) {
      const objToPush = Object.create(this.cart.items[i]);
      copiedArray.push(objToPush);
    }
    const order = {
      sum: this.cart.sum,
      sumFood: this.cart.sumFood,
      sumDrinks: this.cart.sumDrinks,
      totalItems: this.cart.totalItems,
      totalFoodItems: this.cart.totalFoodItems,
      totalDrinkItems: this.cart.totalDrinkItems,
      items: copiedArray,
      customerComment: this.cart.customerComment
    };
    return order;
  }
  getFoodItems(cartItems) {
    const foodItems = [];
    for (let i = 0; i < cartItems.length; i++) {
      if (this.isFoodItem(cartItems[i])) {
        foodItems.push(cartItems[i]);
      }
    }
    return foodItems;
  }
  getDrinkItems(cartItems) {
    const drinkItems = [];
    for (let i = 0; i < cartItems.length; i++) {

      if (this.isDrinkItem(cartItems[i])) {
        drinkItems.push(cartItems[i]);
      }

    }
    return drinkItems;

  }
}

export class Cart {
  items = [];
  sum = 0;
  sumFood = 0;
  sumDrinks = 0;
  totalFoodItems = 0;
  totalDrinkItems = 0;
  totalItems = 0;
  customerComment: string;
  hasAlcohol: boolean;
}
