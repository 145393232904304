import { Component, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import * as Reepay from 'reepay';
import {
  ModalController,
  AlertController,
  LoadingController,
  ToastController,
} from "@ionic/angular";
import { AddCreditcardOutput } from "src/app/models";
import { PaymentProvider } from "src/app/services/payment/payment";
import { AppConfiguration } from "src/app/app.configuration";
import { UserProvider, UserPaymentSource } from "src/app/services/user/user";
import { LanguagePipe } from 'src/app/pipes/language.pipe';
import { LanguageProvider } from 'src/app/services/language/language.provider';
import { EventsProvider } from 'src/app/services/events/events.provider';
import { ContextProvider } from 'src/app/services/context/context';
import { HttpClient } from "@angular/common/http";
import { ReepayEventData } from "../../services/payment/gateways/reepay.gateway";
@Component({
  selector: "add-card",
  styleUrls: ["addCard.component.scss"],
  templateUrl: "addCard.component.html",
})
export class AddCardComponent {
  @ViewChild("cc1") cc1;
  @ViewChild("cc2") cc2;
  @ViewChild("cc3") cc3;
  @ViewChild("cc4") cc4;
  @ViewChild("ccA") ccA;
  @ViewChild("ccMonth") ccMonth;
  @ViewChild("ccYear") ccYear;
  @ViewChild("ccCvc") ccCvc;
  @ViewChild("ccName") ccName;
  @ViewChild("ccSubmit") ccSubmit;
  @Input() sessionId: string = "";
  canNotDismiss = false;
  showForm: boolean = false;
  currency = "SEK";
  userId = "";
  routeName = "demorino";
  cardForm = new CreditCardForm();
  baseUrl = `https://us-central1-pq2-staging.cloudfunctions.net/payments/v1`;
  rp = null;
  constructor(
    private modalController: ModalController,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private paymentProvider: PaymentProvider,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe,
    private contextProvider: ContextProvider,
    private eventsProvider: EventsProvider,
    private http: HttpClient,
    private userProvider: UserProvider
  ) {

    // Hide internal form if not worldnet
    this.showForm = AppConfiguration.paymentProvider === "worldnet";
  }

  ngAfterViewInit() {
    //something steals focus after viewinit so timeout here
    setTimeout(() => {
      if (this.showForm) {
        this.ccA.setFocus();
      }
      else {
        // load reepay components
        this.showReepayEmbeddedComponent(this.sessionId);
      }
    }, 700);
  }

  async handleSubmission(state, component, url) {
    try {
      const res = await this.callServer(url, state.data);
      this.handleServerResponse(res, component);
    } catch (error) {
      console.error(error);
    }
  }

  async callServer(url, data) {
    const res = await this.http.post(url, data ? data : "", {
      headers: {
        "Content-Type": "application/json"
      }
    }).toPromise();

    return await res;
  }

  handleServerResponse(res, component) {
    if (res.action) {
      component.handleAction(res.action);
    } else {
      alert(JSON.stringify(res));
      // switch (res.resultCode) {
      //   case "Authorised":
      //     window.location.href = "/result/success";
      //     break;
      //   case "Pending":
      //   case "Received":
      //     window.location.href = "/result/pending";
      //     break;
      //   case "Refused":
      //     window.location.href = "/result/failed";
      //     break;
      //   default:
      //     window.location.href = "/result/error";
      //     break;
      // }
    }
  }

  select(event) {
    event.target.children[0].select();
  }

  dismiss() {
    if (this.rp)
      this.destroyReepayEmbeddedComponent();

    this.modalController.dismiss();
  }

  validateCreditCardNumberInput(value, nextInput) {
    this.cardForm.numberValid = false;
    try {
      let inputString: string = value.toString();
      if (inputString.length >= 22) {
        this[nextInput].setFocus();
      }
      let output = inputString.match(/\d{1,4}/g);
      var finalstr = output.join("  ");
      setTimeout(() => {
        this.cardForm.ccNumberFriendly = finalstr;
      }, 0);

      this.cardForm.ccNumber = output.join("");
      if (this.cardForm.ccNumber.length >= 16) this.cardForm.numberValid = true;
    } catch (e) {
      this.cardForm.ccNumber = "";
      this.cardForm.ccNumberFriendly = "";
    }
  }

  validateMonthYearInput(value, nextInput) {
    this.cardForm.expiryDateValid = false;
    const inputString = value.toString();
    if (inputString.length >= 2) {
      this[nextInput].setFocus();
    }
    if (nextInput === "ccCvc") {
      const expiryDate = this.cardForm.year + this.cardForm.month;
      if (expiryDate.length == 4) this.cardForm.expiryDateValid = true;
    }
  }
  validateCvcInput(value) {
    this.cardForm.cvcValid = false;
    const inputString = value.toString();
    if (inputString.length >= 3) {
      this.cardForm.cvcValid = true;
      this.ccName.setFocus();
    }
  }

  cardIcon() {
    if (
      this.cardForm.ccNumber.startsWith("5") ||
      this.cardForm.ccNumber.startsWith("2")
    ) {
      this.cardForm.brand = "mastercard";
      return "assets/icons/mastercard.svg";
    }
    if (this.cardForm.ccNumber.startsWith("4")) {
      this.cardForm.brand = "visa";
      return "assets/icons/visa.svg";
    }
    this.cardForm.brand = "unknown";
    return "assets/icons/genericcard.svg";
  }
  formValid() {
    return (
      this.cardForm.cvcValid &&
      this.cardForm.numberValid &&
      this.cardForm.expiryDateValid && this.ccName && this.ccName.el.value.length > 6
    );
  }
  ccNameKeyDown(ev) {
    if (ev.key === "Enter") {
      this.ccSubmit.el.focus();
    }
  }
  async submit() {
    this.outputData();
  }
  async outputData() {
    const cardOutput: AddCreditcardOutput = {
      number: this.cardForm.ccNumber,
      expMonth: this.cardForm.month,
      expYear: this.cardForm.year,
      cardHolderName: this.cardForm.cardHolderName,
      cvc: this.cardForm.cvc,
      saveCardForFutureUse: this.cardForm.saveCardForFutureUse,
      nickname: this.cardForm.nickname,
      type: "VISA"
    };
    if (AppConfiguration.paymentProvider === "worldnet") {
      try {
        let context = this.contextProvider.clientContext.paymentGateway.value;
        let result = <any>(
          await this.paymentProvider.createPaymentSourceXml(cardOutput, { mpi: context.mpi })
        );
        if (result.ERROR) {
          this.handleError(result.ERROR.ERRORCODE._text);
          this.eventsProvider.logEvent("ADD_CARD_FAILURE", { errorMsg: result.ERROR.ERRORCODE._text });
          return;
        }
        let paymentSource: UserPaymentSource = {
          type: this.cardForm.brand,
          nickname: `**** ${this.cardForm.ccNumber.substring(
            this.cardForm.ccNumber.length - 4
          )}`,
          recurringReference:
            result.SECURECARDREGISTRATIONRESPONSE.CARDREFERENCE._text,
        };
        this.eventsProvider.logEvent("ADD_CARD_SUCCESS", { paymentSource: paymentSource });
        let toast = await this.toastCtrl.create({
          color: "success",
          duration: 2000,
          position: "top",
          header: this.languagePipe.transform("Nytt kort", 'ADD_CARD_NEW_CARD', this.languageProvider.currentLanguage),
          message: `${paymentSource.type.toUpperCase()} ${paymentSource.nickname
            }`,
        });
        toast.present();
        this.modalController.dismiss(paymentSource, "confirm");
      } catch (error) {
        alert(JSON.stringify(error));
      } finally {
      }
    }
    // this.viewCtrl.dismiss(cardOutput);
  }
  handleError(errorCode) {
    let errorMsg = "";
    switch (errorCode) {
      case "E01":
        errorMsg = this.languagePipe.transform("Något gick fel. Prova igen!", 'ADD_CARD_ERROR_E1', this.languageProvider.currentLanguage);
        break;
      case "E03":
        errorMsg = this.languagePipe.transform("Restaurangen kan inte lägga till kort. Kontakta personal!", 'ADD_CARD_ERROR_E3', this.languageProvider.currentLanguage);
        break;
      case "E04":
        errorMsg = this.languagePipe.transform("Invalid reference details", 'ADD_CARD_ERROR_E4', this.languageProvider.currentLanguage);
        break;
      case "E05":
        errorMsg = this.languagePipe.transform("Fel korttyp!", 'ADD_CARD_ERROR_E5', this.languageProvider.currentLanguage);
        break;
      case "E06":
        errorMsg = this.languagePipe.transform("Fel terminal-id! Kontakta personalen", 'ADD_CARD_ERROR_E6', this.languageProvider.currentLanguage);
        break;
      case "E07":
        errorMsg = this.languagePipe.transform("Ej tillåtet! Kontakta personalen", 'ADD_CARD_ERROR_E7', this.languageProvider.currentLanguage);
        break;
      case "E08":
        errorMsg = this.languagePipe.transform("Fel merchant-id! Kontakta personalen", 'ADD_CARD_ERROR_E8', this.languageProvider.currentLanguage);
        break;
      case "E09":
        errorMsg = this.languagePipe.transform("Fel datumstämpel! Kontakta personalen", 'ADD_CARD_ERROR_E9', this.languageProvider.currentLanguage);
        break;
      case "E10":
        errorMsg = this.languagePipe.transform("Fel kortnummer. Dubbelkolla att du har slagit in rätt", 'ADD_CARD_ERROR_E10', this.languageProvider.currentLanguage);
        break;
      case "E11":
        errorMsg = this.languagePipe.transform("Fel månad/år. Dubbelkolla att du har slagit in rätt", 'ADD_CARD_ERROR_E11', this.languageProvider.currentLanguage);
        break;
      case "E12":
        errorMsg = this.languagePipe.transform("Fel namn på kort. Kontakta personalen", 'ADD_CARD_ERROR_E12', this.languageProvider.currentLanguage);
        break;
      case "E13":
        errorMsg = this.languagePipe.transform("Restaurangen har fel inställningar. Kontakta personalen", 'ADD_CARD_ERROR_E13', this.languageProvider.currentLanguage);
        break;
      case "E14":
        errorMsg = this.languagePipe.transform("Fel CVV-kod. Dubellkolla att du slagit in rätt", 'ADD_CARD_ERROR_E14', this.languageProvider.currentLanguage);
        break;
    }
    alert(errorMsg);
  }
  async promptForCardNickname() {
    let prompt = await this.alertCtrl.create({
      header: this.languagePipe.transform("Vad vill du kalla ditt kort?", 'ADD_CARD_NICK_TITLE', this.languageProvider.currentLanguage),
      message:
        this.languagePipe.transform("Kortet krypteras på ett säkert sätt och sparas för framtida användning", 'ADD_CARD_NICK_MSG', this.languageProvider.currentLanguage),
      inputs: [
        {
          name: "nickname",
          value: this.cardForm.nickname,
          placeholder: this.languagePipe.transform("Kortets smeknamn", 'ADD_CARD_NICK_PLACEHOLDER', this.languageProvider.currentLanguage),
        } as any,
      ],
      buttons: [
        {
          text: this.languagePipe.transform("Avbryt", 'CANCEL', this.languageProvider.currentLanguage),
          handler: (data) => {
            data.canceled = true;
            prompt.dismiss(data);
            return false;
          },
        },
        {
          text: this.languagePipe.transform("Ok", 'CONFIRM', this.languageProvider.currentLanguage),
          handler: (data) => {
            if (data.nickname.length <= 0) {
              this.cardForm.nickname =
                "**** " + this.cardForm.ccNumber.slice(-4);
              data.ok = true;
              prompt.dismiss(data);
              return false;
            }
            this.cardForm.nickname = data.nickname;
            data.ok = true;
            prompt.dismiss(data);
            return false;
          },
          cssClass: "alert-button-default",
        },
      ],
      backdropDismiss: false,
    });
    prompt.present();
    let result = await prompt.onDidDismiss();

    // .onDidDismiss(); reason => {
    //     if (reason.canceled)
    //         return false;
    //     else
    //         this.outputData();
    // });
    return prompt;
  }

  /************************ Reepay *************************** */
  ///
  /// Show Reepay Embedded component 
  ///
  showReepayEmbeddedComponent(sessionId: string) {
    try {
      this.rp = new Reepay.EmbeddedCheckout(sessionId, { html_element: 'rp_container' });
      // Add component events
      this.rp.addEventHandler(Reepay.Event.Accept, (data) => this.HandleReepaySuccesEvent(data, this));
      this.rp.addEventHandler(Reepay.Event.Error, (data) => this.HandleReepayFailureEvent(data, this));
      this.rp.addEventHandler(Reepay.Event.Close, (data) => this.HandleReepayCloseEvent(data, this));
      //alert("Rendered reepay");
    } catch (error) {
      alert(JSON.stringify(error));
    }

  }

  destroyReepayEmbeddedComponent() {
    this.rp.removeEventHandler(Reepay.Event.Accept);
    this.rp.removeEventHandler(Reepay.Event.Error);
    this.rp.removeEventHandler(Reepay.Event.Close);
    this.rp.destroy();
  }

  // Accept: fires once the transaction is successfully completed.
  HandleReepaySuccesEvent(data: ReepayEventData, context: AddCardComponent) {
    context.eventsProvider.logEvent("ADD_CARD_SUCCESS", { data: data });

    // Remove possibility to dismiss modal, wait for automatic closing
    this.canNotDismiss = true;

    // Wait 2 sek befor closeing to show success message
    setTimeout(() => {
      this.destroyReepayEmbeddedComponent();
      this.modalController.dismiss(data, "confirm");
    }, 2000);
  }

  // Error: whenever an error has occurred in attempting to finalise the transaction.
  HandleReepayFailureEvent(data: ReepayEventData, context: AddCardComponent) {
    context.eventsProvider.logEvent("ADD_CARD_FAILURE", { data: data });
    console.log('Reepay Component -> Failure');
    console.dir(data);
  }

  // Close: fires once the embedded window is closed. This event is independent of the 
  // previous, as none of the above events automatically close the window  
  // - it is only ever done by calling the destroy function seen below.
  HandleReepayCloseEvent(data: ReepayEventData, context: AddCardComponent) {
    context.eventsProvider.logEvent("ADD_CARD_CLOSE", { data: data });
    console.log('Reepay Component - Close');
    console.dir(data);
  }
}

class CreditCardForm {
  ccNumberFriendly = "";
  ccNumber = "";
  month = "";
  year = "";
  cvc = "";
  cardHolderName = "";
  numberTouched = false;
  numberValid = false;
  cvcTouched = false;
  cvcValid = false;
  expiryDateTouched = false;
  expiryDateValid = true;
  brand = "Unknown";
  nickname = "";
  saveCardForFutureUse = true;
}
