import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ContextProvider, DeliveryOption } from '../context/context';
import { AlertController, ModalController } from '@ionic/angular';
import { LanguageProvider } from '../language/language.provider';
import { LanguagePipe } from 'src/app/pipes/language.pipe';
import { OrderProvider } from '../order/order';
import { ReplaySubject, Subscription } from 'rxjs';
import { CartProvider } from '../cart/cart.service';
import { DeliverToLocationComponent } from 'src/app/delivery-place/deliver-to-location/deliverToLocation.component';

/*
  Generated class for the DeliveryPlaceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable(
  { providedIn: 'root' }
)
export class DeliveryPlaceProvider {
  tableOption: DeliveryOption;
  choseVariant = '';
  image = '';
  showAllTypes: boolean = false;
  showTakeAway = true;
  showEat = true;
  showTableServe = true;
  showDelivery = true;
  showMap = false;
  showTableNumber = false;
  showNameBlock = true;

  sitAtRestaurantClicked = false;
  sitAtRestaurantEnabled = false;
  stockBalanceEnabled = false;
  takeawayEnabled = false;
  deliveryEnabled = true;
  tablesEnabled = false;
  takeawayAndOrderByTabKeyEnabled = false;

  noPromtForTableNumber = false;

  selectedTableNumber: number = null;
  tableNumberObservable: ReplaySubject<number>;
  tableBookingEnabled = false;

  incorrectTableNumber = false;

  tableNumber = -2;
  preDefinedTableNumber: string = null;

  contextSub: Subscription;

  userPhone = '';

  takeAwayImage = 'assets/icons/mmm/take_away.svg';
  eatInImage = 'assets/icons/mmm/eatin.svg';
  tableServeImage = 'assets/icons/mmm/tableserve.svg';
  deliveryImage = 'assets/icons/delivery.svg';

  sitAtRestaurantIcon = 'walk';
  takeawayText = 'Ta med';
  sitAtRestaurantText = 'Hämta i baren';
  tablesText = 'Servera till mitt bord';
  deliveryText = 'Utkörning';
  tablesIcon = 'golf';
  takeawayIcon = 'basket';

  modalCloseDelayTime = 500;

  appInfo;

  constructor(public http: HttpClient,
    public alertCtrl: AlertController,
    private languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe,
    private orderProvider: OrderProvider,
    private contextProvider: ContextProvider,
    private cartProvider: CartProvider,
    private modalCtrl: ModalController) {
    this.takeawayText = this.languagePipe.transform('Ta med', 'DELIVERY_TO_GO', this.languageProvider.currentLanguage);
    this.sitAtRestaurantText = this.languagePipe.transform('Hämta i baren', 'DELIVERY_FETCH_BAR', this.languageProvider.currentLanguage);
    this.tablesText = this.languagePipe.transform('Servera till mitt bord', 'DELIVERY_MY_TABLE', this.languageProvider.currentLanguage);
    this.deliveryText = this.languagePipe.transform('Utkörning', 'DELIVERY_HOME_DELIVERY', this.languageProvider.currentLanguage);

    this.contextSub = this.contextProvider.clientContext.appInfo.subscribe((x) => {
      if (!x) { return; }

      this.appInfo = x;
      const appInfo = this.appInfo;

      this.tablesEnabled = appInfo.Context.Tables.enabled;
      this.tablesIcon = appInfo.Context.Tables.icon
        ? appInfo.Context.Tables.icon
        : this.tablesIcon;
      this.tablesText = appInfo.Context.Tables.text
        ? appInfo.Context.Tables.text
        : this.tablesText;
      this.takeawayEnabled = appInfo.Context.takeawayDelivery.enabled;
      this.takeawayIcon = appInfo.Context.takeawayDelivery.icon
        ? appInfo.Context.takeawayDelivery.icon
        : this.takeawayIcon;
      this.takeawayText = appInfo.Context.takeawayDelivery.text
        ? appInfo.Context.takeawayDelivery.text
        : this.takeawayText;
      this.sitAtRestaurantEnabled =
        appInfo.Context.sitAtRestaurantDelivery.enabled;
      this.sitAtRestaurantIcon = appInfo.Context.sitAtRestaurantDelivery.icon
        ? appInfo.Context.sitAtRestaurantDelivery.icon
        : this.sitAtRestaurantIcon;
      this.sitAtRestaurantText = appInfo.Context.sitAtRestaurantDelivery.text
        ? appInfo.Context.sitAtRestaurantDelivery.text
        : this.sitAtRestaurantText;
      this.deliveryEnabled =
        appInfo.Context.homeDelivery && appInfo.Context.homeDelivery.enabled;
      this.deliveryText = appInfo.Context.homeDelivery && appInfo.Context.homeDelivery.text
        ? appInfo.Context.homeDelivery.text
        : this.deliveryText;

      this.tableOption = {
        fee: 0,
        minValue: appInfo.Context.Tables.LowerValue.toString(),
        maxValue: appInfo.Context.Tables.UpperValue.toString(),
        name: 'Tables',
      };

      this.tableBookingEnabled =
        x.Context.tableBooking && x.Context.tableBooking.enabled;
      this.takeawayAndOrderByTabKeyEnabled =
        x.Context.orderByCustomerTabKey && x.Context.takeawayDelivery.enabled;
    });
  }

  async promptForTableNumber() {
    const numberText = this.appInfo.Context.Tables && this.appInfo.Context.Tables.numberText ? this.appInfo.Context.Tables.numberText : null;
    console.log(numberText)
    const alert = await this.alertCtrl.create({
      header: numberText ? 'Skriv in ditt ' + (numberText ? numberText.toLowerCase() : '') : this.languagePipe.transform(
        'Skriv in ditt bordsnummer',
        'WRITE_TABLENUMBER', this.languageProvider.currentLanguage) + ' (' +
        this.tableOption.minValue + '-' + this.tableOption.maxValue + ')'
      ,
      inputs: [
        {
          name: 'tableNumber',
          placeholder: numberText ? numberText : this.languagePipe.transform('Bordsnummer', 'TABLENUMBER', this.languageProvider.currentLanguage),
          type: 'tel'
        }
      ],
      buttons: [
        {
          text: this.languagePipe.transform('Avbryt', 'CANCEL', this.languageProvider.currentLanguage),
          role: 'cancel',
          handler: data => {
            this.selectedTableNumber = null;
            this.choseVariant = '';
          }
        },
        {
          role: 'confirm',
          text: this.languagePipe.transform('Ok', 'CONFIRM', this.languageProvider.currentLanguage),
          handler: data => {
            if (parseInt(data.tableNumber) >= parseInt(this.tableOption.minValue) && parseInt(data.tableNumber) <= parseInt(this.tableOption.maxValue)) {

              this.tableNumber = +data.tableNumber;
              this.orderProvider.orderWaitingForApproval.tableNumber = +data.tableNumber;
              return true;
            } else {
              return false;
            }
          }
        }
      ]
    });
    alert.present();

    return alert;
  }

  ngOnDestroy() {
    if (this.contextSub) this.contextSub.unsubscribe();
  }
  async setOneDeliveryOptionIfOnlyOneActivated() {
    let nrOfOptionsActivated = 0;
    let chosenOption;
    if (this.takeawayEnabled) {
      nrOfOptionsActivated++;
      chosenOption = this.takeawayText;
    }
    if (this.sitAtRestaurantEnabled) {
      nrOfOptionsActivated++;
      chosenOption = this.sitAtRestaurantText;
    }
    if (this.tablesEnabled) {
      nrOfOptionsActivated++;
      chosenOption = this.tablesText;
    }
    nrOfOptionsActivated === 1 && await this.checkWhatSelect(chosenOption);
    return nrOfOptionsActivated === 1;

  }
  async homeDelivery() {
    if (this.cartProvider.cart.hasAlcohol) {
      alert(this.languagePipe.transform("Du kan inte beställa hem alkohol...", 'DELIVERY_ALCOHOL_DELIVERY', this.languageProvider.currentLanguage));
      return
    }
    this.orderProvider.orderWaitingForApproval.tableNumber = -2;
    this.tableNumber = -2;
    if (this.orderProvider.orderWaitingForApproval.coupon === null) {
      this.orderProvider.orderWaitingForApproval.coupon = {
        code: '',
        discountPercent: 0
      };
    }
    try {
      const couponValid = await this.validateCouponAndDeliveryPlace(
        this.orderProvider.orderWaitingForApproval.coupon,
        this.tableNumber
      );
    } catch (error) {
      alert(this.languagePipe.transform(
        'Rabattkoden är inte giltig för detta serveringssätt',
        'DELIVERY_OFFER_ERROR',
        this.languageProvider.currentLanguage));
      return;
    }

    await this.showMapDialog();
  }

  async showMapDialog() {
    const modal = await this.modalCtrl.create({
      component: DeliverToLocationComponent,
      cssClass: 'modal-drawer',
      animated: true,
      backdropDismiss: true,
      // componentProps: { user: this.user }
    });
    modal.present();

    const result = await modal.onDidDismiss();
    if (result.role === 'confirm') {

      this.orderProvider.orderWaitingForApproval.deliveryComment = result.data.deliveryComment;
      this.orderProvider.orderWaitingForApproval.deliveryRadius = result.data.deliveryRadius;
      this.orderProvider.orderWaitingForApproval.deliveryLat = result.data.deliveryLat;
      this.orderProvider.orderWaitingForApproval.deliveryLong = result.data.deliveryLong;
      this.orderProvider.orderWaitingForApproval.deliveryFee = result.data.deliveryFee;
      this.orderProvider.orderWaitingForApproval.deliveryAddress = result.data.deliveryAddress;
    } else {
      this.orderProvider.orderWaitingForApproval.deliveryComment = null;
      this.orderProvider.orderWaitingForApproval.deliveryRadius = null;
      this.orderProvider.orderWaitingForApproval.deliveryLat = null;
      this.orderProvider.orderWaitingForApproval.deliveryLong = null;
      this.orderProvider.orderWaitingForApproval.deliveryFee = null;
      this.orderProvider.orderWaitingForApproval.deliveryAddress = null;
    }
  }

  validateCouponAndDeliveryPlace(coupon, tableNumber) {
    let numberToVerify = parseInt(tableNumber);
    if (tableNumber > 0) { numberToVerify = 1; }
    const promise = new Promise((resolve, reject) => {
      if (!coupon || !coupon.validFor) {
        resolve({ coupon, tableNumber });
        return;
      }
      if (coupon.validFor[numberToVerify]) {
        resolve({ coupon, tableNumber });
        return;
      } else { reject({ coupon, tableNumber }); }
      return;
    });
    return promise;
  }

  async checkWhatSelect(text: string, image?: string, noPromtForTableNumber?: boolean) {
    this.choseVariant = text;
    this.image = image;
    this.showAllTypes = !this.showAllTypes;
    this.showTakeAway = true;
    this.showEat = true;
    this.showTableServe = true;
    this.showDelivery = true;
    this.showMap = false;
    this.showTableNumber = false;
    this.userPhone = '';

    this.orderProvider.orderWaitingForApproval.deliveryComment = null;
    this.orderProvider.orderWaitingForApproval.deliveryRadius = null;
    this.orderProvider.orderWaitingForApproval.deliveryLat = null;
    this.orderProvider.orderWaitingForApproval.deliveryLong = null;
    this.orderProvider.orderWaitingForApproval.deliveryFee = null;
    this.orderProvider.orderWaitingForApproval.deliveryAddress = null;

    if (this.choseVariant === this.takeawayText) {
      this.showTakeAway = !this.showTakeAway;
      this.showNameBlock = true;
      this.takeawaySelected();
    } else if (this.choseVariant === this.sitAtRestaurantText) {
      this.showEat = !this.showEat;
      this.showNameBlock = true;
      this.sitAtRestaurant();
    } else if (this.choseVariant === this.tablesText) {
      this.showTableServe = !this.showTableServe;
      this.showNameBlock = true;
      if (!noPromtForTableNumber) {
        await this.promptForTableNumber();
      }
    } else if (this.choseVariant === this.deliveryText) {
      this.showNameBlock = false;
      this.showDelivery = !this.showDelivery;
      await this.homeDelivery();
    }
  }

  takeawaySelected() {
    this.tableNumber = 0;
    setTimeout(() => {
      if (this.cartProvider.cart.hasAlcohol) {
        alert(this.languagePipe.transform(
          'Du kan inte beställa alkohol som take away',
          'DELIVERY_ALCOHOL_TAKE_AWAY',
          this.languageProvider.currentLanguage)
        );
        return;
      }
    }, this.modalCloseDelayTime);

  }

  sitAtRestaurant() {
    setTimeout(() => {
      this.tableNumber = -1;
      this.sitAtRestaurantClicked = false;
    }, this.modalCloseDelayTime);
  }

  inputTableNumberValue(event) {
    console.log("NOT IMPLEMENTED!!!");
    // this.promptForTableNumber(event.target.value);
  }

  validateTable(): boolean {
    if (this.orderProvider.orderWaitingForApproval && this.orderProvider.orderWaitingForApproval.tableNumber && this.choseVariant === this.tablesText) {
      if (this.orderProvider.orderWaitingForApproval.tableNumber > 0)
        return true;
    }

    return false;
  }

  validateDelivery(): boolean {
    if (this.choseVariant === this.deliveryText) {
      if (this.orderProvider.orderWaitingForApproval && this.orderProvider.orderWaitingForApproval.deliveryLat && this.orderProvider.orderWaitingForApproval.deliveryLat && this.orderProvider.orderWaitingForApproval.deliveryFee && this.orderProvider.orderWaitingForApproval.deliveryAddress) {
        return true;
      }
    }

    return false;
  }
}
