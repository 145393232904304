import { Component } from "@angular/core";
import { OrderProvider, Order } from "../services/order/order";
import { PaymentProvider, PaymentV2Response, TransactionResult } from "../services/payment/payment";
import { SwishPaymentProvider } from "../services/payment/swishPaymentProvider";
import { ActivatedRoute } from "@angular/router";
import {
  NavController,
  IonRouterOutlet,
  ToastController,
  AlertController,
  ModalController
} from "@ionic/angular";
import { UserProvider, User, UserPaymentSource } from "../services/user/user";
import { CartProvider } from "../services/cart/cart.service";
import { ClientContextData, ContextProvider } from "../services/context/context";
import { Storage } from '@ionic/storage';
import { BonusProvider } from '../services/bonus/bonus';
import { StockModalComponent } from '../stock-balance/stock.component';
import { LanguageProvider } from '../services/language/language.provider';
import { LanguagePipe } from '../pipes/language.pipe';
import { AppConfiguration } from "../app.configuration";
import { EventsProvider } from "../services/events/events.provider";
import { PubqConfigProvider } from "../services/pubq-config/pubq-config";
import { ThemeProvider } from "../services/theme/theme-provider.service";
import { CardPaymentProvider } from "../services/payment/cardPaymentProvider";
import { CartItem } from "../cart-items/cart-items";
import { timeStamp } from "console";
let appSettings = require("../../../cordova-scripts/appsettings");

@Component({
  selector: "page-charge-customer",
  styleUrls: ["chargeCustomer.component.scss"],
  templateUrl: "chargeCustomer.component.html",
})
export class ChargeCustomerComponent {
  payingForOrder = false;
  hideSpinner = false;
  title = this.languagePipe.transform("Ett ögonblick...", 'CHARGE_WAIT', this.languageProvider.currentLanguage);
  subtitle = "";
  helpText = "";
  client?: string = null;
  city?: string = null;
  routeName?: string = null;
  qrCode: any;

  constructor(
    private orderProvider: OrderProvider,
    private toastController: ToastController,
    private contextProvider: ContextProvider,
    private routerOutlet: IonRouterOutlet,
    private cartProvider: CartProvider,
    private userProvider: UserProvider,
    private paymentProvider: PaymentProvider,
    private activatedRoute: ActivatedRoute,
    private navCtrl: NavController,
    private alertController: AlertController,
    private storage: Storage,
    private bonusProvider: BonusProvider,
    private modalController: ModalController,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe,
    private eventsProvider: EventsProvider,
    private pubqConfigProvider: PubqConfigProvider,
    public themeProvider: ThemeProvider,
    private swishPaymentProvider: SwishPaymentProvider,
    private cardPaymentProvider: CardPaymentProvider
  ) {
    this.client = this.activatedRoute.snapshot.params.client;
    this.city = this.activatedRoute.snapshot.params.city;
    this.routeName = this.activatedRoute.snapshot.params.routeName;
  }
  async ngOnInit() {
    this.routerOutlet.swipeGesture = false;
    this.payingForOrder = true;
    this.subtitle = "";
    this.helpText = "";
    this.qrCode = null;
    let user = this.userProvider.currentUser.value;

    /* If this is the loading page route to main */
    if (!this.contextProvider || !this.contextProvider.initDone)
      this.goToMenu();

    let paymentSource = this.orderProvider.paymentOfChoice;
    let params = this.activatedRoute.snapshot.queryParams;

    // if (params.action === "paytab") {
    //   try {
    //     this.orderProvider.tabOrder.userId = user.id;
    //     let result = await this.paymentProvider.payTab2(
    //       user,
    //       paymentSource,
    //       this.contextProvider.client.routeName,
    //       params.tabId,
    //       params.tipAmount,
    //       this.orderProvider.tabOrder
    //     );
    //     if (result.error) {
    //       this.showErrorPrompt(result.error.responseText);
    //       this.navCtrl.back();
    //       return;
    //     }
    //     this.orderProvider.tabItemsWaitingForApproval[params.orderId] = null;
    //     let toast = await this.toastController.create({
    //       message:
    //         this.languagePipe.transform("Din nota är betald och ett kvitto har skapats under ditt konto", 'CHARGE_WAIT', this.languageProvider.currentLanguage),
    //       duration: 3000,
    //       cssClass: "tab-paid-toast"
    //     });
    //     toast.present();
    //     this.hideSpinner = true;
    //     await toast.onDidDismiss();
    //   } catch (error) {
    //     this.navCtrl.back();
    //     alert(JSON.stringify(error));
    //   }

    //   this.home();
    //   return;
    // }
    console.log("--------------------------------")
    console.dir(appSettings && appSettings.version ? appSettings.version : 'N/A')
    console.log("--------------------------------")
    let order = this.cleanOrder(this.orderProvider.orderWaitingForApproval);
    let clientContextData: ClientContextData = {
      user: {
        id: user.id,
        isAnonymous: user.isAnonymous,
        firstName: user.firstName,
        lastName: user.lastName,
        deviceToken: user.deviceToken,
        email: user.email,
        phoneNumber: user.phoneNumber,
        preferredLocale: user.preferedLanguage ? user.preferedLanguage : AppConfiguration.defaultLanguage,
        origin: user.origin,
      },
      origin: AppConfiguration.userOrigin,
      locale: AppConfiguration.defaultLanguage,
      appVersion: appSettings && appSettings.version ? appSettings.version : 'N/A',
      appProtocol: `${window.location.origin}/u/${this.routeName}/cart?payment=stripe`
    }



    let cart = this.cartProvider.cart;

    switch (paymentSource.type) {
      case "customerTabKey":

      case "swish":
        await this.verifySwish({ user, paymentSource, order });
        //await this.chargeSwichV2({ user, paymentSource, order });

        break;
      case "swish_verify": {
        await this.chargeCachedSwishOrder()

        break;
      }
      /*
      >=>         >=> >=> >======>   >======>     >=>>=>   
       >=>       >=>  >=> >=>    >=> >=>    >=> >=>    >=> 
        >=>     >=>   >=> >=>    >=> >=>    >=>  >=>       
         >=>   >=>    >=> >======>   >======>      >=>     
          >=> >=>     >=> >=>        >=>              >=>  
           >===>      >=> >=>        >=>        >=>    >=> 
            >=>       >=> >=>        >=>          >=>>=>   
      */
      case "vipps": {
        try {
          this.title = this.languagePipe.transform("Frågar Vipps om betalning", 'CHARGE_VIPPS_INIT', this.languageProvider.currentLanguage);
          let result = await this.paymentProvider.chargeVippsCustomer(
            user,
            paymentSource,
            order,
            { client: this.client, city: this.city, routeName: this.routeName },
            cart
          );

          await this.storage.get("PUBQ_VIPPS").then((data) => {
            let vippsData = JSON.parse(data);
            if (vippsData && vippsData.paymentUrl) {
              // Re route to vips url
              console.log(vippsData.paymentUrl)
              document.location.href = vippsData.paymentUrl;
            } else {
              this.payingForOrder = false;
              this.backToCart();
            }
          });

        } catch (error) {
          // Update idempotency key
          this.cartProvider.updateIdempotencyKey();
          alert(
            this.languagePipe.transform("Något gick fel med ditt köp. Vänligen kontakta restaurangen", 'CHARGE_ERROR', this.languageProvider.currentLanguage)
          );
          this.payingForOrder = false;
          this.backToCart();
        }
        break;
      }
      case "vipps_verify": {
        this.title = this.languagePipe.transform("Verifierar och stämmer av", 'CHARGE_VERIFY', this.languageProvider.currentLanguage);

        let transactionResult: TransactionResult = await this.chargeCachedVippsOrder();
        if (transactionResult.error) {
          let modal = await this.modalController.create({
            component: StockModalComponent,
            componentProps: { routeName: this.contextProvider.client.routeName, data: transactionResult.missing, updatedCart: transactionResult.updatedCart },
            cssClass: 'stock-modal'
          });
          modal.present();
          await modal.onDidDismiss();
          this.backToCart();
        } else {

          this.title = "";
          this.subtitle = "";
          this.helpText = "";

          this.resetOrderFlow();
          this.home();
          this.payingForOrder = false;
        }

        break;
      }
      /*
        >=>          >>       >======>     >====>     
      >=>   >=>      >>=>      >=>    >=>   >=>   >=>  
     >=>            >> >=>     >=>    >=>   >=>    >=> 
     >=>           >=>  >=>    >> >==>      >=>    >=> 
     >=>          >=====>>=>   >=>  >=>     >=>    >=> 
      >=>   >=>  >=>      >=>  >=>    >=>   >=>   >=>  
        >===>   >=>        >=> >=>      >=> >====>     
      */
      case "stripe_verify": {
        await this.chargeCachedStripeOrder()
        break;
      }
      case "newcard": {
        this.startCheckoutSession({ paymentSource, order, clientContextData });
        break;
      }
      default: {
        try {
          this.storage.set("CART", order);
        } catch (error) {

        }
        if (AppConfiguration.paymentProvider === "stripe" || AppConfiguration.paymentProvider === "reepay") {
          this.chargeStoredCardAndHandlePotential3dsAuthRequest({ paymentSource, order, clientContextData });
        }
        // else if (AppConfiguration.paymentProvider === "reepay") {
        //   try {

        //     let result = await this.paymentProvider.chargeReepayCustomer(user, paymentSource, order, order.totalCost, this.orderProvider.reepayData);
        //     this.handleReepayChargeResponse(result, user);
        //   } catch (error) {

        //     // Update idempotency key
        //     this.cartProvider.updateIdempotencyKey();


        //     alert(
        //       this.languagePipe.transform("Något gick fel med ditt köp. Vänligen kontakta restaurangen", 'CHARGE_ERROR', this.languageProvider.currentLanguage)
        //     );

        //     this.back();
        //     this.payingForOrder = false;
        //   }
        // }
        if (AppConfiguration.paymentProvider === "worldnet") {

          console.error("WORLDNET NOT IMPLEMENTED")
          // this.paymentProvider
          //   .chargeRecurringCustomer(
          //     user,
          //     paymentSource,
          //     order.totalCost,
          //     order
          //   )
          //   .subscribe(
          //     async (x) => {
          //       try {
          //         console.log(x);
          //         if (x.error) {
          //           if (x.missing) {
          //             let modal = await this.modalController.create({
          //               component: StockModalComponent,
          //               componentProps: { routeName: this.contextProvider.client.routeName, data: x.missing, updatedCart: x.updatedCart },
          //               cssClass: 'stock-modal'
          //             });
          //             modal.present();
          //             await modal.onDidDismiss();
          //             this.backToCart();
          //           } else {
          //             this.showErrorPrompt(x.error.responseText);
          //             this.back();
          //             this.payingForOrder = false;
          //           }

          //         } else {
          //           this.resetOrderFlow();
          //           this.home();
          //           this.payingForOrder = false;
          //         }
          //       } catch (error) {
          //         this.backToCart();
          //       }


          //     },
          //     (error) => {
          //       alert(
          //         this.languagePipe.transform("Något gick fel med ditt köp. Vänligen kontakta restaurangen", 'CHARGE_ERROR', this.languageProvider.currentLanguage)
          //       );
          //       this.back();
          //       this.payingForOrder = false;
          //     }
          // );
        }
      }
    }
  }

  cleanOrder(order: any) {
    order.order.food = this.removeUnusedOptionsFromCart(order.order.food);
    order.order.drinks = this.removeUnusedOptionsFromCart(order.order.drinks);
    return order;
  }
  removeUnusedOptionsFromCart(cartContents: any[]) {
    for (let index = 0; index < cartContents.length; index++) {
      cartContents[index] = this.removeUnusedOptions(cartContents[index]);
    }
    return cartContents;
  }
  removeUnusedOptions(item: CartItem) {
    if (!item.options)
      return item;
    const filteredOptions = [];
    for (let index = 0; index < item.options.length; index++) {
      const option = item.options[index];
      if (option.choices)
        option.choices = null;
      if (option.linkedProduct && option.linkedProduct.options)
        option.linkedProduct.options = null;
      if (option.selected && option.selected.linkedProduct)
        option.selected.linkedProduct.options = null;
      if (option.selected)
        filteredOptions.push(option);
    }
    item.options = filteredOptions;
    return item;
  }

  async chargeCachedStripeOrder() {

    let body = await this.storage.get("PUBQ_STRIPE");
    if (body) {
      body = JSON.parse(body);
      console.log("Fetching stripe order data");
      if (this.paymentProvider.config) {
        console.log("API not ready!");
      }

      this.title = this.languagePipe.transform("Verifierar och stämmer av", 'CHARGE_VERIFY', this.languageProvider.currentLanguage);
      this.subtitle = '';
      this.helpText;

      // this.title = this.languagePipe.transform("Vi frågar Swish om din betalning", 'CHARGE_SWISH_INIT', this.languageProvider.currentLanguage);
      let response = await this.cardPaymentProvider.verify(body.idempotencyKey, body.routeName);

      // Handle response
      await this.handlePaymentResponse(response);

      // Removed cached swish order
      await this.storage.remove("PUBQ_STRIPE")
    } else {
      this.showErrorPrompt(this.languagePipe.transform('Stripe nekade betalningen.', 'CHARGE_ERROR', this.languageProvider.currentLanguage));
    }
  }

  async startCheckoutSession(options: { paymentSource: any, order: Order, clientContextData: ClientContextData }) {
    if (AppConfiguration.paymentProvider === "stripe") {
      const idempotencyKey = this.cartProvider.idempotencyKey;
      const orderId = this.cartProvider.orderId;

      try {
        let response = await this.cardPaymentProvider.initiate(options, idempotencyKey, orderId);

        // If initiat is ok open payment window
        if (response && response.success) {

          // Save order to be opened when returning from callback
          const body = {
            cart: this.cartProvider.cart,
            user: this.userProvider.currentUser.value,
            paymentSource: options.paymentSource,
            order: options.order,
            idempotencyKey,
            routeName: options.order.client.routeName
          };

          // Save the current order to be used when returning from callback to the cart.
          await this.storage.set("PUBQ_STRIPE", JSON.stringify(body));

          let sessionUrl = response.providerData.url;
          window.open(sessionUrl, '_self');
        } else {
          // Handle response
          this.handlePaymentResponse(response);
        }
      } catch (error) {
        // Update idempotency key
        this.cartProvider.updateIdempotencyKey();
        this.backToCart();
        this.payingForOrder = false;
      }
      return;
    } else if (AppConfiguration.paymentProvider === "reepay") {
      const idempotencyKey = this.cartProvider.idempotencyKey;
      const orderId = this.cartProvider.orderId;

      try {

        let response = await this.cardPaymentProvider.verify(idempotencyKey, options.order.client.routeName)

        // Handle response
        this.handlePaymentResponse(response);

      } catch (error) {
        // Update idempotency key
        this.cartProvider.updateIdempotencyKey();
        this.backToCart();
        this.payingForOrder = false;
      }
      return;
    }
    alert("Något gick fel!");
    throw "No payment provider compatible with this flow";
  }

  async chargeStoredCardAndHandlePotential3dsAuthRequest(options: { paymentSource: any, order: Order, clientContextData: ClientContextData }) {
    if (AppConfiguration.paymentProvider === "stripe") {
      const idempotencyKey = this.cartProvider.idempotencyKey;
      const orderId = this.cartProvider.orderId;
      let browser;
      try {
        let response = await this.cardPaymentProvider.initiate(options, idempotencyKey, orderId);
        if (response && response.success) {
          if (response.providerData.next_action) {
            let nextAction = response.providerData.next_action;
            if (nextAction.type === "redirect_to_url") {

              let sessionUrl = nextAction[nextAction.type].url;
              // Save order to be opened when returning from callback
              const body = {
                cart: this.cartProvider.cart,
                user: this.userProvider.currentUser.value,
                paymentSource: options.paymentSource,
                order: options.order,
                idempotencyKey,
                routeName: options.order.client.routeName
              };

              // Save the current order to be used when returning from callback to the cart.
              await this.storage.set("PUBQ_STRIPE", JSON.stringify(body));

              window.open(sessionUrl, '_self');
            }
          } else {
            // Handle response
            this.handlePaymentResponse(response);
          }

          // The verify will be handled when the stripe page opens the web-order cart page 
          // response = await this.cardPaymentProvider.verify(idempotencyKey, options.order.client.routeName);

        } else {
          // Handle response
          this.handlePaymentResponse(response);
        }

      } catch (error) {
        // Update idempotency key
        this.cartProvider.updateIdempotencyKey();
        this.backToCart();
        this.payingForOrder = false;
      }
      return;
    } else if (AppConfiguration.paymentProvider === "reepay") {

      const idempotencyKey = this.cartProvider.idempotencyKey;
      const orderId = this.cartProvider.orderId;
      options.paymentSource.type === "reepay"

      try {
        let response = await this.cardPaymentProvider.initiate(options, idempotencyKey, orderId);

        console.dir(response)
        // Extract reepay error code
        let errorCode = ''
        if (response.error && response.error.responseText) {
          let parts = response.error.responseText.split(':')
          if (parts.length > 1) {
            errorCode = parts[1].trim();
          }
        }

        if (errorCode === "SCA_REQUIRED") {
          console.log("Card needs to run 3D Secure handling,");
          console.log("reverts to paymat and automatic init 3D Secure payment.");
          // Needs to go back to payment.ts page and run the payWithReepayCardOnFile funktion
          this.backForAutomaticCardOnFilePayment();
        } else {

          if (response.success && !response.error)
            response = await this.cardPaymentProvider.verify(idempotencyKey, options.order.client.routeName);

          // Handle response
          this.handlePaymentResponse(response);
        }
      } catch (err) {
        console.log(err);
        // Update idempotency key
        this.cartProvider.updateIdempotencyKey();
        this.backToCart();
        this.payingForOrder = false;
      }
      return;
    }
    alert("Något gick fel!");
    throw "No payment provider compatible with this flow";
  }

  async handleReepayChargeResponse(x: any, user: any) {
    try {
      if (x.error) {
        if (x.missing) {
          let modal = await this.modalController.create({
            component: StockModalComponent,
            componentProps: { routeName: this.contextProvider.client.routeName, data: x.missing, updatedCart: x.updatedCart },
            cssClass: 'stock-modal'
          });
          modal.present();
          await modal.onDidDismiss();
          this.backToCart();
        } else {
          // Get error code from responseText
          let errorCode = 'CHARGE_ERROR'
          if (x.error.responseText) {
            let parts = x.error.responseText.split(':')
            if (parts.length > 1) {
              errorCode = parts[1].trim();
            }
          }

          // Log event for credit card error  
          this.eventsProvider.logEvent("CREDIT_CARD_ERROR_" + errorCode, { user: user });

          if (errorCode === "SCA_REQUIRED") {
            console.log("Card needs to run 3D Secure handling,");
            console.log("reverts to paymat and automatic init 3D Secure payment.");
            // Needs to go back to payment.ts page and run the payWithReepayCardOnFile funktion
            this.backForAutomaticCardOnFilePayment();
          } else {
            this.showErrorPrompt(this.languagePipe.transform("Något gick fel med ditt köp. Vänligen kontakta restaurangen", errorCode, this.languageProvider.currentLanguage));
            this.back();
            this.payingForOrder = false;
          }
        }
      } else {

        this.title = "";
        this.subtitle = "";
        this.helpText = "";

        this.resetOrderFlow();
        this.home();
        this.payingForOrder = false;
      }
    } catch (error) {
      // Update idempotency key
      this.cartProvider.updateIdempotencyKey();
      this.backToCart();
    }
  }

  async handleChargeResponse(x: any) {
    try {
      if (x.error) {
        if (x.missing) {
          let modal = await this.modalController.create({
            component: StockModalComponent,
            componentProps: { routeName: this.contextProvider.client.routeName, data: x.missing, updatedCart: x.updatedCart },
            cssClass: 'stock-modal'
          });
          modal.present();
          await modal.onDidDismiss();
          this.backToCart();
        } else {
          this.showErrorPrompt(x.error.responseText);
          this.back();
          this.payingForOrder = false;
        }

      } else {

        this.title = "";
        this.subtitle = "";
        this.helpText = "";

        this.resetOrderFlow();
        this.home();
        this.payingForOrder = false;
      }
    } catch (error) {
      // Update idempotency key
      this.cartProvider.updateIdempotencyKey();
      this.backToCart();
    }

  }

  goToMenu() {
    this.navCtrl.navigateBack(`u/${this.routeName}`);
  }

  goToComplete() {
    this.navCtrl.navigateBack(`u/${this.routeName}/complete`);
  }

  async chargePossibleSwishCustomerCached() {
    try {
      let body = await this.storage.get("swish");
      body = JSON.parse(body);

      return await this.paymentProvider.chargeSwishCustomerCachedOrder(body);
    } catch (err) {
      // alert(JSON.stringify(err));
    }
  }

  async verifySwish(options: { user: User, paymentSource: any, order: Order }) {
    let canceledByUser: boolean = false;
    try {

      this.qrCode = null;
      const idempotencyKey = this.cartProvider.idempotencyKey;
      const orderId = this.cartProvider.orderId;

      // Initiate invoice payment
      this.title = this.languagePipe.transform("Vi behandlar din order, ett ögonblick...", 'VERIFY_SWISH_APP', this.languageProvider.currentLanguage);
      this.subtitle = '';//options.order.phoneNumber ? options.order.phoneNumber : "";
      this.helpText = this.languagePipe.transform("Vänligen vänta...", 'SWISH_PLEASE_WAIT', this.languageProvider.currentLanguage);
      let response = await this.swishPaymentProvider.verify(idempotencyKey, options.order.client.routeName);

      // Handle response
      if (response && response.success) {

        // Handle response
        this.handlePaymentResponse(response);
        return;

        // Swish verify failed will return to cart
      } else {

        // Send Swish payment error to slack
        if (response.error && response.error.showResponseText && response.error.languageCode) {
          try {
            let body = {
              user: options.user,
              appText: response.error.showResponseText ? response.error.responseText : this.languagePipe.transform(response.error.responseText, response.error.languageCode, this.languageProvider.currentLanguage),
              error: response.error && response.error.responseText ? response.error.responseText + '(' + response.error.languageCode + ')' : JSON.stringify(response.error),
              order: options.order,
              paymentReference: idempotencyKey
            };
            this.paymentProvider.sendPaymentErrorToSlack(body);
          } catch (err) {

          }
        }

        console.log(response);

        // Failed to make init
        this.cartProvider.updateIdempotencyKey();
        this.showErrorPromptWithHeader(
          this.languagePipe.transform('BETALNINGSFEL', 'PAYMENT_ERROR_HEADER', this.languageProvider.currentLanguage),
          response.error.showResponseText ? response.error.responseText : this.languagePipe.transform(response.error.responseText, response.error.languageCode, this.languageProvider.currentLanguage)
        );

        this.backToCart();
        this.payingForOrder = false;
      }
    } catch (error) {
      // Update idempotency key
      this.cartProvider.updateIdempotencyKey();

      // Check if user canceld the payment or if it´s a error
      // Only show error if it's not a user how has canceld
      if (!error.canceledByUser) {
        console.dir(error)
        this.showErrorPrompt(this.languagePipe.transform('Swish nekade betalningen.', 'CHARGE_SWISH_ERROR', this.languageProvider.currentLanguage));
      }

      this.backToCart();
      this.payingForOrder = false;
    }
  }

  async openSwishAppOnMobile(order: Order, paymentRequestToken: string, routeName: string) {

    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf("android") > -1; // android check
    const isIphone = ua.indexOf("iphone") > -1; // ios check

    let callbackUrl = `${window.location.origin}/u/${routeName}/cart?payment=swish`;
    let appUrl = `swish://paymentrequest?token=${paymentRequestToken}&callbackurl=${callbackUrl}`;

    if (isAndroid || isIphone) {
      setTimeout(function () {
        window.location.href = appUrl;

      }, 25);
    }
  }

  async handlePaymentResponse(x: PaymentV2Response) {
    try {
      console.log(x);
      if (x.error || x.success !== true) {
        if (x.missing) {
          let modal = await this.modalController.create({
            component: StockModalComponent,
            componentProps: { routeName: this.contextProvider.client.routeName, data: x.missing, updatedCart: x.updatedCart },
            cssClass: 'stock-modal'
          });
          modal.present();
          await modal.onDidDismiss();
          this.backToCart();
        } else {
          this.cartProvider.updateIdempotencyKey();
          this.showErrorPromptWithHeader(
            this.languagePipe.transform('BETALNINGSFEL', 'PAYMENT_ERROR_HEADER', this.languageProvider.currentLanguage),
            x.error.showResponseText ? x.error.responseText : this.languagePipe.transform(x.error.responseText, x.error.languageCode, this.languageProvider.currentLanguage)
          );
          if (x.error.resetOrder) {
            this.resetOrderFlow();
            this.goToMenu();
          } else {
            this.backToCart();
          }
          this.payingForOrder = false;
        }

      } else {
        this.resetOrderFlow();
        this.home();
        this.payingForOrder = false;
      }
    } catch (error) {
      this.cartProvider.updateIdempotencyKey();
      this.backToCart();
    }
  }

  async chargeCachedVippsOrder() {

    let body = await this.storage.get("PUBQ_VIPPS");
    body = JSON.parse(body);
    console.log("Fetching Vipps order data");
    if (this.paymentProvider.config) {
      console.log("API not ready!");
    }

    return await this.paymentProvider.chargeVippsCachedOrder(body);

  }

  async chargeCachedSwishOrder() {

    let body = await this.storage.get("PUBQ_SWISH");
    if (body) {
      body = JSON.parse(body);
      console.log("Fetching Swish order data");
      if (this.paymentProvider.config) {
        console.log("API not ready!");
      }

      this.title = this.languagePipe.transform("Verifierar och stämmer av", 'CHARGE_VERIFY', this.languageProvider.currentLanguage);
      this.subtitle = '';
      this.helpText;

      // this.title = this.languagePipe.transform("Vi frågar Swish om din betalning", 'CHARGE_SWISH_INIT', this.languageProvider.currentLanguage);
      let response = await this.swishPaymentProvider.verify(body.idempotencyKey, body.routeName);

      // Handle response
      await this.handlePaymentResponse(response);

      // Removed cached swish order
      await this.storage.remove("PUBQ_SWISH")
    } else {
      this.showErrorPrompt(this.languagePipe.transform('Swish nekade betalningen.', 'CHARGE_ERROR', this.languageProvider.currentLanguage));
    }
  }

  async chargeSwishCustomer(
    user: User,
    recurringDetailReference: UserPaymentSource,
    order: Order
  ) {
    const amount = order.totalCost;
    return this.paymentProvider.chargeSwishCustomer(
      user,
      recurringDetailReference,
      amount,
      order
    );
  }

  async showErrorPromptWithHeader(header, errorMsg): Promise<void> {
    return new Promise<void>(async (resolve) => {

      let prompt = await this.alertController.create({
        header: header,
        message: errorMsg,
        buttons: [
          {
            text: this.languagePipe.transform("OK", 'OK', this.languageProvider.currentLanguage),
            role: "confirm",
            handler: ((event) => {
              resolve();
            })
          },
        ],
      });

      prompt.present();
    });
  }

  async showErrorPrompt(errorMsg): Promise<void> {
    return new Promise<void>(async (resolve) => {

      let prompt = await this.alertController.create({
        header: errorMsg,
        buttons: [
          {
            text: this.languagePipe.transform("OK", 'OK', this.languageProvider.currentLanguage),
            role: "confirm",
            handler: ((event) => {
              resolve();
            })
          },
        ],
      });

      prompt.present();
    });
  }


  async showConfirmOrDismissPrompt(text, message): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {

      let prompt = await this.alertController.create({
        header: text,
        message: message,
        backdropDismiss: false,
        buttons: [
          {
            text: this.languagePipe.transform("OK", 'OK', this.languageProvider.currentLanguage),
            role: "confirm",
            handler: ((event) => {
              resolve(false);
            })
          },
          {
            text: this.languagePipe.transform("Avbryt", 'CANCEL', this.languageProvider.currentLanguage),
            role: "dismiss",
            handler: ((event) => {
              resolve(true);
            })
          },
        ],
      });

      prompt.present();
    });
  }

  async showConfirm(text, subHeader, message): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {

      let prompt = await this.alertController.create({
        header: text,
        subHeader: subHeader,
        message: message,
        backdropDismiss: false,
        buttons: [
          {
            text: this.languagePipe.transform("OK", 'OK', this.languageProvider.currentLanguage),
            role: "confirm",
            handler: ((event) => {
              resolve(false);
            })
          }
        ],
      });

      prompt.present();
    });
  }

  resetOrderFlow() {
    this.orderProvider.orderWaitingForApproval = null;
    this.orderProvider.paymentOfChoice = null;
    this.orderProvider.activatedOfferIds = [];
    this.cartProvider.empty();
  }
  home() {
    this.routerOutlet.swipeGesture = true;
    this.goToComplete();
    // this.navCtrl.navigateBack(`u/${this.routeName}`, {
    //     // relativeTo: this.activatedRoute,
    //     queryParams: {
    //         showActiveOrder: true
    //     }
    // });
  }
  back() {
    this.routerOutlet.swipeGesture = true;
    this.navCtrl.navigateBack([`/u/${this.routeName}/cart`], {
    });
  }
  backForAutomaticCardOnFilePayment() {
    this.routerOutlet.swipeGesture = true;
    this.navCtrl.navigateBack(`/u/${this.routeName}/cart`, {
      queryParams: { automaticCardOnFilePayment: true },
    });
  }
  backToCart() {
    this.routerOutlet.swipeGesture = true;
    this.navCtrl.navigateBack([`/u/${this.routeName}/cart`], {
    });
  }
}
