import { CartItem } from '../cart-items/cart-items';


export class ItemFunctions {
    static costOfItemOptions(item: CartItem): number {
        if (!item.options)
            return 0;
        const selectedBoolOptions = item.options.filter(x => x.selected === true).reduce((a, b) => {
            return a + b.cost;
        }, 0);
        const selectedMultipleOptions = item.options.filter(x => {
            if (x.selected && x.selected.cost)
                return true;
            return false;
        }).reduce((a, b) => {
            return a + b.selected.cost;
        }, 0);

        return selectedBoolOptions + selectedMultipleOptions;
    }
}