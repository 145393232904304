export const lang = {
    get: (string, parameters?) => {
        switch (string) {
            case 'BONUS_USE':
                return `Käytä tähän ostokseen ${parameters[0]}`
            case 'STOCK_ALERT':
                return `Valitettavasti ${parameters[0]}​​​ on loppu. Ostoskorisi on mukautettu käytettävissä olevaan, jotta voit jatkaa ostoksia.`
            case 'BOOKING_NO_AVAILABLE_TIMES_1':
                return `Valitettavasti ${parameters[0]} henkilölle ei ole käytettävissä aikaväliä. `
            case 'BOOKING_SMS_TEXT':
                return `Hei, tämä koskee varaustasi ${parameters[0]} \ n ${parameters[1]} \ n ${parameters[2]} henkilöä.`
            case 'OFFERS_ACTIVATED':
                return `${parameters[0]} aktivoitu`
            case 'CART_ITEMS_REMOVE':
                return `Haluatko poistaa ${parameters[0]}?`
            case 'CART_ALLERGY_CONTACT':
                return `, välillä ${parameters[0]}-${parameters[1]}, vastaamme mielellämme sisältöä koskeviin kysymyksiisi`
            case 'EMAIL_CORRECT':
                return `Onko ${parameters[0]} oikein?`
            case 'LOGIN_SEND_SMS':
                return `Lähetetäänkö teksti käyttäjälle ${parameters[0]}?`
            case 'LOGIN_ERROR':
                return `Oho! Jotain meni pieleen. Yritä kirjautua sisään muulla tavalla. \ N ${parameters[0]}`
            case 'DELIVERY_MIN_FEE':
                return `Valitettavasti tilauksesi ei saavuta toimituksen vähimmäisarvoa ${parameters[0]}. Voit joko maksaa erotuksen tai palata takaisin ja lisätä lisää tuotteita.`
            case 'PAYMENT_BONUS':
                return `Sinulla on ${parameters[0]} bonuspistettä`
            case 'BONUS_POINTS_RECEIVED':
                return `Täytettyäsi tilauksen saat ${parameters[0]} bonuspistettä`
            case 'OPEN_SWISH_APP_WITH_PHONENUMBER':
                return `Visas ingen betalning i Swish, kontrollera att det är rätt nummer (${parameters[0]}).`;
            case 'SINGLE_ORDER':
                return `Sinulla on ${parameters[0]} tilausta meneillään`;
            case 'MULTIPLE_ORDER':
                return `Sinulla on ${parameters[0]} tilausta meneillään`;
        }
    },

    ABOUT_US: 'Meistä',
    ACCOUNT: 'Tili',
    ACCOUNT_REMOVE_CARD: 'Poista kortti',
    ACCOUNT_REMOVE_CARD_2: 'Haluatko poistaa kortin?',
    ACTIVATE: 'Aktivoi',
    ACTIVE_ORDER_CLOSE_WINDOW: 'Tämä ikkuna poistuu kun valitset "kyllä"',
    ACTIVE_ORDER_DEL_CUSTOMER: 'Tilaus on mennyt keittiöön, saat ilmoituksen kun tilaus on valmis noudettavaksi',
    ACTIVE_ORDER_DRINK_READY: 'Juomasi on valmis',
    ACTIVE_ORDER_EATIN_COMPLETE: 'Näytä tämä näkymä kassalla',
    ACTIVE_ORDER_FOOD_READY: 'Ruokasi on valmis',
    ACTIVE_ORDER_HOME_DELIVERY_OTW: 'Tilauksesi on nyt toimitettu eteenpäin ravintolasta ja on matkalla luoksesi.',
    ACTIVE_ORDER_ONGOING: 'Käynnissä olevat tilaukset',
    ACTIVE_ORDER_READY: 'Tilauksesi on valmis',
    ACTIVE_ORDER_RECIEVED_Q: 'Oletko saanut tilauksesi?',
    ACTIVE_ORDER_SHOW_SCREEN: 'Näytä tämä näkymä kassalla',
    ACTIVE_ORDER_TABLE: 'Kiitos! Valmistamme nyt tilaustasi ja toimitamme sen sinulle niin nopeasti kuin mahdollista',
    ACTIVE_ORDER_TABLE_DELIVERY_OTW: 'Tilauksesi on toimitettu pöytääsi',
    ACTIVE_ORDER_TAKEAWAY: 'Keittiö on vastaanottanut tilauksesi ja ilmoittaa kun nouto on mahdollisimman pian mahdollista',
    ACTIVE_ORDER_TAKEAWAY_COMPLETE: 'Näytä tämä näkymä kassalla',
    ACTIVE_ORDER_TICKET: 'Tässä on tilauslippusi',
    ACTIVE_ORDER_WAITING: 'Kiitos! Odottaa hyväksyntää.',
    ADD: 'Lisää',
    ADD_CARD_ADDING: 'Lisää kortti',
    ADD_CARD_ERROR_E1: 'Jotain meni pieleen. Yritä uudelleen.',
    ADD_CARD_ERROR_E10: 'Virheellinen kortin numero. Tarkista että syötit tiedot oikein.',
    ADD_CARD_ERROR_E11: 'Väärä kuukausi/vuosi. Tarkista, että kirjoitit oikein.',
    ADD_CARD_ERROR_E12: 'Virheellinen nimi kortissa. Ota yhteyttä henkilökuntaan. ',
    ADD_CARD_ERROR_E13: 'Ravintolassa on väärät asetukset. Ota yhteyttä henkilökuntaan.',
    ADD_CARD_ERROR_E14: 'Väärä CVV -koodi. Tarkista, että kirjoitit oikein.',
    ADD_CARD_ERROR_E3: 'Ravintola ei voi lisätä korttia. Ota yhteyttä henkilökuntaan.',
    ADD_CARD_ERROR_E4: 'Virheelliset tiedot',
    ADD_CARD_ERROR_E5: 'Väärä korttityyppi',
    ADD_CARD_ERROR_E6: 'Virheellinen päätetunnus. Ota yhteyttä henkilökuntaan.',
    ADD_CARD_ERROR_E7: 'Ei sallittu! Ota yhteyttä henkilökuntaan.',
    ADD_CARD_ERROR_E8: 'Virheellinen jälleenmyyjän tunnus. Ota yhteyttä henkilökuntaan',
    ADD_CARD_ERROR_E9: 'Väärä päivämäärä. Ota yhteyttä henkilökuntaan',
    ADD_CARD_NEW_CARD: 'Lisää uusi kortti',
    ADD_CARD_NICK_MSG: 'Kortti salataan ja tallennetaan turvallisesti tulevaa käyttöä varten',
    ADD_CARD_NICK_PLACEHOLDER: 'Kortin lempinimi',
    ADD_CARD_NICK_TITLE: 'Miten haluat nimetä korttisi',
    ADD_CARD_SECURITY_INFO: 'Käsittelemme korttitietosi turvallisesti. Kaikki arkaluonteiset tiedot tallennetaan valtuutetun maksupalveluntarjoajan luo.',
    BAB_ADD: 'Lisää',
    BAB_BURGER_NAME: 'Nimeä hampurilaisesi',
    BAB_DRESSING: 'Jälkiruoka',
    BAB_EXTRAS: 'Valitse ylimääräiset',
    BAB_MANY: 'Valitse montako haluat',
    BAB_NAME: 'Ruokalajin nimi',
    BAB_SIDEORDER: 'Valitse sivutilaus',
    BAB_TOPPINGS: 'Täytteet',
    BAB_TYPE_SIZE: 'Valitse tyyppi ja koko',
    BACK: 'Takaisin',
    BONUS_POINTS: 'Bonus pisteet',
    BONUSPOINTS: 'Bonus pisteet',
    BOOK: 'Varaa',
    BOOKING_ALLOW_SMS: 'Lupa sallii sovelluksen avata esitäytetyn tekstiviestin ravintolaan',
    BOOKING_APPCODE: 'Koodisi on',
    BOOKING_CANCEL: 'Oletko varma, että haluat peruuttaa varauksesi',
    BOOKING_CANCELED: 'Varauksesi on peruttu',
    BOOKING_CONFIRM: 'Tilausvahvistus',
    BOOKING_CONFIRM_CANCEL: 'Vahvista peruutus',
    BOOKING_CONTACT_US_1: 'Ota meihin yhteyttä',
    BOOKING_CONTACT_US_2: 'tai klikkaa tästä lähettääksesi tekstiviestin',
    BOOKING_ICONS: 'Tilaa käyttämällä alla olevia kuvakkeita',
    BOOKING_LARGER_GROUPS: 'Varaus isommille ryhmille',
    BOOKING_LARGER_GROUPS_CONTACT: 'Jos haluat varata pöydän usemmalle henkilölle, ota yhteyttä',
    BOOKING_NO_AVAILABLE_TIMES_1: 'Ei vapaita aikoja',
    BOOKING_NUMBER_GUESTS: 'Henkilömäärä',
    BOOKING_QUESTIONS: 'Onko sinulla kysymyksiä liittyen varaukseen?',
    BOOKING_THANKS_1: 'Kiitos tilauksestasi!',
    BOOKING_THANKS_2: 'Nähdään pian uudelleen',
    BOOKING_TITE: 'Varaa pöytä',
    CANCEL: 'Peruuta',
    CANCEL_ORDER: 'Haluatko peruuttaa tilauksen ja tyhjentää ostoskorin?',
    CARD_NO: 'Kortin numero',
    CART_ALLERGIC_CONTACT: 'Allergioita? Ota yhteyttä henkilökuntaan.',
    CART_BOOK_TABLE: 'Varaa pöytä',
    CART_CHECK_CARD: "Varmista, että korttiasi voidaan käyttää verkkokauppoihin ja että käytettävissä on riittävä määrä rahaa'",
    CART_CREATING_ORDER: 'Luo tilauksia ja lisääminen',
    CART_GO_TO_MENY: 'Siirry valikkoon ja tee ensimmäinen tilauksesi',
    CART_ITEM_18Y: '18 vuotta täyttänyt ja voimassa oleva henkilöllisyystodistus vaaditaan alkoholia ostettaessa',
    CART_ITEM_ALCOHOL: 'Alkoholista ei alennuksia',
    CART_ITEM_COMMENT: 'Kirjoita kommenttisi tähän',
    CART_ITEM_COMMENT_EXAMPLE: 'Esimerkiksi "ei korianteria"',
    CART_ITEM_OFFER_NOT_VALID: 'Tarjouskoodi ei kelpaa',
    CART_ITEM_REMOVE_CODE: 'Poista koodi?',
    CART_ITEMS_APPLYING_OFFER: 'Tarjouskoodin käyttäminen',
    CART_MY_BOOKING: 'Varaukseni',
    CART_NO_ACTIVE_TAB: 'Sinulla ei ole aktiivista merkintää',
    CART_ORDER_TAKEWAY: 'Tilaa takeaway',
    CART_PAYMENT_ABORTED: 'Maksu keskeytettiin',
    CART_PERSONNEL_CARD: 'Henkilökortti',
    CART_PREPARNG_SWISH: "Swish -maksun valmistelu'",
    CART_SERVE_NOW: 'Tarjoile nyt',
    CART_SOME_ERROR: 'Jotain meni pieleen. Yritä uudelleen',
    CART_WEEK_MENU: 'Tämän viikon ruokalista',
    CART_WELCOME_OPENING_HOURS: 'Aukioloajat ja yhteystiedot',
    CART_WELCOME_READ: 'Lue lisää',
    CHARGE_ERROR: 'Jotain meni vikaan maksussasi. Ota yhteyttä ravintolaan. ',
    CHARGE_SWISH_INIT: 'Swish pyytää maksua',
    CHARGE_TAB_TOAST: 'Tilauksesi on maksettu ja kuitti on luotu tilillesi',
    CHARGE_VERIFY: 'Todentaminen',
    CHARGE_WAIT: 'Odota',
    CHOOSE_RESTAURANT: 'Valitse ravintola',
    CLIENT_OPEN_HOURS: 'Aukioloajat sovelluksessa',
    CLOSE: 'Sulje',
    CLOSED: 'Suljettu',
    COMMENT: 'Kommentti',
    CONFIRM: 'Vahvista',
    CREATE_LOGIN: 'Luo tili / Kirjaudu sisään',
    CURRENT: 'Ajankohtaista',
    CUSTOM_TICKET_VALID: 'Voimassa asti',
    CUSTOMER_CODE_TYPE_4_DIGITS: 'Kirjoita 4 numeroa',
    CUSTOMER_TAB: 'Välilehti',
    CUSTOMER_TAB_KEY_KOD: 'Pöytä koodi',
    CUSTOMER_TAB_KEY_SAME_CODE: 'Sama koodi jälleen. Klikkaa tästä. ',
    CUSTOMER_TAB_KEY_WRONG_CODE: 'Väärä pöydä koodi',
    CUSTOMER_TABS_ATTENTION_MSG: 'Tulemme pian ja autamme sinua',
    CUSTOMER_TABS_CALL_WAITER: 'Kutsu tarjoilija',
    CUSTOMER_TABS_CUSTOM_AM1: 'Valinnainen summa',
    CUSTOMER_TABS_CUSTOM_AM2: 'Valinnainen määrä',
    CUSTOMER_TABS_PAY_IN_APP: 'Maksa sovelluksessa',
    CUSTOMER_TABS_TIPS: 'Haluatko antaa tippiä henkilökunnallemme?',
    DATE: 'Päivä',
    DATE_TIME: 'Päivä ja aika',
    DELIVERY_ALCOHOL_DELIVERY: 'Et voi tilata alkoholia toimitettavaksi',
    DELIVERY_ALCOHOL_TAKE_AWAY: 'Alkoholia ei voi tilata mukaan otettavaksi',
    DELIVERY_FETCH_BAR: 'Nouda ravintolasta',
    DELIVERY_HOME_DELIVERY: 'Kotiinkuljetus',
    DELIVERY_INFORMATION: 'Toimitustiedot',
    DELIVERY_MY_TABLE: 'Tarjoile pöytääni',
    DELIVERY_OFFER_ERROR: 'Alennuskoodi ei kelpaa tämän tyyppiselle tarjoilulle',
    DELIVERY_QUESTION: 'Miten haluat saada tilauksesi?',
    DELIVERY_SERVING: 'Tarjoilu',
    DELIVERY_SET_LOCATIONS: 'Valitse toimipiste',
    DELIVERY_TO_GO: 'Take away',
    DISCOUNT: 'Alennus',
    DISCOUNT_CODE: 'Alennuskoodi',
    DONE: 'Tehty',
    EMAIL: 'Sähköposti',
    EMAIL_FOLLOW_INSTRUCTIONS: 'Avaa sähköpostisi ja seuraa ohjeita',
    EMAIL_SENT: 'Sähköposti lähetetty',
    ENTER_CARD_NUMBER_AT_START_OF_PAYMENT: 'Kortin numero täytetään maksun alkaessa.',
    ERROR: 'Virhe',
    FOLLOW: 'Seuraa meitä',
    FORWARD: 'Eteenpäin',
    FRI: 'Pe',
    FRIDAY: 'Perjantai',
    GO_TO_CART: 'Ostoskoriin',
    HANDLE_NOTIFICATIONS: 'Käsittele ilmoituksia',
    HOUR: 'Tunti',
    ITEM_DESCRIPTION_OUT_OF_STOCK: 'Loppunut varastosta',
    ITEM_DESCRIPTION_SCROLL: 'Selaa alas',
    LOCATION_ADRESS: 'Osoitteesi',
    LOCATION_CHOSE: 'Valitse sijainti',
    LOCATION_COMMENT: 'Kommentti (kerros, postinumero jne.)',
    LOCATION_FEE: 'Toimitus ilmainen',
    LOCATION_OTHER_ADRESS: 'Kokeile toista osoitetta',
    LOCATION_QUESTION: 'Toimitus tänne',
    LOCATION_UNFORTUNATELY_OTHER_ADRESS: 'Valitettavasti emme voi vielä toimittaa tähän osoitteeseen. Kokeile toista osoitetta',
    LOGGING_IN: 'Kirjautuu sisään',
    LOGGING_IN_2: 'Kirjautuu sisään',
    LOGIN: 'Kirjaudu sisään',
    LOGIN_APPLE: 'Kirjaudu Applen kautta',
    LOGIN_CONTACTING_FACEBOOK: 'Yhdistää Facebookiin',
    LOGIN_CREATE: 'Luo',
    LOGIN_EMAIL: 'Kirjaudu sähköpostiosoitteella',
    LOGIN_EMAIL_FORMAT: 'Sähköpostiosoitteesi on virheellinen. Tarkista että olet kirjoittanut osoitteesi oikein ja yritä uudelleen.',
    LOGIN_EMAIL_IN_USE: 'Tämä sähköpostiosoite on jo käytössä',
    LOGIN_EXISTING_USER: 'Onko sinulla jo tili?',
    LOGIN_FACEBOOK: 'Kirjaudu Facebookin kautta',
    LOGIN_FINALIZING: 'Viimeistely',
    LOGIN_HI: 'Hei',
    LOGIN_NAME_NEEDED: 'Nimeäsi tarvitaan vastaamaan tilauksiisi',
    LOGIN_NEW_USER: 'Uusi käyttäjä',
    LOGIN_NO_ACCOUNT_Q: 'Ei tiliä?',
    LOGIN_PHONE: 'Kirjaudu puhelinnumerolla',
    LOGIN_PW_6: 'Salasanassa on oltava vähintään 6 numeroa',
    LOGIN_RESET: 'Nollaa',
    LOGIN_TERMS: 'Käyttöehdot',
    LOGIN_VERIFYI: 'Todentaminen',
    LOGIN_WAITING_CODE: 'Odotetaan koodia…',
    LOGIN_WRITE_EMAIL: 'Kirjoita sähköpostiosoitteesi',
    LOGIN_FOR_QR: 'Sinun on kirjauduttava sisään tilillesi, jotta voit palauttaa uudelleenkäytettäviä pakkauksia',
    LOGOUT: 'Kirjaudu ulos',
    ME: 'Tilini',
    MENU_DRINKS_CLOSED: 'Juomia ei ole tällä hetkellä saatavilla',
    MENU_FOOD_CLOSED: 'Ruokaa ei ole saatavilla tällä hetkellä',
    MENU_NO_PRODUCTS: 'Ei näytettäviä tuotteita',
    MENU_NOT_AVAILABLE: 'Tällä hetkellä ei saatavilla',
    MENU_NOT_UPDATED: 'Näyttää siltä, ​​että ravintola ei ole vielä päivittänyt ruokalistaa',
    MENU_ORDER_FULL: 'Et voi tilata enempää samaan tilaukseen',

    MINUTE: 'Minuutti',
    MON: 'Ma',
    MONDAY: 'Maanantai',
    MY_BOOKING: 'Minun varaukset',
    NAME: 'Nimi',
    NEW_ORDER: 'Uusi tilaus',
    NO: 'Ei',
    OFFERS: 'Tarjouksia',
    OFFERS_NONE: 'Ei tarjouksia näytettävänä',
    OK: 'OK',
    OPEN: 'Auki',
    OPT_OUT: 'Ei kiitos',
    ORDER: 'Tilaa',
    ORDER_CONFIRMED: 'Tilauksesi on valmis',
    ORDER_READY_ESTIMATION: 'Tilauksesi on arvioitu valmistuvan:',
    PASSWORD: 'Salasana',
    PAY: 'Maksa',
    PAYED: 'Maksettu',
    PAYMENT_ADD_CARD: 'Lisää kortti',
    PAYMENT_APPLEPAY: 'Maksa Apply paylla',
    PAYMENT_DELIVERY: 'Toimitusmaksut',
    PAYMENT_DISCOUNT: 'Alennus',
    PAYMENT_ERROR: "Varmista, että korttiasi voidaan käyttää verkkokauppoihin ja että käytettävissä on riittävä määrä rahaa'",
    PAYMENT_ERROR_MAINTENANCE: "Päivitys on parhaillaan käynnissä eikä ostoasi voi suorittaa loppuun'",
    PAYMENT_EXTRA: 'Ylimääräinen',
    PAYMENT_FIRST_DISCOUNT: 'Matkalla ensimmäiseen alennukseesi!',
    PAYMENT_OFFER_ERROR_HEADER: 'Näitä tarjouksia ei voitu aktivoida',
    PAYMENT_OFFER_ERROR_TEXT: 'Ne eivät koske valitsemaasi tarjoilutyyppiä',
    PAYMENT_PAY: 'Maksa',
    PAYMENT_PAYMENTS: 'Maksutavat',
    PAYMENT_SAVED_CARDS: 'Tallennetut kortit',
    PAYMENT_SCROLL: 'Rullaa alas maksamaan',
    PAYMENT_SWISH: 'Maksa Swishillä',
    PAYMENT_TABLETAB: 'Lisää taulukkoon',
    PAYMENT_TITLE: 'Maksaa',
    PAYMENT_TOTAL: 'Yhteensä',
    PHONE: 'Puhelin',
    PURCHASE_HISTORY_Brutto: 'Brutto',
    PURCHASE_HISTORY_BUY_AT: 'Osto kohteessa',
    PURCHASE_HISTORY_COLLECTED_DISCOUNT: 'Kerätty alennus',
    PURCHASE_HISTORY_CONTROL_UNIT: 'Kustannuspaikka',
    PURCHASE_HISTORY_DISCOUNT: 'Alennus',
    PURCHASE_HISTORY_FILL_MAIL: 'Kirjoita sähköpostiosoitteesi',
    PURCHASE_HISTORY_MAIL_RECIEPT: 'Lähetä kuitti',
    PURCHASE_HISTORY_NETTO: 'Netto',
    PURCHASE_HISTORY_ORDER: 'Tilaus',
    PURCHASE_HISTORY_PHONE: 'Puhelin',
    PURCHASE_HISTORY_RECIEPT_NO: 'Kuitin numero',
    PURCHASE_HISTORY_ROUNDING: 'Pyöristäminen',
    PURCHASE_HISTORY_SENDING_RECIEPT: 'Lähetetään kuitti osoitteeseen',
    PURCHASE_HISTORY_SENDING_SENT: 'Kuitti lähetetty',
    PURCHASE_HISTORY_SUM: 'Summa',
    PURCHASE_HISTORY_TOTAL: 'Yhteensä',
    PURCHASE_HISTORY_VAT: 'Arvonlisävero',
    PURCHASE_HISTORY_VATID: 'ALV-tunnus',
    PUSH_ALERT_BTN: 'Klikkaa tästä',
    PUSH_ALERT_TXT: 'Jotta voit tarkastella tilauksesi tilaa, sinun on sallittava push-ilmoitukset',
    PUSH_CHANGE_SELECTION: 'Voit aina vaihtaa asetuksia "tili" alla -> ilmoitukset',
    PUSH_INFO: 'Valitse ravintolat, joista haluat saada tarjouksia. Lupaamme säilyttää tietojasi vastuullisesti.',
    PUSH_PERMISSION: 'Tee tämä muutos siirtymällä puhelimen asetuksiin ja sallimalla sovelluksen push-ilmoitukset.',
    PUSH_TITLE: 'Ilmoitukset',
    QUANTITY: 'Määrä',
    RESTAURANT_INFO: 'Ravintoloista tiedot',
    SAT: 'La',
    SATURDAY: 'Lauantai',
    SAVED_CARDS: 'Tallennetut kortit',
    SEARCH: 'Etsi...',
    SELECT_LANGUAGE: 'Valitse kieli',
    SEND_SMS_CODE: 'Lähetä SMS koodi',
    SENT: 'Lähetetty',
    STOCK_TITLE: 'Loppu varastosta',
    SUM: 'Summa',
    SUN: 'Sunnuntai',
    SUNDAY: 'Sunnuntai',
    TABLENUMBER: 'Pöytänumero',
    THU: 'To',
    THURSDAY: 'Torstai',
    TIPS: 'Tipit',
    TODAY: 'Tänään',
    TUE: 'Ti ',
    TUESDAY: 'Tiistai',
    WED: 'Ke',
    WEDNESDAY: 'Keskiviikko',
    WEEK: 'Viikko',
    WEEKLY_LUNCH: 'Viikon ruokalista',
    WELCOME: 'Tervetuloa',
    WRITE_TABLENUMBER: 'Kirjoita pöydän numero',
    YES: 'Kyllä',
    HOME: 'Koti',
    DRINKS: 'Juomat',
    FOOD: 'Ruoka',

    PAYMENT_NOT_AUTHORIZED: 'Jotain meni pieleen maksussa. Ota yhteyttä ravintolaan',

    CREDIT_CARD_EXPIRED: 'Korttisi on vanhentunut. Kokeile toista.',
    CREDIT_CARD_LOST_OR_STOLEN: 'Tämä kortti on merkitty varastetuksi, IP-osoitteesi on kirjattu.',
    CREDIT_CARD_SUSPECTED_FRAUD: 'Tämä kortti voi olla osallisena petokseen, ota yhteyttä pankkiisi.',

    LOGIN_MOBILE_NEEDED: 'Numerosi tarvitaan, jos tarvitsemme ottaa yhteyttä',

    SAVE_CARD_QUESTION: 'Lisää kortti tulevaa käyttöä varten',
    SAVE_CARD_QUESTION_HEADER: 'Säästä kortin tiedot?',

    VERIFY_TIMEOUT: '<p> Maksusi peruutettiin, koska et suorittanut maksua määritetyssä aikarajassa. </p>',
    WAITING_ON_SWISH_PAYMENT: 'Maksa tilauksesi Swish appin avulla',
    ORDER_NOT_VALID: 'Tilauksen summa on virheellinen',
    ACQUIRER_ERROR: 'Jotain meni pieleen ostoksessasi. Ota yhteyttä ravintolaan.',
    INSUFFICIENT_FOUNDS: 'Epäonnistunui riittämättömien varojen vuoksi.',
    DECLINED_BY_ACQUIRER: 'Jotain meni pieleen ostoksessasi. Ota yhteyttä ravintolaan.',
    REFOUND_AMOUNT_TOO_HIGH: 'Jotain meni pieleen ostoksessasi. Ota yhteyttä ravintolaan.',
    AUTHORIZATION_EXPIRED: 'Jotain meni pieleen ostoksessasi. Ota yhteyttä ravintolaan.',
    AUTHORIZATION_AMOUNT_EXCEEDED: 'Jotain meni pieleen ostoksessasi. Ota yhteyttä ravintolaan.',
    AUTHORIZATION_VOIDED: 'Jotain meni pieleen ostoksessasi. Ota yhteyttä ravintolaan.',
    SCA_REQUIRED: 'Jotain meni pieleen ostoksessasi. Ota yhteyttä ravintolaan.',
    RISK_FILTER_BLOCK: 'Jotain meni pieleen ostoksessasi. Ota yhteyttä ravintolaan.',

    ACCEPT_USER_TERMS: 'Hyväksyn käyttöehdot',
    ADD_CARD: 'Lisää maksukortti',

    ADD_TO_CART: 'Lisää ostoskoriin',

    CHANGE_UNIT: 'Vaihda ravintolaa',

    CHOOSE_DELIVERY_OPTION: 'Valitse toimitustapa',

    CONTINUE_SHOPPING: 'Lisää tuotteita',
    CONTACT_INFORMATION: 'Yhteystiedot',

    DELIVERY_OPTION: 'Toimitustapa',

    DELIVERY_CHANGE_OPTION: 'Vaihda toimitustapaa',

    FREE_CHARGE: 'Tästä ostoksesta ei tarvitse maksaa.',

    GO_TO_PAYMENT_PAY: 'Siirry maksamaan',

    HOW_TO_PAY: 'miten haluat maksaa?',
    HOW_TO_PAY_SWISH: 'Kuinka haluat maksaa Swishillä?,',
    INIT_SWISH_APP: 'Valmistamme Swish-maksuasi pieni hetki...,',

    MOVE: 'Jatka eteenpäin',

    MY_RECIEPTS: 'Kuittini',

    NO_DELIVERY_OPTIONS_SELECTED: 'Sinun on valittava toimitustapa,',

    OPENING_HOURS: 'Aukioloajat',

    OR: 'Tai',

    PAY_NOW: 'Maksa nyt',
    PAY_WITH_CARD: 'Maksa kortilla',

    PAYMENT_BONUS: 'Hyödynnä bonuspisteet,',

    PAYMENT_DEVICE_NOT_SUPPORTED: 'Laitetta ei tueta!',

    PAYMENT_INIT: 'Valmistellaan maksua',

    PAYMENT_VIPPS: 'Maksa Vippsillä',
    PAYMENT_VIPPS_START: 'Aloita maksu Vippsillä',
    PAYMENT_VIPPS_COMPLETE: 'Suorita maksu Vipps-sovelluksessa,',
    PAYMENT_VIPPS_OPEN_APP: 'Avaa Vipps',
    PAYMENT_VIPPS_SHOW_ORDER: 'Klikkaa Näytä tilaus.',
    PAYMENT_VIPPS_SHOW_ORDER_WHEN_PAYED: 'Klikkaa Näytä tilaus maksun suorittamisen jälkeen.',
    PAYMENT_VIPPS_NO_APP: 'Emme löydä Vippsiä tältä laitteelta. Käytä toista laitetta.',
    PAYMENT_VERYFING: 'Varmistaa...,',
    PAYMENT_GO_TO_ORDER: 'Tilauksiin,',
    PAYMENT_RETRY: 'Yritä uudelleen',
    PAYMENT_OTHER_DEVICE: 'Toinen laite',
    PAYMENT_THIS_DEVICE: 'Tämä laite',
    PAYMENT_VIPPS_INIT: 'Valmistellaan Vippsiä',
    PAYMENT_VIPPS_NO_DATA: 'Tietoja puuttuu Vipps-maksun suorittamiseen.',
    PAYMENT_DONE: 'Valmis ',
    PAYMENT_WAIT_CONFIRM: 'Odottaa vahvistusta,',
    PAYMENT_CONFIRMED: 'Maksusi on rekisteröity,',
    PAYMENT_NOT_CONFIRMED: 'Tilauksesi maksua ei ole vielä rekisteröity.',
    PLEASE_WAIT: 'Odota...',

    PURCHASE_COMPLETE: 'Teit ostoksen xxxssa (Musiikitalossa)',
    PURCHASE_CANCEL: 'Peruutit ostoksen xxxssa (Musiikkitalossa)',

    RECIEPTS: 'Kuitit',
    YOUR_CART: 'Ostoskori',
    PAYMENT_AND_ORDER_IS_PLACED: 'Tilauksesi on maksettu ja tilattu,',
    DONT_CLOSE_WEB_BROWSER: 'HUOM! Älä sulje selainta!,',
    ORDER_INFORMATION: '<p> Kun tilaus on valmis, selaimeen tulee valintaikkuna. </p> <p> Kuittisi sijaitsee kohdassa <b> Omat kuitit </b> ja voit lähettää sen haluamaasi sähköpostiosoitteeseen </ p > <p> Tilauksesi näkyy aktiivisten tilausten alla ja sinut lähetetään automaattisesti sinne, kun hyväksyt tämän dialogin. </p> ,',
    SWISH_ERROR_CONNECTION: 'Maksamisen aikana yhteydessä onli ongelmia. Varmista, että tilauksesi on vahvistettu, muuten tilaa uudelleen.',
    SWISH_DEMAND_NUMBER: '<p>Sinun on syötettävä kelvollinen numero voidaksesi maksaa.</p> <p>Tilauksestasi ilmoitetaan tekstiviestillä, kun se on valmis.</p>',
    SHOW_ORDERS: 'Näytä tilaukset,',
    SHOW_CART: 'Tee tilaus',
    CLEARE: 'Tyhjennä',
    SHOW_YOUR_ORDERS: 'Näytä tilaukseni ',
    NO_ACTIVE_ORDERS: 'Ei aktiivisia tilauksia',
    PREVIOUS_PURCHASES: 'Katso aiemmat ostokset,',
    DELIVERY_OPTIONS: 'Toimitusvaihtoehdot',

    //NOT ACTIVATED COMPONENT:'',
    UNIT_NOT_ACTIVATED: 'Tätä ravintolaa ei ole vielä aktivoitu verkkotilausta varten!',
    CONTACT_ADMIN_UNIT: 'Ota yhteyttä järjestelmänvalvojaan aktivoidaksesi ravintolan.',
    NO_CLIENT_DATA: 'Ei asiakasdataa tai asiakkaalle määritettyjä yksiköitä.',
    ABLE_ORDER_ONLINE: 'Voidaan tilata verkosta.',
    ALLOW_LOCATION: 'Salli sijainnin käyttöoikeus ja päivitä sivu!,',
    CONFIRMED: 'Vahvista',
    CONTACT_SUPPORT_SWISH: 'Tietoja puuttuu, jotta voit suorittaa Swish-maksun, ota yhteyttä tukeen.',
    CONTACT_ADMONISTRATOR: 'Ota yhteyttä järjestelmänvalvojaan aktivoidaksesi yksikön.',
    CONTACT_SUPPORT_PAYMENT: 'Tietoja puuttuu maksun suorittamiseksi, ota yhteyttä tukeen,',
    CANCEL_SWISH_VERIFY: 'Älä peruuta, jos olet suorittanut maksun.Haluatko peruuttaa?',
    INSERT_CARD_: '',
    NOTE: 'Lisää kortti uudelleen',
    NOT_ACTICATED_FOR_WEB_ORDERING: 'Tätä yksikköä ei ole vielä aktivoitu verkkotilausta varten!,',
    NEED_TO_INSERT_CARD_AGAIN: 'Sinun on asetettava kortti uudelleen, jotta voit jatkaa maksua,',
    ORDERING_NOT_POSSIBLE: 'Ei voi tilata nyt,',
    PENDING_ORDER: 'Odottavat tilaukset',
    TO_HOMEPAGE: 'Kotisivu',
    UNABLE_TO_ORDER: 'Ei voi tilata verkosta -,',
    OUR_POLICY: 'Käytäntömme',
}
