import { Component, ViewChild, ElementRef, Input } from "@angular/core";
import {
  ModalController,
} from "@ionic/angular";
import { ContextProvider } from "../services/context/context";
import * as moment from "moment";
import { LanguageProvider } from '../services/language/language.provider';
import { LanguagePipe } from '../pipes/language.pipe';
import { ThemeProvider } from "../services/theme/theme-provider.service";

declare var google;
@Component({
  selector: "page-client-info",
  styleUrls: ["clientInfo.component.scss"],
  templateUrl: "clientInfo.component.html",
})
export class ClientInfoComponent {
  @Input() HideCloseBtn: boolean = false;
  @ViewChild("search") public searchElementRef: ElementRef;

  openingHours = null;
  openingDeviations = null;
  aboutUs = null;
  restaurantPhoneNumber = null;
  restaurantEmail = null;
  restaurantOrgNo = null;
  restaurantOrgName = null
  loading;
  zoom = 15;
  latitude = 1;
  longitude = 0.5;
  address: string;
  maploaded = false;
  userPosition = null;
  private geoCoder;
  positionSubscription = null;
  generalInfo = null;
  constructor(
    private modalController: ModalController,
    public contextProvider: ContextProvider,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe,
    public themeProvider: ThemeProvider
  ) {
    let context = this.contextProvider.clientContext.appInfo.value.Context;
    this.aboutUs = context.aboutUsDescription ? context.aboutUsDescription.replace(/(\r\n|\n|\r)/gm, "<br>") : "<br>";
    this.restaurantOrgName = context.restaurantInfo.companyName;
    this.restaurantOrgNo = context.restaurantInfo.orgNo
    this.restaurantEmail = context.restaurantInfo.email;
    this.restaurantPhoneNumber = context.restaurantInfo.phoneNumber;
    // this.restaurantEmail = context.generalInfo && context.generalInfo.email ? context.generalInfo.email : context.restaurantEmail;
    // this.restaurantPhoneNumber = context.generalInfo && context.generalInfo.phoneNumber ? context.generalInfo.phoneNumber : context.restaurantPhoneNumber;
    this.formatOpeningHours(
      this.contextProvider.clientContext.appInfo.value.Context.openingHours
    );
    this.generalInfo = context.generalInfo;
  }
  async ngOnInit() {
    //load Places Autocomplete
  }
  dismiss() {
    this.modalController.dismiss();
  }
  async ngAfterViewInit() {

  }
  getPolicyUrl() {
    let webname = encodeURI(this.restaurantOrgName);
    let weborg = encodeURI(this.restaurantOrgNo);

    return `https://info.pubq.se/policy?c=${webname}&nr=${weborg}`;
  }
  ngOnDestroy() {
    if (this.positionSubscription)
      this.positionSubscription.unsubscribe();
  }
  getWeekdayLabel(weekday) {
    switch (weekday.type) {
      case "monday": return this.languagePipe.transform("Måndag", 'MONDAY', this.languageProvider.currentLanguage);
      case "tuesday": return this.languagePipe.transform("Tisdag", 'TUESDAY', this.languageProvider.currentLanguage);
      case "wednesday": return this.languagePipe.transform("Onsdag", 'WEDNESDAY', this.languageProvider.currentLanguage);
      case "thursday": return this.languagePipe.transform("Torsdag", 'THURSDAY', this.languageProvider.currentLanguage);
      case "friday": return this.languagePipe.transform("Fredag", 'FRIDAY', this.languageProvider.currentLanguage);
      case "saturday": return this.languagePipe.transform("Lördag", 'SATURDAY', this.languageProvider.currentLanguage);
      case "sunday": return this.languagePipe.transform("Söndag", 'SUNDAY', this.languageProvider.currentLanguage);
    }
  }
  formatOpeningHours(openingHours: any) {
    if (!openingHours) return;
    if (openingHours.weekdays) {
      this.openingHours = [];
      openingHours.weekdays.forEach((x) => {
        let startHour = parseInt(x.start.split(":")[0]);
        let startMinute = parseInt(x.start.split(":")[1]);
        let endHour = parseInt(x.end.split(":")[0]);
        let endtMinute = parseInt(x.end.split(":")[1]);

        let weekday = {
          hours: x.isChecked
            ? `${moment
              .utc()
              .set("hours", startHour)
              .set("minute", startMinute)
              .format("kk:mm")} - ${moment
                .utc()
                .set("hours", endHour)
                .set("minute", endtMinute)
                .format("kk:mm")}`
            : this.languagePipe.transform("Stängt", 'CLOSED', this.languageProvider.currentLanguage),
          label: x.label,
          type: x.type
        };
        this.openingHours.push(weekday);
      });
    }
    if (openingHours.deviations) {
      this.openingDeviations = [];
      openingHours.deviations.forEach((x) => {
        let startHour = parseInt(x.start.split(":")[0]);
        let startMinute = parseInt(x.start.split(":")[1]);
        let endHour = parseInt(x.end.split(":")[0]);
        let endtMinute = parseInt(x.end.split(":")[1]);

        let hours = '';
        if (endHour - startHour < 1) {
          if (endtMinute - startMinute < 10)
            hours = this.languagePipe.transform("Stängt", 'CLOSED', this.languageProvider.currentLanguage)
          else
            hours = `${moment
              .utc()
              .set("hour", startHour)
              .set("minute", startMinute)
              .format("kk:mm")} - ${moment
                .utc()
                .set("hour", endHour)
                .set("minute", endtMinute)
                .format("kk:mm")}`
        } else {
          hours = `${moment
            .utc()
            .set("hour", startHour)
            .set("minute", startMinute)
            .format("kk:mm")} - ${moment
              .utc()
              .set("hour", endHour)
              .set("minute", endtMinute)
              .format("kk:mm")}`
        }


        let date = moment(x.timestamp * 1000).format('DD/MM')
        let deviation = {
          hours: hours,
          date: date,
          label: x.label,
        };
        this.openingDeviations.push(deviation);
      });
    }
  }
}
