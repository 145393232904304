import { Component } from '@angular/core';
import { ClientInfo, ContextProvider } from '../services/context/context';
import { Subscription } from 'rxjs';
import { NavController } from '@ionic/angular';
import { UnitProvider } from '../services/unitProvider';
import { FilterPipe } from '../pipes/filter.pipe';
import { ActivatedRoute } from '@angular/router';
import { LanguageProvider } from '../services/language/language.provider';
import { LanguagePipe } from '../pipes/language.pipe';
import { UserProvider } from '../services/user/user';
import { AppConfiguration } from 'src/app/app.configuration';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AnalyticsService } from '../services/analytics/analytics';
import { runInThisContext } from 'vm';

@Component({
    styleUrls: ['unitSelection.component.scss'],
    selector: 'page-unit-selection',
    templateUrl: 'unitSelection.component.html'
})

export class UnitSelectionComponent {
    loadingUnits = false;
    units: ClientInfo[] = [];
    filteredUnits: ClientInfo[] = [];
    toggleItemIndex = 999;
    subscription: Subscription;
    activatedRouteSubscription: Subscription;
    searchString = '';
    isSorted = false;
    userOrigin = AppConfiguration.userOrigin;

    client = null;
    city = null;

    constructor(
        public navCtrl: NavController,
        private unitProvider: UnitProvider,
        private contextProvider: ContextProvider,
        private filterPipe: FilterPipe,
        private activatedRoute: ActivatedRoute,
        public languageProvider: LanguageProvider,
        private languagePipe: LanguagePipe,
        private userProvider: UserProvider,
        private fns: AngularFireFunctions,
        private analyticsService: AnalyticsService
    ) {
        //this.fns.functions.useFunctionsEmulator('http://localhost:5001');
        this.client = this.activatedRoute.snapshot.params.client;
        this.city = this.activatedRoute.snapshot.params.city;

    }

    search(ev) {
        this.searchString = ev.target.value;
        this.filteredUnits = this.filterPipe.transform(this.units, this.searchString, ['name', 'address']);
    }

    async ngOnInit() {
        console.log("ngOnInit");

        this.activatedRouteSubscription = this.activatedRoute.params.subscribe(params => {
            // if(this.activatedRoute.snapshot.url[0].path.includes("unitselection")){
            //     this.client = params['client'];
            //     this.city = params['city'];
            //     console.log(this.client)
            //     console.log(this.city)
            //     if(this.client && this.city){
            //         this.loadData();
            //     }
            // }

            this.client = params['client'];
            this.city = params['city'];

            if (this.client && this.city) {
                this.loadData();
            }

        });
    }

    async ngOnAfterViewInit() {
        await this.userProvider.createAnonymousUser();
        this.analyticsService.trackEvent('APP_START');
    }

    ngOnDestroy() {
        console.log("ngOnDestroy");
        this.subscription.unsubscribe();
        this.activatedRouteSubscription.unsubscribe();
    }

    loadData() {
        console.log("loadData");
        this.loadingUnits = true;

        this.subscription = this.unitProvider.getUnits(this.client, this.city).subscribe(async data => {
            console.log(this.client + ", " + this.city);
            if (!data) {
                return;
            }

            this.units = data;

            this.units.forEach(x => {
                this.loadAndApplyImage(x);
            });
            this.filteredUnits = this.units;
            this.loadingUnits = false;
            this.sortUnits();
        });
    }

    getSearchString() {
        return this.languagePipe.transform('Sök...', 'SEARCH', this.languageProvider.currentLanguage);
    }

    async sortUnits() {
        try {
            this.units = await this.unitProvider.sortUnitsByProximity(this.units);
            this.isSorted = true;
        } catch (error) {
            this.loadingUnits = false;
        }

    }

    allowGeolocation() {
        alert( this.languagePipe.transform('Please allow Location access and refresh the page!','ALLOW_LOCATION',
         this.languageProvider.currentLanguage));
    }

    loadAndApplyImage(news) {
        const image = new Image();
        image.src = news.imageUrl;
        news.styling = { opacity: '0' };
        image.onload = x => {
            news.styling = {
                'background-image': `url('${news.imageUrl}')`,
                'background-color': 'transparent',
                'background-size': 'cover',
                'background-position': 'center',
                'background-repeat': 'no-repeat',
                opacity: '1'
            };
        };
    }

    getDistanceText(distanceInKm) {
        if (distanceInKm > 1) {
            return Math.round(distanceInKm) + ' km';
        } else {
            return Math.round(distanceInKm * 1000) + ' m';
        }
    }

    async unitSelected(selected: ClientInfo) {
        // Move to MenuPage

        this.contextProvider.clientId = this.client;
        const result = await this.contextProvider.init(selected.routeName);

        if(!result){
            this.navCtrl.navigateForward(`not-activated`);
        }else{
            try{
                this.analyticsService.trackEvent("UNIT_SELECTED")
            }catch(error)
            {
                console.log(error);
            }
            await this.navCtrl.navigateForward(`u/${selected.routeName}`);
        }
        //await this.navCtrl.navigateForward(`main/${this.client}/${this.city}/${selected.routeName}`);
    }

    imgLoaded(index) {
        this.startAnimation(index);
    }

    startAnimation(index) {
        this.toggleItemIndex = index;
        setTimeout(() => {
            this.toggleItemIndex = 999;
        }, 2000);
    }
}
