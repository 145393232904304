import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-route',
  templateUrl: './no-route.page.html',
  styleUrls: ['./no-route.page.scss'],
})
export class NoRoutePage implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
    console.log(window.location);
    setTimeout(() => {
      if (window.location.host.includes("test.pubq.se"))
        document.location.href = 'https://test.pubq.se/u/demorino';
      if (window.location.host.includes("mainburgerkebab"))
        document.location.href = 'https://mainburgerkebab.ax/u/main_burger';
      if (window.location.host.includes("noitre.se"))
        document.location.href = "https://order.noitre.se/u/noitre";
      if (window.location.host.includes("joans.se"))
        document.location.href = "https://order.joans.se/units/joans/all";
      if (window.location.host.includes("localhost"))
        document.location.href = `http://${window.location.host}/u/demorino/kiosk`;
      if (window.location.host.includes("kiosk"))
        document.location.href = `http://${window.location.host}/u/demorino/kiosk`;
    }, 700);
  }

}
