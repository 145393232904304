import { Pipe, PipeTransform } from '@angular/core';
import { LanguageProvider } from '../services/language/language.provider';

@Pipe({
    name: 'language'
})

export class LanguagePipe implements PipeTransform {
    constructor(private languageProvider: LanguageProvider) {

    }
    transform(defaultValue: string, translationProperty: string, languageCode: string, parameters?: string[]) {
        const translated = this.languageProvider.getString(translationProperty, languageCode, parameters);
        if (!translated) {
            return defaultValue;
        }
        return translated;
    }
}
