module.exports = {
    name: "PubQ Web order",
    id: "com.emberlink.pubqdemo",
    defaultLanguage: "se",
    description: "Ordering made easy",
    userOriginName: "web_order",
    dbRoute: "demorino",
    customUrlProtocol: "https://web.pubq.se", // "https://web.pubq.se" "https://pq-web-order-cg.web.app"
    statusBarColorHex: "",
    hasCitySelection: false,
    paymentProvider: "reepay",
    statusBarStyle: "lightcontent",
    tabHomeTitle: "Hem",
    tabFoodTitle: "Mat",
    tabDrinksTitle: "Dryck",
    tabAccountTitle: "Konto",
    menuSegmentType: "md",
    firebaseProject: null
}
