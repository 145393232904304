import { Component } from '@angular/core';
import { UserPurchaseHistoryV2, User, UserProvider } from 'src/app/services/user/user';
import { AppConfiguration } from 'src/app/app.configuration';
import { AlertController, LoadingController, NavController } from '@ionic/angular';
import { OrderProvider } from 'src/app/services/order/order';
import { ReceiptProvider } from 'src/app/services/payment/receiptProvider';
import { ItemFunctions } from 'src/app/services/itemFunctions';
import { DateGoblin, CouponGoblin, TaxGoblin } from 'src/app/services/payment/payment';
import { CartItem } from 'src/app/cart-items/cart-items';
import { ActivatedRoute } from '@angular/router';
import { LanguageProvider } from 'src/app/services/language/language.provider';
import { LanguagePipe } from 'src/app/pipes/language.pipe';
import { ThemeProvider } from 'src/app/services/theme/theme-provider.service';
import { ContextProvider } from 'src/app/services/context/context';
import { CurrencyProvider } from 'src/app/services/currency/currency.provider';

@Component({
  selector: 'page-purchase-history-details',
  templateUrl: 'purchaseHistoryDetails.html',
  styleUrls: ['purchaseHistory.scss']
})
export class PurchaseHistoryDetailsViewComponent {
  objectKeys = Object.keys;
  purchase: UserPurchaseHistoryV2;
  user: User;
  categories;
  momsSums;
  client?: string = null;
  city?: string = null;
  routeName?: string = null;
  constructor(
    private orderProvider: OrderProvider,
    private userProvider: UserProvider,
    private receiptProvider: ReceiptProvider,
    private alertCtrl: AlertController,
    private loaderCtrl: LoadingController,
    private activatedRoute: ActivatedRoute,
    private navCtrl: NavController,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe,
    public themeProvider: ThemeProvider,
    private contextProvider: ContextProvider,
    public currencyProvider: CurrencyProvider
  ) 
  {
    /* Check route parameters on load */
    this.client = this.activatedRoute.snapshot.params.client;
    this.city = this.activatedRoute.snapshot.params.city;
    this.routeName = this.activatedRoute.snapshot.params.routeName;

    /* Get id on receipt and load data for it */  
    this.activatedRoute.params.subscribe(async x => {
      const key = x.id;
      this.user = this.userProvider.currentUser.value;
      this.purchase = await this.userProvider.getPurchaseHistoryItem(this.user, key);
      if (this.purchase.receipt.extraData && typeof (this.purchase.receipt.extraData) == 'string') {
        this.purchase.receipt.extraData = JSON.parse(
          this.purchase.receipt.extraData
        );
      }
      this.categories = this.orderProvider.momsCategories;
      this.momsSums = this.calculateMoms(this.purchase);
    });

  }

  ionViewWillEnter(){
    /* If this is the loading page route to main */
    // if(!this.contextProvider || !this.contextProvider.client)
    //   this.navCtrl.navigateForward(`main/${this.client}/${this.city}/${this.routeName}`);
  }

  goBack() {
    this.navCtrl.back();
  }
  async sendEmailReceiptRequest() {
    let emailAdress = '';
    if (!this.user.email) {
      emailAdress = prompt(this.languagePipe.transform('Fyll i din mailadress', 'PURCHASE_FILL_MAIL', this.languageProvider.currentLanguage));
      if (!emailAdress) { return; }
      if (!confirm(this.languagePipe.transform(`Är ${emailAdress} korrekt?`, 'EMAIL_CORRECT', this.languageProvider.currentLanguage, [emailAdress]))) {
        this.sendEmailReceiptRequest();
      } else {
        this.user.email = emailAdress;
        this.userProvider.updateUser(this.user);
      }

    } else {
      emailAdress = this.user.email;
    }
    const loader = await this.loaderCtrl.create({
      message: `${this.languagePipe.transform('Skickar kvitto till', 'PURCHASE_HISTORY_SENDING_RECIEPT', this.languageProvider.currentLanguage)}, ${this.user.email}`,
      duration: 10000
    });
    loader.present();
    this.receiptProvider
      .sendEmailReceiptRequest(
        this.purchase,
        this.user,
        emailAdress
      )
      .subscribe(async x => {
        const alert = await this.alertCtrl.create({
          header: `${this.languagePipe.transform('Kvittot mailades till', 'PURCHASE_HISTORY_SENDING_SENT', this.languageProvider.currentLanguage)} ${this.user.email}`,
          buttons: ['OK']
        });
        alert.present();
        loader.dismiss();
      });
  }
  costOfItemOptions(item) {
    return ItemFunctions.costOfItemOptions(item);
  }
  getNiceDateWithTime(date) {
    return DateGoblin.getNiceDateWithTime(date);
  }
  valueDeductedWithCoupon() {
    return CouponGoblin.valueOfCoupon(
      this.purchase.order.totalDrinksCost + this.purchase.order.totalFoodCost,
      this.purchase.order.coupon.discountPercent
    );
  }
  calculateMoms(item) {
    const momsSums = { totals: { brutto: 0, netto: 0, moms: 0 } };
    const itemsWithCategory = {};
    for (const momsKey in this.categories) {
      if (this.categories.hasOwnProperty(momsKey)) {
        const category = this.categories[momsKey];

        for (const drinkKey in item.order.order.drinks) {
          if (item.order.order.drinks.hasOwnProperty(drinkKey)) {
            const drink = item.order.order.drinks[drinkKey];
            if (drink.Category.startsWith(momsKey)) {
              if (itemsWithCategory[category.moms]) {
                itemsWithCategory[category.moms].push(drink);
              } else { itemsWithCategory[category.moms] = [drink]; }
            }
          }
        }
        for (const foodKey in item.order.order.food) {
          if (item.order.order.food.hasOwnProperty(foodKey)) {
            const food = item.order.order.food[foodKey];
            if (food.Category.startsWith(momsKey)) {
              if (itemsWithCategory[category.moms]) {
                itemsWithCategory[category.moms].push(food);
              } else { itemsWithCategory[category.moms] = [food]; }
            }
          }
        }
      }
    }
    for (const moms in itemsWithCategory) {
      if (itemsWithCategory.hasOwnProperty(moms)) {
        const orderItems = itemsWithCategory[moms];
        const momsPercent = parseInt(moms);
        let brutto = 0;
        let netto = 0;
        let momsOfItems = 0;
        for (let i = 0; i < orderItems.length; i++) {
          const orderItem: CartItem = Object.create(orderItems[i]);
          orderItem.Cost +=
            ItemFunctions.costOfItemOptions(orderItem) * orderItem.NrOfItems;
          if (item.order.coupon) {
            orderItem.Cost = CouponGoblin.valueWithCouponDeducted(
              orderItem.Cost,
              item.order.coupon.discountPercent
            );
          }
          brutto += orderItem.Cost * orderItem.NrOfItems;
          netto += TaxGoblin.valueWithoutTax(
            orderItem.Cost * orderItem.NrOfItems,
            momsPercent
          );
          momsOfItems += TaxGoblin.taxOfValue(
            orderItem.Cost * orderItem.NrOfItems,
            momsPercent
          );
        }
        brutto = brutto / 100;
        netto = netto / 100;
        momsOfItems = momsOfItems / 100;
        momsSums[moms] = { brutto, netto, moms: momsOfItems };
        momsSums.totals.brutto += brutto;
        momsSums.totals.netto += netto;
        momsSums.totals.moms += momsOfItems;
      }
    }
    return momsSums;
  }
}
