import {Component, EventEmitter, Input, Output} from '@angular/core';
import { UserPurchaseHistoryV2, User, UserProvider } from 'src/app/services/user/user';
import { NavParams, NavController } from '@ionic/angular';
import { PurchaseHistoryDetailsViewComponent } from './purchaseHistoryListDetails';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { LanguageProvider } from 'src/app/services/language/language.provider';
import { ThemeProvider } from 'src/app/services/theme/theme-provider.service';
import { Context } from 'vm';
import { ContextProvider } from 'src/app/services/context/context';
import { Console } from 'console';
import { Subscription } from 'rxjs';
import { CurrencyProvider } from 'src/app/services/currency/currency.provider';

@Component({
  selector: 'page-purchase-history-list',
  templateUrl: 'purchaseHistoryList.html',
  styleUrls: ['purchaseHistory.scss'],
  providers: [PurchaseHistoryListViewPage, PurchaseHistoryDetailsViewComponent]
})
export class PurchaseHistoryListViewPage {
  @Input() HideCloseBtn = false;
  @Output() receipts = new EventEmitter();
  purchaseHistory: UserPurchaseHistoryV2[];
  user: User;
  subscription: Subscription;
  userSubscription: Subscription;
  client?: string = null;
  city?: string = null;
  routeName?: string = null;
  constructor(private userProvider: UserProvider,
              private navCtrl: NavController,
              public languageProvider: LanguageProvider,
              public themeProvider: ThemeProvider,
              private activatedRoute: ActivatedRoute,
              private contextProvider: ContextProvider,
              public currencyProvider: CurrencyProvider) 
  {
    /* Check route parameters on load */
    this.client = this.activatedRoute.snapshot.params.client;
    this.city = this.activatedRoute.snapshot.params.city;
    this.routeName = this.activatedRoute.snapshot.params.routeName;

    this.userSubscription = this.userProvider.currentUser.subscribe(
      (x: User) => {
        if (!x) {
          return;
        }
        this.user = x;
        this.subscription = this.userProvider.getPurchaseHistory(x).subscribe((x: UserPurchaseHistoryV2[]) => {
          this.purchaseHistory = x.sort((a, b) => {
            return moment(a.receipt.timestamp) < moment(b.receipt.timestamp) ? 1 : -1;
          });
        });
      }
    );
  }

  ngOnInit() {
    this.receipts.emit(this.purchaseHistory);
  }

  ionViewWillEnter(){
    /* If this is the loading page route to main */
    // if(!this.contextProvider || !this.contextProvider.client)
    //   this.navCtrl.navigateForward(`main/${this.client}/${this.city}/${this.routeName}`);
  }

  ngOnDestroy(){
    if(this.subscription)
      this.subscription.unsubscribe();
    if(this.userSubscription)
      this.userSubscription.unsubscribe();
  }

  getNiceDateWithTime(date) {
    return moment(date).format('YYYY-MM-DD kk:mm');
  }
  goBack() {
    this.navCtrl.back();
  }
  async showPurchaseHistoryDetails(item) {
    this.navCtrl.navigateForward([`/history/${this.client}/${this.city}/${this.routeName}/${item.key}`]);
  }

}
