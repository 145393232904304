import { Component, Output, EventEmitter } from '@angular/core';

import { UserPaymentSource, UserProvider, User } from '../services/user/user';

import { Subscription } from 'rxjs';

import {
  NavController,
  AlertController,
  LoadingController,
  ModalController,
  IonRouterOutlet,
} from '@ionic/angular';

import {
  PaymentProvider  
} from '../services/payment/payment';

import { ActivatedRoute } from '@angular/router';
import { LoginNavComponent } from './login-nav.component';
import { AddCardComponent } from '../payment/add-card/addCard.component';
import { ContextProvider } from '../services/context/context';

import { BonusProvider } from '../services/bonus/bonus';
import { LanguageProvider } from '../services/language/language.provider';
import { LanguagePipe } from '../pipes/language.pipe';
let appSettings = require("../../../cordova-scripts/appsettings");
import { ThemeProvider } from '../services/theme/theme-provider.service';
import { AppConfiguration } from '../app.configuration';

@Component({
  selector: 'page-account',
  templateUrl: 'account.html',
  styleUrls: ['account.scss']
})
export class AccountPage {
  @Output() onReceiptClick = new EventEmitter();
  user: User;
  userBonusVoucher: any;
  userStoredPaymentOptions: UserPaymentSource[];
  browserResponse = {};
  object = Object;
  userSubscription: Subscription;
  paymentSourcesSubscription: Subscription;
  subscriptions: Subscription;
  languages = [];
  selectedLanguage: any;
  languageHandlingEnabled = true;
  userOrigin = AppConfiguration.userOrigin;
  userReceiptsData: any;
  client?: string = null;
  city?: string = null;
  routeName?: string = null;
  version = appSettings.version;
  showHeader = false;
  constructor(
    public navCtrl: NavController,
    private alertCtrl: AlertController,
    private userProvider: UserProvider,
    private loadingCtrl: LoadingController,
    private paymentProvider: PaymentProvider,
    private contextProvider: ContextProvider,
    private activatedRoute: ActivatedRoute,
    private modalController: ModalController,
    private routerOutlet: IonRouterOutlet,
    private bonusProvider: BonusProvider,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe,
    public themeProvider: ThemeProvider
  ) {

    /* Check route parameters on load */
    this.client = this.activatedRoute.snapshot.params.client;
    this.city = this.activatedRoute.snapshot.params.city;
    this.routeName = this.activatedRoute.snapshot.params.routeName;

    this.userSubscription = this.userProvider.currentUser.subscribe(
      (x: User) => {
        if (!x) {
          this.userStoredPaymentOptions = null;
          return;
        }
        this.user = x;
        this.paymentSourcesSubscription = this.userProvider
          .getUserPaymentSources(x.id)
          .subscribe((y: UserPaymentSource[]) => {
            this.userStoredPaymentOptions = y;
          });
      }
    );

    this.languageHandlingEnabled = this.contextProvider.clientContext.appInfo.value.Context.languageHandlingEnabled;
    this.languages = this.languageProvider.getAvailableLanguages();

    this.selectedLanguage = this.languages.find(l => l.code === this.languageProvider.currentLanguage);


  }

  ngOnInit() {
    if (this.subscriptions)
      this.subscriptions = new Subscription();
  }

  async ionViewDidEnter() {
    if (this.user && this.user.id) {
      this.userBonusVoucher = await this.bonusProvider.getBonus(this.contextProvider.clientContext.currentClient.value.routeName, this.user.id);
    }
  }

  ionViewWillEnter() {
    /* If this is the loading page route to main */
    if (!this.contextProvider || !this.contextProvider.client)
      this.navCtrl.navigateForward(`main/${this.client}/${this.city}/${this.routeName}`);
  }

  ngOnDestroy() {
    if (this.subscriptions)
      this.subscriptions.unsubscribe();
    // this.userSubscription && this.userSubscription.unsubscribe();
    this.paymentSourcesSubscription && this.paymentSourcesSubscription.unsubscribe();
  }
  async showAddCreditCardModal() {
    const modal = await this.modalController.create({
      component: AddCardComponent,
      cssClass: 'modal-drawer-small',
      swipeToClose: true,
      backdropDismiss: true,
    });
    modal.present();
    const result = await modal.onDidDismiss();
    if (result.role === 'confirm') {
      this.userProvider.addPaymentSource(this.user, result.data);
    }
    // if (AppConfiguration.paymentProvider === "worldnet") {
    //   await this.paymentProvider.createPaymentSource(this.user);
    // }
  }
  setLanguage(ev: any) {
    this.selectedLanguage = this.languages.find(l => l.code === ev.value);
    this.languageProvider.setLanguage(ev.value);
    this.userProvider.setPreferedLanguage(this.user, ev.value);
  }
  getCardSource(option: UserPaymentSource) {
    let brand = option.type.split('+')[0];
    if (brand) {
      brand = brand.toLowerCase();
      if (brand.indexOf('visa') > -1) { return './assets/icons/visa.svg'; }
      if (brand.indexOf('master') > -1) { return './assets/icons/mastercard.svg'; }
      return './assets/icons/genericcard.svg';
    }
  }

  userReceipts(event) {
    this.userReceiptsData = event;
  }

  async createNewCustomer(
    email: string,
    encryptedCard: string,
    paymentNickname: string
  ) {
    const loader = await this.loadingCtrl.create({ spinner: 'circular' });
    loader.present();
    // this.paymentProvider.createRecurringCustomer(email, encryptedCard).then(
    //   (result: { customer: RecurringDetailsResponse; error: any }) => {
    //     loader.dismiss();
    //     if (result.error) { this.showRefusedAlert(result.error); } else {
    //       const source =
    //         result.customer.details[result.customer.details.length - 1];
    //       const paymentSource: UserPaymentSource = {
    //         recurringReference: source.RecurringDetail.recurringDetailReference,
    //         type: 'Unknown',
    //         nickname: paymentNickname,
    //       };
    //       if (paymentNickname) {
    //         this.userProvider.addPaymentSource(this.user, paymentSource);
    //       }
    //     }
    //   },
    //   (error) => {
    //     this.showRefusedAlert(error);
    //     loader.dismiss();
    //   }
    // );
  }
  async showRefusedAlert(error) {
    let text;
    if (error.pubqmaintenance) {
      text = this.languagePipe.transform('Just nu pågår en uppdatering och du kan inte spara ditt kort',
        'PAYMENT_ERROR_MAINTENANCE', this.languageProvider.currentLanguage);
    } else {
      text =
        this.languagePipe.transform('Se så ditt kort är öppet för internetköp och att några kronor finns tillgängligt för kontroll',
          'CART_CHECK_CARD', this.languageProvider.currentLanguage);
    }
    const alert = await this.alertCtrl.create({
      header: this.languagePipe.transform('Fel', 'ERRIR', this.languageProvider.currentLanguage),
      subHeader: text,
      buttons: ['OK'],
    });
    alert.present();
  }
  getSourceTypeIcon(source: UserPaymentSource) {
    switch (source.type) {
      default:
        return 'card';
    }
  }

  goBack() {
    this.navCtrl.back();
  }

  goToActiveOrders() {
    this.navCtrl.navigateForward(`active-orders/${this.client}/${this.city}/${this.routeName}`);
  }

  hasActiveOrders(user) {
    return user && user.activeOrders && typeof user.activeOrders === 'object' && Object.keys(user.activeOrders).length > 0;
  }

  async showLogin() {
    const modal = await this.modalController.create({
      component: LoginNavComponent,
      animated: true,
      swipeToClose: true,
    });
    modal.present();
  }
  showUserPurchaseHistory() {
    //this.navCtrl.navigateForward([`history/${this.client}/${this.city}/${this.routeName}`]);
    this.onReceiptClick.emit(null);
  }
  async signOut() {
    const loader = await this.loadingCtrl.create();
    await loader.present();
    await this.userProvider.signOut();
    await loader.dismiss();
    await this.userProvider.createAnonymousUser();
    await this.navCtrl.navigateForward([`main/${this.client}/${this.city}/${this.routeName}`]);
  }


  async openPolicy() {

    let context = this.contextProvider.clientContext.appInfo.getValue().Context;
    if (!context.policyUrl)
      context.policyUrl = "https://pubq.se/policy/";

    var strWindowFeatures = "location=no,scrollbars=yes,status=yes";
    var win = window.open(context.policyUrl, "_blank", strWindowFeatures);
  }

  async removePaymentSource(source: UserPaymentSource) {
    const confirm = await this.alertCtrl.create({
      header: this.languagePipe.transform('Ta bort kort?', 'ACCOUNT_REMOVE_CARD', this.languageProvider.currentLanguage),
      message: this.languagePipe.transform('Vill du ta bort kortet ',
        'ACCOUNT_REMOVE_CARD_2', this.languageProvider.currentLanguage) + source.nickname + '?',
      buttons: [
        {
          text: this.languagePipe.transform('Avbryt', 'CANCEL', this.languageProvider.currentLanguage),
          handler: () => {
            return null;
          },
        },
        {
          text: this.languagePipe.transform('Ok', 'Confirm', this.languageProvider.currentLanguage),
          handler: () => {
            this.userProvider.removePaymentSource(this.user, source);
          },
        },
      ],
    });
    await confirm.present();
  }
}
