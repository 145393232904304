import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonContent, ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { MenuItem, Option } from '../models';
import { AppInfo, ContextProvider } from '../services/context/context';
import { CurrencyProvider } from '../services/currency/currency.provider';
import { LanguageProvider } from '../services/language/language.provider';


@Component({
  templateUrl: 'itemDescription.html',
  selector: 'page-item-description',
  styleUrls: ['itemDescription.scss'],
})

export class MenuItemDescriptionComponent {
  constructor(public modalCtrl: ModalController,
    private platform: Platform,
    private contextProvider: ContextProvider,
    public languageProvider: LanguageProvider,
    public currencyProvider: CurrencyProvider) { }
  @ViewChild('descContent') ionContent: IonContent;
  @Input() isReadonly: boolean;
  @Input() item: any;
  @Input() smallLayout: boolean = false;
  @Output() itemToCart = new EventEmitter();
  showItemsComment = false;
  subscriptions = new Subscription();
  selectOptions = {
    cssClass: 'itemDescriptionOption',
  };
  style;
  scrollDepthTriggered = false;
  showToast = false;
  changeArrowPoss = false;
  chosenIndexes = new Set();
  idText;
  itemHaveBooleanProperties;
  isCollapsed = false;
  1;

  ngOnInit() {
    this.item.DescriptionLong = this.item.DescriptionLong.replace(/(\r\n|\n|\r)/gm, '<br>');
    this.subscriptions.add(this.contextProvider.clientContext.appInfo.subscribe(
      (data: AppInfo) => {
        if (typeof (data.Context.showItemsComment) !== 'undefined') {
          this.showItemsComment = data.Context.showItemsComment;
        }
      }
    ));
    this.item = Object.assign({}, this.item);
    this.style = {
      'min-height': '40px',
      // background: `url(${this.item.ImageUrl})`,
      'background-size': 'cover',
      'background-position': 'center',
    };
    if (this.item.options != null) {
      this.item.options.forEach((element: Option) => {
        element.enabled = true;
        if (element.type === 'multiple') {
          element.choices.forEach((choice: any) => {
            choice.enabled = true;
            if (choice.linkedProduct !== undefined) {
              const prod: MenuItem = choice.linkedProduct;
              if (prod.enabled === false) {
                choice.enabled = false;
              }
            }
          });

          element.selected =
            element.choices.length > 0 ? element.choices.filter(e => e.enabled == true)[0] : null;


        } else if (element.type === 'boolean') {
          if (element.linkedProduct != null) {
            const prod: MenuItem = element.linkedProduct;
            if (prod.enabled === false) {
              element.enabled = false;
            }
          }
        }
      });
    }
    if (this.item.options)
      this.itemHaveBooleanProperties = this.item.options.find(el => el.type === 'boolean');

  }
  getOptionText(option) {
    return option.choices ? option.selected.text : option.text
  }
  getOptionCost(option) {
    return option.choices ? (option.selected && option.selected.cost > 0 ? option.selected.cost / 100 : 0) : option.cost / 100;
  }
  getEnabledChoices(choices) {
    return choices.filter(c => c.enabled == true);
  }
  getDisabledChoices(choices) {
    return choices.filter(c => c.enabled == false);
  }
  isDisabled(option) {
    if (option.enabled == undefined) { return false; }
    else { return option.enabled; }
  }

  ngAfterViewInit() {

  }
  async ionViewDidEnter() {
    // await this.ionContent.scrollByPoint(0, 10, 1);
    // setTimeout(() => {
    //   this.showToast = true;
    //   setTimeout(() => {
    //     const element = document.getElementById('item-description-reached-end');
    //     const result = this.isInView(element);
    //     if (result) {
    //       this.scrollDepthTriggered = true;
    //     }
    //   }, 200);
    // }, 100);
  }
  optionChanged(ev, option) {
    option.selected = ev.detail.value;
  }

  dismiss() {
    this.itemToCart.emit(null);
    this.modalCtrl.dismiss();
  }

  addToOrder() {
    const data = this.item;
    this.itemToCart.emit({ data });
    this.modalCtrl.dismiss(data, "confirm");
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getChosenOptions(item) {
    if (item.options && item.options.length > 0) {
      return item.options.filter((x) => x.selected)
    }
    return [];
  }

  getTotalCost(item) {

    let cost = item.Cost;

    const options = this.getChosenOptions(item);

    options.forEach(option => {
      cost += this.getOptionCost(option) * 100
    });

    return cost;
  }

  async scrollIsHappening($event) {
    if ($event.target.localName != 'ion-content') {
      // not sure if this is required, just playing it safe
      return;
    }

    const scrollElement = await $event.target.getScrollElement();
    // minus clientHeight because trigger is scrollTop
    // otherwise you hit the bottom of the page before
    // the top screen can get to 80% total document height
    const scrollHeight = scrollElement.scrollHeight - scrollElement.clientHeight;
    const currentScrollDepth = $event.detail.scrollTop;
    const targetPercent = 90;

    const triggerDepth = ((scrollHeight / 100) * targetPercent);

    if (currentScrollDepth > triggerDepth) {
      this.scrollDepthTriggered = true;
    }
  }

  isInView(element) {
    const height = this.platform.height();
    const width = this.platform.width();
    const bounding = element.getBoundingClientRect();
    if (
      Math.floor(bounding.top) >= 0 &&
      Math.floor(bounding.left) >= 0 &&
      Math.floor(bounding.right) <= width &&
      Math.floor(bounding.bottom) <= height
    ) {
      return true;
    } else {
      return false;
    }
  }

  confirmEnterPress(ev) {
    if (ev.key === 'Enter') {
      this.addToOrder();
    }
  }

  changeArrow(index) {
    if (this.chosenIndexes.has(index)) {
      this.chosenIndexes.delete(index);
    } else {
      this.chosenIndexes.add(index);
    }
    this.changeArrowPoss = !this.changeArrowPoss;
  }
}
