import { Injectable } from '@angular/core';
import { AppConfiguration } from 'src/app/app.configuration';
import { ContextProvider } from '../context/context';
@Injectable({
    providedIn: "root"
})
export class CurrencyProvider {
    
    currentCurrency = "SEK";

    // ISO 4217 Valutakoder https://www.iban.se/currency-codes
    availableCurrency = [
        {currency:"SEK", description: "Svenska kronor", symbol:"kr", infront: false, space: ' '}, // Example 10 kr
        {currency:"NOK", description: "Norska kronor", symbol:"kr", infront: false, space: ' '}, // Example 10 kr
        {currency:"DKK", description: "Dansk Krone", symbol:"kr", infront: false, space: ' '}, // Example 10 kr
        {currency:"EUR", description: "Euro", symbol:"€", infront: true, space: ''}]; // Example €10

    constructor(private contextProvider : ContextProvider) {
        
        this.contextProvider.clientContext.appInfo.subscribe((x) => {
            if(x){
                if(x.Context && x.Context.currencyToUse !== undefined){
                    this.currentCurrency = x.Context.currencyToUse;
                }else   
                console.log("WARNING : No defualt currency in App context.")

                // Check if defaultCurrency exists in AppConfiguration
                // if(AppConfiguration.defaultCurrency !== undefined){
                //     this.currentCurrency = AppConfiguration.defaultCurrency;
                // }else   
                //     console.log("WARNING : No defualt currency in App configuration.")

                console.log("Current currency is " + this.currentCurrency);
            }
        });
    }

    getAvailableCurrencies(){
        return this.availableCurrency;
    }

    setCurrency(currencyCode: string) {
        this.currentCurrency = currencyCode;
    }

    getCurrency(currencyCode: string){
        let currencySettings = this.availableCurrency.filter(x=>x.currency == currencyCode);

        if(currencySettings.length > 0)
            return currencySettings[0];
        else    
            return this.availableCurrency[0];
    }
}