
export const lang = {
    get: (string, parameters?) => {
        switch (string) {
            case 'BONUS_USE': return `Use ${parameters[0]}​​​ bonus points`;
            case 'BONUS_USE': `Brug ${parameters[0]} bonuspoint'`;
            case 'STOCK_ALERT': `Desværre manglede ${parameters[0]}​​​ på lager. Din kurv er blevet justeret til det, der var tilgængeligt, så du kan fortsætte med at shoppe.`;
            case 'BOOKING_NO_AVAILABLE_TIMES_1': `Der er desværre ingen ledige tidspunkter for ${parameters[0]} personer.`;
            case 'BOOKING_SMS_TEX': `Hej, dette handler om reservationen [booking_name] \n kl. ${parameters[0]}\n ${parameters[1]} personer.`;
            case 'OFFERS_ACTIVATED': `Aktiverede ${parameters[0]}`;
            case 'CART_ITEMS_REMOVE': `Vil du fjerne ${parameters[0]}?`;
            case 'CART_ALLERGY_CONTACT': `Mellem kl. ${parameters[0]}-${parameters[1]} svarer vi gerne på dine spørgsmål om indhold`;
            case 'EMAIL_CORRECT': `Er ${parameters[0]} korrekt? `;
            case 'LOGIN_SEND_SMS': `Send sms til ${parameters[0]}?`;
            case 'LOGIN_ERROR': `Ups! Noget gik galt. Prøv en anden måde at logge ind. \n ${parameters[0]}`;
            case 'OPEN_SWISH_APP_WITH_PHONENUMBER': return `Visas ingen betalning i Swish, kontrollera att det är rätt nummer (${parameters[0]}).`;
            case 'SINGLE_ORDER': return `Du har ${parameters[0]} ordre i gang`;
            case 'MULTIPLE_ORDER': return `Du har${parameters[0]} afventende ordre`;
        }
    },
    ABOUT_US: 'Om os',
    ACCOUNT: 'Konto',
    ACCOUNT_REMOVE_CARD: 'Fjern betalingskort',
    ACCOUNT_REMOVE_CARD_2: 'Vil du slette betalingskort?',
    ACTIVATE: 'Aktivér',
    ACTIVE_ORDER_CLOSE_WINDOW: 'Dette vindue forsvinder, når du vælger Ja',
    ACTIVE_ORDER_DEL_CUSTOMER: 'Køkkenet har modtaget din ordre, og du får besked, når ordren leveres til dig',
    ACTIVE_ORDER_DRINK_READY: 'Din drikkevare er klar',
    ACTIVE_ORDER_EATIN_COMPLETE: 'Vis denne skærm ved skranken',
    ACTIVE_ORDER_FOOD_READY: 'Din mad er klar',
    ACTIVE_ORDER_HOME_DELIVERY_OTW: 'Din ordre forlader restauranten nu og er på vej til dig!',
    ACTIVE_ORDER_ONGOING: 'Igangværende ordrer',
    ACTIVE_ORDER_READY: 'Din ordre er klar',
    ACTIVE_ORDER_RECIEVED_Q: 'Har du modtaget din ordre?',
    ACTIVE_ORDER_SHOW_SCREEN: 'Vis denne skærm ved skranken',
    ACTIVE_ORDER_TABLE: 'Tak! Vi er i gang med din ordre og leverer den til dig, så snart vi kan!',
    ACTIVE_ORDER_TABLE_DELIVERY_OTW: 'Din ordre leveres straks til dit bord',
    ACTIVE_ORDER_TAKEAWAY: 'Køkkenet har modtaget din ordre, og du får snarest besked med afhentningstidspunkt',
    ACTIVE_ORDER_TAKEAWAY_COMPLETE: 'Vis denne skærm ved skranken',
    ACTIVE_ORDER_TICKET: 'Her er din billet',
    ACTIVE_ORDER_WAITING: 'Tak! Afventer godkendelse',
    ADD: 'Tilføj',
    ADD_CARD_ADDING: 'Tilføjer betalingskort',
    ADD_CARD_ERROR_E1: 'Noget gik galt. Prøv venligst igen.',
    ADD_CARD_ERROR_E10: 'Forkert kortnummer. Tjek venligst at du har indtastet korrekt. ',
    ADD_CARD_ERROR_E11: 'Forkert måned/år. Tjek venligst at du har indtastet korrekt. ',
    ADD_CARD_ERROR_E12: 'Forkert navn på betalingskortet. Kontakt venligst personalet. ',
    ADD_CARD_ERROR_E13: 'Restauranten har de forkerte indstillinger. Kontakt venligst personalet.',
    ADD_CARD_ERROR_E14: 'Forkert CVV-kode. Tjek venligst at du har indtastet korrekt. ',
    ADD_CARD_ERROR_E3: 'Restauranten kan ikke tilføje kort. Kontakt venligst personalet.',
    ADD_CARD_ERROR_E4: 'Ugyldige oplysninger',
    ADD_CARD_ERROR_E5: 'Forkert korttype',
    ADD_CARD_ERROR_E6: 'Forkert terminal-ID! Kontakt venligst personalet.',
    ADD_CARD_ERROR_E7: 'Ikke tilladt! Kontakt venligst personalet.',
    ADD_CARD_ERROR_E8: 'Forkert forhandler-ID! Kontakt venligst personalet.',
    ADD_CARD_ERROR_E9: 'Forkert dato! Kontakt venligst personalet.',
    ADD_CARD_NEW_CARD: 'Tilføj nyt betalingskort',
    ADD_CARD_NICK_MSG: 'Kortet krypteres på en sikker måde og gemmes til fremtidig brug',
    ADD_CARD_NICK_PLACEHOLDER: 'Kortets navn',
    ADD_CARD_NICK_TITLE: 'Hvad vil du navngive dit kort?',
    ADD_CARD_SECURITY_INFO: 'Vi behandler dine kortoplysninger på sikker vis. Alle følsomme oplysninger gemmes ved en certificeret betalingsudbyder.',
    BAB_ADD: 'Tilføj',
    BAB_BURGER_NAME: 'Hvad skal din burger hedde?',
    BAB_DRESSING: 'Dressing',
    BAB_EXTRAS: 'Vælg ekstra',
    BAB_MANY: 'Vælg det antal du ønsker',
    BAB_NAME: 'Hvad skal din ret hedde?',
    BAB_SIDEORDER: 'Vælg tilbehør',
    BAB_TOPPINGS: 'Toppings',
    BAB_TYPE_SIZE: 'Vælg type og størrelse',
    BACK: 'Tilbage',
    BONUS_POINTS: 'Bonuspoint',
    BONUSPOINTS: 'Bonuspoint',
    BOOK: 'Bestil',
    BOOKING_ALLOW_SMS: 'Tilladelsen giver appen mulighed for at åbne en forudfyldt SMS til restauranten for dig',
    BOOKING_APPCODE: 'Din kode er',
    BOOKING_CANCEL: 'Er du sikker på, du vil annullere bestillingen?',
    BOOKING_CANCELED: 'Din reservation er nu annulleret',
    BOOKING_CONFIRM: 'Ordrebekræftelse',
    BOOKING_CONFIRM_CANCEL: 'Bekræft afbestilling',
    BOOKING_CONTACT_US_1: 'Kontakt os på',
    BOOKING_CONTACT_US_2: 'eller klik her for at sende en sms',
    BOOKING_ICONS: 'Bestil ved hjælp af ikonerne nedenfor',
    BOOKING_LARGER_GROUPS: 'Betil bord til større grupper',
    BOOKING_LARGER_GROUPS_CONTACT: 'Hvis du vil reservere et bord til flere mennesker bedes du kontakte',
    BOOKING_NO_AVAILABLE_TIMES_1: 'Ingen ledige tider',
    BOOKING_NUMBER_GUESTS: 'Antal gæster',
    BOOKING_QUESTIONS: 'Har du spørgsmål til din bestilling?',
    BOOKING_THANKS_1: 'Tak for besøget!',
    BOOKING_THANKS_2: 'Vi ses snart igen',
    BOOKING_TITE: 'Bestil bord',
    CANCEL: 'Afbryd',
    CANCEL_ORDER:'Aflys ordre',
    CARD_NO: 'Kortnummer',
    CART_ALLERGIC_CONTACT: 'Allergier? Kontakt restauranten på',
    CART_BOOK_TABLE: 'Reserver bord',
    CART_CHECK_CARD: 'Sørg venligst for at dit kort kan benyttes til onlinekøb, og at der er et tilstrækkeligt beløb tilgængeligt',
    CART_CREATING_ORDER: 'Opretter ordrer og tilføjer til regningen',
    CART_GO_TO_MENY: 'Gå til menuen og placer din første ordre',
    CART_ITEM_18Y: '18+ år og gyldig ID er påkrævet ved køb af alkohol',
    CART_ITEM_ALCOHOL: 'Ingen rabat på alkohol',
    CART_ITEM_COMMENT: 'Skriv din kommentar her',
    CART_ITEM_COMMENT_EXAMPLE: '"F.eks. ingen koriander"',
    CART_ITEM_OFFER_NOT_VALID: 'Rabatkoden er ikke tilgængelig',
    CART_ITEM_REMOVE_CODE: 'Fjern koden?',
    CART_ITEMS_APPLYING_OFFER: 'Tilføjer rabatkode',
    CART_MY_BOOKING: 'Mine bestillinger',
    CART_NO_ACTIVE_TAB: 'Du har ingen åben regning',
    CART_ORDER_TAKEWAY: 'Bestil takeaway',
    CART_PAYMENT_ABORTED: 'Betalingen blev afbrudt',
    CART_PERSONNEL_CARD: 'Personalekort',
    CART_PREPARNG_SWISH: 'Forbereder Swish-betaling',
    CART_SERVE_NOW: 'Server straks',
    CART_SOME_ERROR: 'Noget gik galt. Prøv venligst igen.',
    CART_WEEK_MENU: 'Ugens menu',
    CART_WELCOME_OPENING_HOURS: 'Åbningstider og kontaktinformation',
    CART_WELCOME_READ: 'Læs mere',
    CHARGE_ERROR: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    CHARGE_SWISH_INIT: 'Anmod Swish om betaling',
    CHARGE_TAB_TOAST: 'Din regning er blevet betalt og en kvittering er oprettet på din konto',
    CHARGE_VERIFY: 'Verificerer og afstemmer',
    CHARGE_WAIT: 'Vent venligst ... ',
    CHOOSE_RESTAURANT: 'Vælg restaurant',
    CLIENT_OPEN_HOURS: 'Åbningstider i appen',
    CLOSE: "Steng",
    CLOSED: 'Lukket',
    COMMENT: 'Kommentar',
    CONFIRM: 'Bekræft',
    CREATE_LOGIN: 'Opret konto / Log ind',
    CURRENT: 'Aktuelt',
    CUSTOM_TICKET_VALID: 'Gyldigt indtil',
    CUSTOMER_CODE_TYPE_4_DIGITS: 'Skriv 4 cifre',
    CUSTOMER_TAB: 'Regning',
    CUSTOMER_TAB_KEY_KOD: 'Bordkode',
    CUSTOMER_TAB_KEY_SAME_CODE: 'Samme kode igen? Klik her',
    CUSTOMER_TAB_KEY_WRONG_CODE: 'Forkert bordkode',
    CUSTOMER_TABS_ATTENTION_MSG: 'Vi kommer straks og hjælper dig',
    CUSTOMER_TABS_CALL_WAITER: 'Tilkald tjener',
    CUSTOMER_TABS_CUSTOM_AM1: 'Valgfrit beløb...',
    CUSTOMER_TABS_CUSTOM_AM2: 'Valgfrit beløb...',
    CUSTOMER_TABS_PAY_IN_APP: 'Betal i appen',
    CUSTOMER_TABS_TIPS: 'Vil du give noget ekstra til vores personale?',
    DATE: 'Dato',
    DATE_TIME: 'Dato og tidspunkt',
    DELIVERY_ALCOHOL_DELIVERY: 'Du kan ikke bestille alkohol til udbringning',
    DELIVERY_ALCOHOL_TAKE_AWAY: 'Du kan ikke bestille alkohol som take away',
    DELIVERY_FETCH_BAR: 'Hent i baren',
    DELIVERY_HOME_DELIVERY: 'Udbringning',
    DELIVERY_INFORMATION: 'Leverings information',
    DELIVERY_MY_TABLE: 'Server ved mit bord',
    DELIVERY_OFFER_ERROR: 'Rabatkoden er ikke gyldig for denne serveringstype',
    DELIVERY_QUESTION: 'Hvordan vil du modtage din bestilling?',
    DELIVERY_SERVING: 'Servering',
    DELIVERY_TO_GO: 'To go',
    DELIVERY_CHANGE_OPTION: 'Skift leveringsmetode',
    DISCOUNT: 'Rabat',
    DONE: 'Færdig',
    EMAIL: 'E-mail',
    EMAIL_FOLLOW_INSTRUCTIONS: 'Åbn din indbakke og følg instruktionerne',
    EMAIL_SENT: 'E-mail sendt',
    ENTER_CARD_NUMBER_AT_START_OF_PAYMENT: 'Kortnummer udfyldes ved start af betaling.',
    ERROR: 'Fejl',
    FORWARD: 'Videre',
    FREE_CHARGE: 'Der skal ikke betales for dette køb.',
    FRI: 'Fre',
    FRIDAY:'Fredag',
    GO_TO_PAYMENT_PAY:'Gå til betaling',
    HANDLE_NOTIFICATIONS: 'Administrer notifikationer',
    HOUR: 'Time',
    HOW_TO_PAY:'hvordan vil du betale??',
    HOW_TO_PAY_SWISH:'Hvordan vil du betale med Swish?',
    INIT_SWISH_APP: 'Vi forbereder din swish betaling, et øjeblik...',
    ITEM_DESCRIPTION_OUT_OF_STOCK: 'Udsolgt',
    ITEM_DESCRIPTION_SCROLL: 'Scroll ned',
    LOCATION_ADRESS: 'Din adresse',
    LOCATION_CHOSE: 'Vælg plads',
    LOCATION_COMMENT: 'Kommentar (etage, postnummer etc.)',
    LOCATION_FEE: 'Leveringsomkostninger',
    LOCATION_OTHER_ADRESS: 'Prøv en anden adresse',
    LOCATION_QUESTION: 'Levering hertil?',
    LOCATION_UNFORTUNATELY_OTHER_ADRESS: 'Desværre leverer vi ikke hertil endnu. Prøv en anden adresse.',
    LOGGING_IN: 'Du bliver nu logget ind',
    LOGGING_IN_2: 'Logger ind',
    LOGIN: 'Log ind',
    LOGIN_APPLE: 'Log ind med Apple',
    LOGIN_CONTACTING_FACEBOOK: 'Kontakter Facebook',
    LOGIN_CREATE: 'Opret',
    LOGIN_EMAIL: 'Log ind med e-mail',
    LOGIN_EMAIL_FORMAT: 'Din e-mail ser ud til at være forkert. Kontrollér venligst og prøv igen',
    LOGIN_EMAIL_IN_USE: 'Denne e-mail er allerede benyttet',
    LOGIN_FACEBOOK: 'Log ind med Facebook',
    LOGIN_FINALIZING: 'Afslutter',
    LOGIN_HI: 'Hej',
    LOGIN_NAME_NEEDED: 'Dit navn er nødvendigt for at matche dig med dine ordrer',
    LOGIN_NEW_USER: 'Ny bruger',
    LOGIN_NO_ACCOUNT_Q: 'Ingen konto?',
    LOGIN_PHONE: 'Log ind med telefonnummer',
    LOGIN_PW_6: 'Din adgangskode skal minimum indeholde 6 tegn',
    LOGIN_RESET: 'Nulstil',
    LOGIN_TERMS: 'Betingelser og vilkår',
    LOGIN_VERIFYI: 'Verificerer',
    LOGIN_WAITING_CODE: 'Afventer kode...',
    LOGIN_WRITE_EMAIL: 'Skriv din e-mail',
    LOGOUT: 'Log ud',
    ME: 'Min konto',
    MENU_DRINKS_CLOSED: 'Der kan i øjeblikket ikke bestilles drikkevarer',
    MENU_NO_PRODUCTS: 'Der er ingen produkter at vise',
    MENU_NOT_AVAILABLE: 'i øjeblikket ikke tilgængelig',
    MENU_NOT_UPDATED: 'Det ser ud til, at restauranten endnu ikke har opdateret sin menu',
    MENU_ORDER_FULL: 'Du kan ikke tilføje yderligere til din ordre',
    MINUTE: 'Minut',
    MON: 'Man',
    MONDAY:'Mandag',
    MOVE:'Kom videre',
    MY_BOOKING: 'Min bestilling',
    MY_RECIEPTS: 'Mine kvitteringer',
    NAME: 'Navn',
    NEW_ORDER: 'Ny bestilling',
    NO: 'Nej',
    OFFERS: 'Tilbud',
    OFFERS_NONE: 'Der er ingen tilbud at vise på nuværende tidspunkt',
    OK: 'OK',
    OPEN: "Open",
    OPT_OUT: 'Nej tak',
    ORDER: 'Bestil',
    OR:'eller',
    ORDER_CONFIRMED: 'Din ordre er gennemført',
    ORDER_NOT_VALID: 'Ordrebeløbet er ugyldigt',
    ORDER_READY_ESTIMATION: 'Din ordre forventes at være klar inden for:',
    PASSWORD: "Adgangskode",
    PAY: 'Betal',
    PAY_NOW:'Betal nu',
    PAYED: 'Betalt',
    PAYMENT_ADD_CARD: 'Tilføj betalingskort',
    PAYMENT_APPLEPAY: 'Betal med Apple Pay',
    PAYMENT_BONUS: 'Brug bonuspoint',
    PAYMENT_DELIVERY: 'Leveringsomkostninger',
    PAYMENT_DEVICE_NOT_SUPPORTED: 'Enheden understøttes ikke!',
    PAYMENT_DISCOUNT: 'Rabat',
    PAYMENT_ERROR: 'Sørg for at dit kort kan benyttes til onlinekøb, og at der er et tilstrækkeligt beløb tilgængeligt',
    PAYMENT_ERROR_MAINTENANCE: 'En opdatering er i øjeblikket i gang, og dit køb kan ikke gennemføres',
    PAYMENT_EXTRA: 'Ekstra',
    PAYMENT_FIRST_DISCOUNT: 'på vej til din første rabat!',
    PAYMENT_INIT:'Forberedelse af betaling',
    PAYMENT_OFFER_ERROR_HEADER: 'Disse tilbud kunne ikke aktiveres',
    PAYMENT_OFFER_ERROR_TEXT: 'De gælder ikke for den serveringstype, du har valgt',
    PAYMENT_PAY: 'Betal',
    PAYMENT_PAYMENTS: 'Betalingsmetode',
    PAYMENT_SAVED_CARDS: 'Gemte betalingskort',
    PAYMENT_SCROLL: 'Scroll ned for at betale',
    PAYMENT_SWISH: 'Betal med Swish',
    PAYMENT_SWISH_COMPLETE: "Gennemfør din betaling i Swish-appen",
    PAYMENT_SWISH_OPEN_APP: "Åbn Swish",
    PAYMENT_SWISH_NO_APP: "Vi kan ikke finde Swish på denne enhed. <br> Skift venligst til at betale på en anden enhed.",
    PAYMENT_TABLETAB: 'Tilføj til bordregning',
    PAYMENT_TITLE: 'Betale for',
    PAYMENT_TOTAL: 'Total',
    PAYMENT_VIPPS: 'Betal med Vipps',
    PAYMENT_VIPPS_START: "Start betaling med Vipps?",
    PAYMENT_VIPPS_COMPLETE: "Gennemfør din betaling i Vipps-appen",
    PAYMENT_VIPPS_OPEN_APP: "Åbn Vipps",
    PAYMENT_VIPPS_SHOW_ORDER: "Klik på vis ordre.",
    PAYMENT_VIPPS_SHOW_ORDER_WHEN_PAYED: "Klik på vis ordre efter gennemført betaling.",
    PAYMENT_VIPPS_NO_APP: "Vi kan ikke finde Vipps på denne enhed. <br> Skift venligst til at betale på en anden enhed.",
    PAYMENT_SWISH_INIT: "Forberedelse af Swish",
    PAYMENT_VERYFING: "Bekræfter...",
    PAYMENT_GO_TO_ORDER: "Videre til ordrer",
    PAYMENT_RETRY: "Prøv igen",
    PAYMENT_OTHER_DEVICE: "Anden enhed",
    PAYMENT_THIS_DEVICE: "Denne enhed",
    PAYMENT_VIPPS_INIT: "Forberedelse af Vipps",
    PAYMENT_VIPPS_NO_DATA: "Mangler data for at kunne foretage en Vipps-betaling.",
    PAYMENT_DONE: "Klar!",
    PAYMENT_ERROR_HEADER: "Betalingsfejl",
    PAYMENT_WAIT_CONFIRM: "Afventer bekræftelse",
    PAYMENT_CONFIRMED: "Din betaling er registreret",
    PAYMENT_NOT_CONFIRMED: "Betalingen for din ordre er endnu ikke blevet registreret.",
    PHONE: 'Telefon',
    PLEASE_WAIT:'Vent venligst...',
    PURCHASE_HISTORY_Brutto: 'Brutto',
    PURCHASE_HISTORY_BUY_AT: 'Køb hos',
    PURCHASE_HISTORY_COLLECTED_DISCOUNT: 'Samlet rabat',
    PURCHASE_HISTORY_CONTROL_UNIT: 'K.enhed',
    PURCHASE_HISTORY_DISCOUNT: 'Rabat',
    PURCHASE_HISTORY_FILL_MAIL: 'Skriv din mailadresse',
    PURCHASE_HISTORY_MAIL_RECIEPT: 'Send kvitteringen ',
    PURCHASE_HISTORY_NETTO: 'Netto',
    PURCHASE_HISTORY_ORDER: 'Ordre',
    PURCHASE_HISTORY_PHONE: 'Telefon',
    PURCHASE_HISTORY_RECIEPT_NO: 'Kvitteringsnummer',
    PURCHASE_HISTORY_ROUNDING: 'Afrundning',
    PURCHASE_HISTORY_SENDING_RECIEPT: 'Sender kvittering til',
    PURCHASE_HISTORY_SENDING_SENT: 'Kvitteringen blev sendt til',
    PURCHASE_HISTORY_SUM: 'Sum',
    PURCHASE_HISTORY_TOTAL: 'I alt',
    PURCHASE_HISTORY_VAT: 'Moms',
    PURCHASE_HISTORY_VATID: 'Moms ID',
    PURCHASE_COMPLETE:'Du gennemførte et køb kl',
    PURCHASE_CANCEL:'Du annullerede et køb kl',
    PUSH_ALERT_BTN: 'Klik her',
    PUSH_ALERT_TXT: 'For at se status på din bestilling skal du tillade push-beskeder',
    PUSH_CHANGE_SELECTION: 'Du kan altid ændre dit valg under Konto => Push-beskeder',
    PUSH_INFO: 'Vælg de restauranter, du ønsker at modtage tilbud fra. Vi lover at håndtere din tillid forsvarligt.',
    PUSH_PERMISSION: 'Du bedes gå til telefonens indstillinger og tillade push-beskeder for appen, før du kan foretage denne ændring.',
    PUSH_TITLE: 'Notifikationer',
    QUANTITY: "Antal",
    RECIEPTS: 'Kvittering',
    RESTAURANT_INFO: 'Restaurantinformation',
    SAT: 'Lør',
    SATURDAY:'Lørdag',
    SCAN_QR_WITH_SWISH:'Scan QR-koden med Swish-appen',
    SAVED_CARDS: 'Gemte betalingskort',
    SEARCH: 'Søg…',
    SELECT_LANGUAGE: 'Vælg dit sprog',
    SEND_SMS_CODE: 'Send SMS-kode',
    SENT: 'Sendt',
    STOCK_TITLE: 'Udsolgt',
    SUM: 'Sum',
    SUN: 'Søn',
    SUNDAY:'Søndag',
    TABLENUMBER: 'Bordnummer',
    THU: 'Tor',
    THURSDAY:'Torsdag',
    TIPS: 'Drikkepenge',
    TODAY: 'I dag',
    TUE: 'Tir',
    TUESDAY:'Tirsdag',
    WED: 'Ons',
    WEDNESDAY:'Onsdag',
    WEEK: 'Uge',
    WEEKLY_LUNCH: 'Ugens menu',
    WELCOME: 'Velkommen!',
    WRITE_TABLENUMBER: 'Skriv dit bordnummer',
    YES: 'Ja',
    HOME:'Hjem',
    DRINKS:'Drikke',
    FOOD:'Mad',
    DISCOUNT_CODE:'Rabattkode',
    FOLLOW:'Følg os',
    SAVE_CARD_QUESTION:'Tilføj kort til fremtidige køb.',
    SAVE_CARD_QUESTION_HEADER:'Gemme dit kort?',
    // Reepay charge errors
    ACQUIRER_ERROR: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    INSUFFICIENT_FOUNDS: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    PAYMENT_NOT_AUTHORIZED: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    CREDIT_CARD_EXPIRED: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    DECLINED_BY_ACQUIRER: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    CREDIT_CARD_LOST_OR_STOLEN: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    CREDIT_CARD_SUSPECTED_FRAUD: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    REFOUND_AMOUNT_TOO_HIGH: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    AUTHORIZATION_EXPIRED: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    AUTHORIZATION_AMOUNT_EXCEEDED: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    AUTHORIZATION_VOIDED: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    SCA_REQUIRED: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    RISK_FILTER_BLOCK: 'Der gik noget galt med din bestilling. Kontakt venligst restauranten',
    PAYMENT_AND_ORDER_IS_PLACED: "Din ordre er betalt og bestilt",
    DONT_CLOSE_WEB_BROWSER: "BEMÆRK! Luk ikke browseren!",
    ORDER_INFORMATION: "<p> Når ordren er gennemført, vises en dialog i browseren. </p> <p> Din kvittering findes under <b> Mine kvitteringer </b> og kan sendes til den ønskede e-mail-adresse. </p> <p> Din ordre vises under aktive ordrer, og du vil automatisk blive sendt derhen, når du bekræfter denne dialog. </p>",


    // VIPPS
    VIPPS_ERROR: "Vipps nekade din betalning.",
    VIPPS_CANCELLED: "Användare avbröt betalningen",
    VIPPS_PAYMENT_41 : "The user does not have a valid card",
    VIPPS_PAYMENT_42 : "Refused by issuer bank",
    VIPPS_PAYMENT_43 : "Refused by issuer bank because of invalid a amount",
    VIPPS_PAYMENT_44 : "Refused by issuer because of expired card",
    VIPPS_PAYMENT_45 : "Reservation failed. Either because the user cancelled, did not act, or for some unknown reason.",
    VIPPS_PAYMENT_51 : "Cannot cancel an already captured order",
    VIPPS_PAYMENT_52 : "Cancellation failed",
    VIPPS_PAYMENT_53 : "Cannot cancel an order that is not reserved",
    VIPPS_PAYMENT_61 : "Captured amount exceeds the reserved amount",
    VIPPS_PAYMENT_62 : "The amount you tried to capture is not reserved",
    VIPPS_PAYMENT_63 : "Capture failed for some unknown reason, please use /details endpoint to know the exact status",
    VIPPS_PAYMENT_71 : "Cannot refund more than captured amount",
    VIPPS_PAYMENT_72 : "Cannot refund a reserved order (only captured orders), please use the /cancel endpoint",
    VIPPS_PAYMENT_73 : "Cannot refund a cancelled order",
    VIPPS_PAYMENT_93 : "Captured amount must be the same in an Idempotent retry",
    VIPPS_PAYMENT_95 : "Payments can only be refunded up to 365 days after reservation",
    VIPPS_PAYMENT_96 : "Payments can only be captured up to 180 days after reservation",
    VIPPS_PAYMENT_1501 : "This person can not pay to companies. Used for Vipps users under the age of 15.",
    VIPPS_PAYMENT_1082 : "This person is not BankID verified. Only applies for test users.",
    VIPPS_VIPPSERROR_91: "Transaction is not allowed",
    VIPPS_VIPPSERROR_92: "Transaction already processed",
    VIPPS_VIPPSERROR_94: "Order locked and is already processing. This can occur if a bank has problems, and Vipps needs to wait and/or clean up.",
    VIPPS_VIPPSERROR_98: "Too many concurrent requests. Used only to prevent incorrect API use.",
    VIPPS_VIPPSERROR_99: "Description of the internal error",
    VIPPS_USER_81: "User not registered with Vipps",
    VIPPS_USER_82: "User app version not supported",
    VIPPS_MERCHANT_31: "Merchant is blocked because of",
    VIPPS_MERCHANT_32: "Receiving limit of merchant is exceeded",
    VIPPS_MERCHANT_33: "Number of payment requests has been exceeded",
    VIPPS_MERCHANT_34: "Unique constraint violation of the orderId",
    VIPPS_MERCHANT_35: "Registered order not found",
    VIPPS_MERCHANT_36: "Merchant agreement not signed",
    VIPPS_MERCHANT_37: "Merchant not available, deactivated or blocked. See the FAQ.",
    VIPPS_MERCHANT_38: "Sale unit is not allowed to skip the landing page",
    VIPPS_MERCHANT_21: "Reference orderId is not valid",
    VIPPS_MERCHANT_22: "Reference orderId is not in valid state",
    VIPPS_MERCHANT_97: "The merchant is not approved by Vipps to receive payments",

    // Swish
    SWISH_ERROR_FF08: 'Swish nægtede din betaling.', // PaymentReference is invalid.
    SWISH_ERROR_RP03: 'Swish nægtede din betaling.', // Callback URL is missing or does not use HTTPS.
    SWISH_ERROR_BE18: 'Swish nægtede dit nummer, sørg for at du har indtastet det rigtige nummer.', // Payer alias is invalid.
    SWISH_ERROR_RP01: 'Swish nægtede din betaling.', // Missing Merchant Swish Number.
    SWISH_ERROR_PA02: 'Swish nægtede betalingen, fordi beløbet ikke er gyldigt.', // Amount value is missing or not a valid number.
    SWISH_ERROR_AM02: 'Swish nægtede betalingen, fordi beløbet er for stort.', // Amount value is too large..
    SWISH_ERROR_AM03: 'Swish afviste betalingen, fordi valutaen mangler eller ikke understøttes.', // Invalid or missing Currency.
    SWISH_ERROR_AM06: 'Swish nægtede betalingen, fordi beløbet er mindre end den aftalte grænse.', // Specified transaction amount is less than agreed minimum.
    SWISH_ERROR_AM21: 'Swish nægtede betalingen, fordi beløbet er større end hvad din bank tillader.', // Transaction amount exceeds Swish limit agreed between bank and payer for given period. Please inform the payer to contact their bank for more information on how to adjust the Swish limits.
    SWISH_ERROR_RP02: 'Swish nægtede din betaling.', // Wrong formatted message..
    SWISH_ERROR_RP06: 'Swish afviste betalingen, fordi du allerede har en anden aktiv betaling, bedes du annullere og prøve igen.', // A payment request already exists for that payer. Only applicable for Swish e-commerce.

    SWISH_ERROR_RF07: 'Swish nægtede betalingen, sørg for at du har penge til rådighed for Swish.', // Transaction declined.
    SWISH_ERROR_ACMT03: 'Swish afviste betalingen, fordi dit nummer ikke ser ud til at være aktiveret for Swish.', // Payer not Enrolled.
    SWISH_ERROR_ACMT01: 'Swish nægtede betalingen.', // Counterpart is not activated.
    SWISH_ERROR_ACMT07: 'Swish afviste betalingen, fordi dit nummer ikke ser ud til at være aktiveret for Swish.', // Payee not Enrolled.
    SWISH_ERROR_VR01: 'Swish afviste betalingen, fordi denne Swish-konto ikke opfylder alderskravet for dette køb.', // Payer does not meet age limit.
    SWISH_ERROR_VR02: 'Swish nægtede betalingen', // The payer alias in the request is not enrolled in swish with the supplied ssn.
    SWISH_ERROR_TM01: 'Swish nægtede betalingen, fordi du ikke gennemførte betalingen i Swish inden for den fastsatte frist.',// Swish timed out before the payment was started
    SWISH_ERROR_BANKIDCL: 'Swish nægtede betalingen, fordi du annullerede BankID-signeringen.', // Payer cancelled BankID signing
    SWISH_ERROR_BANKIDONGOING: 'Swish nægtede betalingen, fordi du allerede har en anden BankID-signering aktiv, afslut den og prøv igen ..', // BankID already in use.
    SWISH_ERROR_BANKIDUNKN: 'Swish nægtede betalingen, fordi BankID ikke kunne godkende din betaling.', // BankID is not able to authorize the payment..


    SWISH_ERROR_CONNECTION: '<p>Sauthend :(</p> <p>Der var problemer i kontakten under betalingen. Kontroller, at din ordre er bekræftet, ellers bestil igen.',

    SWISH_CANCELLED: 'Betaling blev annulleret.', // Unhandled error in PubQ backend
    SWISH_DECLINED: 'Betaling blev annulleret.', // Unhandled error in PubQ backend

    SWISH_ERROR: 'Swish nægtede din betaling.', // Unhandled error in PubQ backend

    SWISH_DEMAND_NUMBER: '<p> Du skal indtaste et gyldigt nummer for at kunne betale. </p> <p> Din ordre vil blive underrettet via SMS, når den er klar. </p>',

    WAITING_ON_SWISH_PAYMENT: 'Betal for din ordre i Swish-appen',

    // Payment V2

    SHOW_ORDERS:'Vis ordrer',
    VERIFY_TIMEOUT: '<p> Din betaling blev annulleret, fordi du ikke gennemførte betalingen inden for den fastsatte frist. </p>',


     //NOT ACTIVATED COMPONENT
     UNIT_NOT_ACTIVATED: 'Denne enhed er endnu ikke aktiveret til webbestilling!',
     CONTACT_ADMIN_UNIT:'Kontakt venligst administratoren for at aktivere enheden.',
     NO_CLIENT_DATA:'Ingen klientdata eller definerede enheder til klient.',

     ABLE_ORDER_ONLINE:"Kan bestilles online.",
     ALLOW_LOCATION:"'Tillad venligst placeringsadgang og opdater siden!'",
    CONTACT_SUPPORT_SWISH:'Mangler data for at kunne foretage en Swish-betaling, kontakt support.',
    CONFIRMED:"Confirm",
    CANCEL_SWISH_VERIFY:'Fortryd ikke, hvis du har gennemført betalingen. Vil du annullere?',
    CONTACT_SUPPORT_PAYMENT:"Manglende data for at kunne foretage en betaling, kontakt support",
    CONTACT_ADMINISTRATOR:"Kontakt venligst administratoren for at aktivere enheden.",
    INSERT_CARD_:"Tilføj kort på nytt",
    LOGIN_MOBILE_NEEDED:'Dit nummer er påkrævet, hvis vi skal kontakte dig',
    NEED_TO_INSERT_CARD_AGAIN:"Du skal indsætte kortet igen for at fortsætte betalingen",
    NOT_ACTICATED_FOR_WEB_ORDERING:"Denne enhed er endnu ikke aktiveret til webbestilling!",
    NOTE:"Bemærk",
    ORDERING_NOT_POSSIBLE:'Kan ikke bestille nu',
    PENDING_ORDER:'Afventende ordrer',
    TO_HOMEPAGE:'Internet side',
    UNABLE_TO_ORDER:"Kan ikke bestille online -",
    OUR_POLICY:'Vores politik',
}
