import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ClientContextData, ContextProvider } from '../context/context';
import { PubqConfig, PubqConfigProvider } from '../pubq-config/pubq-config';
import { HttpClient } from '@angular/common/http';
import { PaymentV2Response } from './payment';
import { Subscription } from 'rxjs';
import { Order } from '../order/order';
import { UserProvider } from '../user/user';
import { AppConfiguration } from 'src/app/app.configuration';
import { PaymentUtils } from './paymentUtils';
import { environment } from 'src/environments/environment';
let appSettings = require("../../../../cordova-scripts/appsettings");

@Injectable({
    providedIn: "root"
})
export class CardPaymentProvider {
    appBuild = "";
    config: PubqConfig;
    sub: Subscription;

    constructor(
        private contextProvider: ContextProvider,
        private pubqConfigProvider: PubqConfigProvider,
        private http: HttpClient,
        private platform: Platform,
        private userProvider: UserProvider,
        private paymentUtils: PaymentUtils
    ) {

        this.sub = this.pubqConfigProvider.config.subscribe((x: PubqConfig) => {
            if (!x) return;
            this.config = x;
        });
        // this.platform.ready().then(async x => {
        //     this.appBuild = `${await this.appVersion.getVersionNumber()}_${await this.appVersion.getVersionCode()}`
        // })
        this.appBuild = appSettings && appSettings.version ? appSettings.version : 'N/A';
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe();
    }
    
    async initiate(options: { paymentSource: any, order: Order, clientContextData: ClientContextData }, idempotencyKey: string, orderId: string): Promise<PaymentV2Response> {
        let receiptOnly = false;
        try {
            receiptOnly = this.contextProvider.clientContext.appInfo.value.Context.receiptOnly;
        } catch (error) {
            receiptOnly = false;
        }

        options.order.id = orderId;

        const providerData = {
            type: options.paymentSource.type,
            paymentSource:options.paymentSource,            
            provider: AppConfiguration.paymentProvider,
            customer: null
        }
        var promise = new Promise(async (resolve, reject) => {
            let sub = (await this.userProvider.getUserPaymentSourcesV2(this.userProvider.currentUser.value.id)).subscribe(x => {
                try {
                    if (x[0]) {
                        let customerId = x[0].data.recurringReference.split("&")[0];
                        resolve(customerId);
                    }
                    else
                        resolve(null)

                } catch (error) {
                    resolve(null);
                }

                setTimeout(() => {
                    sub.unsubscribe();
                }, 100);
            });

        })

        providerData.customer = await promise;        
        const body = {
            idempotencyKey: idempotencyKey,
            order: options.order,
            paymentType: options.paymentSource.type,
            userPaymentSource: options.paymentSource,
            clientContext: options.clientContextData,
            receiptOnly,
            providerData
        };

        // let url = `${this.config.ApiUrl}/payments/v1/idempotency/initiate`;
        // let resp = await this.http.post<PaymentV2Response>(url, body).toPromise();
        // return resp;

        return this.paymentUtils.initiatePaymentWithRetry(body, this.config, this.http);
    }

    async charge(idempotencyKey: string, routeName: string): Promise<PaymentV2Response> {

        const body = {
            idempotencyKey: idempotencyKey,
            routeName: routeName,
        };

        let url = `${environment.apiUrl}/v1/idempotency/charge`;
        let resp = await this.http.post<PaymentV2Response>(url, body).toPromise();
        return resp;
    }

    async verify(idempotencyKey: string, routeName: string): Promise<PaymentV2Response> {

        return this.paymentUtils.verifyPaymentWithRetry(idempotencyKey, routeName, this.config, this.http);
    }

    async initiateAddCard(clientContext,  client, locale){
        if (AppConfiguration.paymentProvider === "stripe"){

            let body = {
                clientContext,
                customer: null, // Stripe customer
                locale,
                client
            }

            var promise = new Promise(async (resolve, reject) => {
                let sub = (await this.userProvider.getUserPaymentSourcesV2(this.userProvider.currentUser.value.id)).subscribe(x => {
                    try {
                        if (x[0]) {
                            let customerId = x[0].data.recurringReference.split("&")[0];
                            resolve(customerId);
                        }
                        else
                            resolve(null)
    
                    } catch (error) {
                        resolve(null);
                    }
    
                    setTimeout(() => {
                        sub.unsubscribe();
                    }, 100);
                });
    
            })
    
            body.customer = await promise;

            let url = `${environment.apiUrl}/v1/stripe/card/add/initiate`;
            let resp = await this.http.post<any>(url, body).toPromise();
            return resp;
        }
    }
}
