import { Component, ViewChild } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { LoginPage } from './login/login';


@Component({
    styleUrls: [],
    selector: 'login-nav',
    templateUrl: 'login-nav.component.html'
})
export class LoginNavComponent {
    @ViewChild('loginNav') nav: IonNav;
    constructor(modalCtrl: ModalController) {

    }
    ngAfterViewInit() {
        this.nav.push(LoginPage, { nav: this.nav });
    }
}
