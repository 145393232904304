import { CssSelector } from '@angular/compiler';
import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { map } from 'rxjs/operators';
import { MenuItem } from '../models';
import { LanguagePipe } from '../pipes/language.pipe';
import { CartProvider } from '../services/cart/cart.service';
import { LanguageProvider } from '../services/language/language.provider';

@Component({
  selector: "app-stock",
  templateUrl: "stock.component.html",
  styleUrls: ["stock.component.scss"],
})
export class StockModalComponent {
  @Input() routeName;
  @Input() data: { name: string, quantity: number }[];
  @Input() updatedCart: MenuItem[];

  msgString: string = "";

  constructor(
    private modalController: ModalController,
    private cartProvider: CartProvider,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe
  ) { }

  ngOnInit() {
    let missingProducts = this.getMissingProducts();
    this.msgString = this.languagePipe.transform(`Tyvärr saknades ${missingProducts} i lager, din varukorg har justerats efter vad som fanns tillgängligt så du kan fortsätta handla.`,
      'STOCK_ALERT', this.languageProvider.currentLanguage, [missingProducts]);
  }

  async ngAfterViewInit() {

  }

  getMissingProducts() {
    let str = "";
    for (let i = 0; i < this.data.length; i++) {
      str += `${this.data[i].name} x ${this.data[i].quantity}`;
      if (i < this.data.length - 1) str += ',';
    }
    return str;
  }

  goBack() {

    this.cartProvider.empty();

    this.updatedCart.forEach((item) => {

      for (let i = 0; i < item.NrOfItems; i++) {
        this.cartProvider.addItem(item);
      }
    })
    this.dismiss();
  }

  dismiss() {
    this.modalController.dismiss(null,
      "cancel"
    );
  }

  forward() {
    this.modalController.dismiss(null, "confirm");
  }
}
