import { Location } from "./location";
import { ClientInfo } from "./context/context";
import { City } from '../models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: "root"
})
export class LocationSorter {
  public sortUnits(userLocation: Location, units: ClientInfo[]): ClientInfo[] {
    for (const unit of units) {
      unit.distanceInKm = this.getDistance(unit.location, userLocation);
    }
    return units.sort((u1, u2) => {
      return u1.distanceInKm - u2.distanceInKm;
    });
  }

  public sortCities(userLocation: Location, cities: City[]): City[] {
    for (const city of cities) {
      city.distanceInKm = this.getDistance(city.location, userLocation);
    }
    return cities.sort((c1, c2) => {
      return c1.distanceInKm - c2.distanceInKm;
    });
  }

  public getDistance(location1: Location, location2: Location) {
    return this.getDistanceFromLatLonInKm(location1, location2);
  }

  // Code taken from https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
  private getDistanceFromLatLonInKm(loc1: Location, loc2: Location): number {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(loc2.latitude - loc1.latitude);  // deg2rad below
    var dLon = this.deg2rad(loc2.longitude - loc1.longitude);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(loc1.latitude)) * Math.cos(this.deg2rad(loc2.latitude)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }

  private deg2rad(deg): number {
    return deg * (Math.PI / 180)
  }
}