import { Component } from '@angular/core';

// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'page-splash',
    templateUrl: 'splashScreen.html',
    styleUrls: ['splashScreen.scss']
})
export class SplashScreenComponent {

    constructor(
      public modalCtrl: ModalController,
      // public splashScreen: SplashScreen
    ) {

    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.modalCtrl.dismiss(null,null,"splash");
        }, 3000);
    }
}
