import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { PubqConfig, PubqConfigProvider } from '../pubq-config/pubq-config';
import { ContextProvider } from '../context/context';
import { AngularFirestore } from "@angular/fire/firestore"
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from "firebase/app";
import "firebase/auth";
//const debugUrl = 'http://localhost:5001/pq-dev/us-central1'
//const debugUrl ='https://us-central1-pq2-staging.cloudfunctions.net/checkBonus' ;


@Injectable({
  providedIn: "root"
})
export class BonusProvider {
  config: PubqConfig;
  subscriptions = new Subscription();
  routeName: string;
  amount: number;
  bonus: Voucher;
  globalOffers = new BehaviorSubject<any[]>([]);
  claimedOffers: any[] = [];
  globalOfferSubscription: Subscription;
  claimsSubscription: Subscription;
  constructor(private pubqConfigProvider: PubqConfigProvider,
    private contextProvider: ContextProvider,
    public http: HttpClient,
    private afStore: AngularFirestore,
    private afDatabase: AngularFireDatabase,
  ) {

    this.pubqConfigProvider.config.subscribe((x: PubqConfig) => {
      if (!x) return;
      this.config = x;
    });
    this.subscriptions.add(
      this.contextProvider.clientContext.currentClient.subscribe(x => {
        if (!x) return;
        this.routeName = x.routeName;
        let appInfoSub = this.contextProvider.clientContext.appInfo.subscribe(appInfo => {
          if (!appInfo)
            return;
          if (appInfo.Context.globalOffersEnabled)
            this.setupGlobalOffers();
          // setTimeout(() => {
          //   appInfoSub.unsubscribe();
          // }, 100);
        });

        //this.setupGlobalOffers();

      })
    );
  }

  setupGlobalOffers() {
    if (this.globalOfferSubscription)
      this.globalOfferSubscription.unsubscribe();
    this.globalOfferSubscription = this.afStore.collection("marketing").doc(this.routeName).collection("globalOffers").valueChanges().subscribe(x => {
      let authSub = firebase.auth().onAuthStateChanged(async (user) => {
        if (!user) {
          this.globalOffers.next(x);
          return;
        }
        let voucherFranchiseId = (
          await this.afDatabase.database.ref(`adminApp/clients/${this.routeName}/settings/receiptApi/voucherFranchiseId`)
            .once("value")).val();
        if (!voucherFranchiseId) return;
        if (this.claimsSubscription)
          this.claimsSubscription.unsubscribe();
        this.claimsSubscription = this.afStore.collection("marketing").doc("franchises").collection(voucherFranchiseId).doc("userInteraction")
          .collection(user.uid).doc("claimedOffers").collection("offers").valueChanges().subscribe(claims => {
            this.claimedOffers = [];
            claims.forEach(claim => {
              this.claimedOffers.push(claim);
            });
            let globalOffers = x;
            for (let index = globalOffers.length - 1; index >= 0; index--) {
              const element = globalOffers[index];
              this.claimedOffers.forEach(claim => {
                if (claim.id === element.id)
                  globalOffers.splice(index, 1);
              });

            }
            this.globalOffers.next(globalOffers);
          });
          let globalOffers = x;
          for (let index = globalOffers.length - 1; index >= 0; index--) {
            const element = globalOffers[index];
            this.claimedOffers.forEach(claim => {
              if (claim.id === element.id)
                globalOffers.splice(index, 1);
            });

          }
          this.globalOffers.next(globalOffers);
        authSub();
      });
    });
  }

  async getBonus(routeName: string, userId: string) {
    const body = {
      userId: userId,
      routeName: routeName
    };
    await this.http.post<any>(`${this.config.ApiUrl}/checkBonus`, body).toPromise().then((body) => {
      if (body.error == undefined) this.bonus = body.voucher;
    })
    return this.bonus;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.claimsSubscription.unsubscribe();
    this.globalOfferSubscription.unsubscribe();
    // this.userSubscription && this.userSubscription.unsubscribe();
    // this.paymentSourcesSubscription && this.paymentSourcesSubscription.unsubscribe();
  }
}

export class Voucher {
  id: string;
  salesLocationIdSold: string;
  clientGroupId: string;
  userId: string;
  physicalCardGuid: string;
  friendlyId: string;
  soldDate: number;
  value: number
  voucherType: number;
  currencyCode: string;
  active: boolean;
  validTo: number;
  franchiseString: string;
}
