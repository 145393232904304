import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ChargeCustomerComponent } from './payment/chargeCustomer.component';
import { UnitSelectionComponent } from './unit-selection/unitSelection.component';

import { PaymentConfirmationComponent } from './payment/paymentConfirmation/paymentConfirmation.component';


import { CartPage } from "./cart/cart";
import { MenuPage } from "./menu/menu";

import { NoRoutePage } from './no-route/no-route.page';
import { NotActivated } from './notActivated/notActivated.component';
import { PaymentStatusComponent } from './payment/paymentStatus/paymentStatus.component';

const routes: Routes = [

  {
    path: 'main/:client/:city/:routeName',
    // loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
    component: MenuPage,
    pathMatch: 'full'
  },
  {
    path: 'restaurant/:routeName',
    // loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
    component: MenuPage,
    pathMatch: 'full'
  },
  {
    path: 'restaurant/:routeName:/order/:orderId',
    // loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
    component: MenuPage,
    pathMatch: 'full'
  },
  {
    path: 'u/:routeName/kiosk',
    loadChildren: () => import('./kioskModule/kiosk.module').then(m => m.KioskModule)
  },
  {
    path: 'u/:routeName',
    // loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
    component: MenuPage,
    pathMatch: 'full'
  },
  {
    path: 'order/:status',
    component: PaymentStatusComponent,
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./account/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'unitselection/:client/:city',
    component: UnitSelectionComponent,
    pathMatch: 'full'
  },
  {
    path: 'units/:client/:city',
    component: UnitSelectionComponent,
    pathMatch: 'full'
  },
  {
    path: 'restaurant/:routeName/charge',
    component: ChargeCustomerComponent,
    pathMatch: 'full'
  },
  {
    path: 'u/:routeName/charge',
    component: ChargeCustomerComponent,
    pathMatch: 'full'
  },
  {
    path: 'restaurant/:routeName/complete',
    component: PaymentConfirmationComponent
  },
  {
    path: 'u/:routeName/complete',
    component: PaymentConfirmationComponent
  },
  {
    path: 'restaurant/:routeName/cart',
    component: CartPage,
    pathMatch: 'prefix'
  },
  {
    path: 'u/:routeName/cart',
    component: CartPage,
    pathMatch: 'prefix'
  },
  {
    path: 'not-activated',
    component: NotActivated
  },
  {
    path: '',component:NoRoutePage
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
