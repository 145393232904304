import { Component, Input } from '@angular/core';
import { User, UserProvider } from 'src/app/services/user/user';
import { AppConfiguration } from 'src/app/app.configuration';
// import { Facebook } from '@ionic-native/facebook/ngx';
import {
  NavController,
  AlertController,
  ModalController,
  LoadingController,
  Platform,
  IonNav,
} from '@ionic/angular';
// import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ContextProvider } from 'src/app/services/context/context';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { Observable } from 'rxjs';
import { PhoneFormPage } from './phone/phoneForm';
import { ActivatedRoute } from '@angular/router';
import { LoginCompleteComponent } from './login-complete.component';
import { EmailForm } from './emailForm.component';
import { PubqConfigProvider } from 'src/app/services/pubq-config/pubq-config';
import { HttpClient } from '@angular/common/http';
import { LanguageProvider } from 'src/app/services/language/language.provider';
import { LanguagePipe } from 'src/app/pipes/language.pipe';
import { AngularFireAuth } from '@angular/fire/auth';

declare var cordova;
@Component({
  selector: 'page-login',
  templateUrl: 'login.html',
  styleUrls: ['login.scss'],
  providers: [AngularFireAuth]
})
export class LoginPage {
  @Input() nav: IonNav;
  user = new User();
  userOrigin = AppConfiguration.userOrigin;
  signInMethods = { phone: true, apple: false, facebook: true, email: true };
  constructor(
    // private facebook: Facebook,
    public navCtrl: NavController,
    private activatedRoute: ActivatedRoute,
    // private iab: InAppBrowser,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public userProvider: UserProvider,
    private contextProvider: ContextProvider,
    private platform: Platform,
    private pubqConfigProvider: PubqConfigProvider,
    private http: HttpClient,
    // private signInWithApple: SignInWithApple,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe,
    private afAuth: AngularFireAuth
  ) {
    firebase.auth().languageCode = 'sv';
    // let data = this.navParams.get("completePhoneAuthData");
    // if (data) this.completeInterruptedSignInWithPhone(data);
    //
    // this.contextProvider.clientContext.appInfo.subscribe((x) => {
    //   if (this.platform.is('ios')) {
    //     this.signInMethods = x.Context.signInMethods
    //       ? x.Context.signInMethods.ios
    //       : { phone: true, apple: false, facebook: true, email: true };
    //   } else {
    //     this.signInMethods = x.Context.signInMethods
    //       ? x.Context.signInMethods.android
    //       : { phone: true, apple: false, facebook: true, email: true };
    //   }
    // });
    this.signInMethods = { phone: true, apple: false, facebook: true, email: true };
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  async openPolicy() {
    const context = this.contextProvider.clientContext.appInfo.getValue().Context;
    const loading = await this.loadingCtrl.create({
      spinner: 'circular',
      duration: 5000,
    });
    loading.present();
    if (!context.policyUrl) {
      context.policyUrl = 'https://pubq.se/om-oss/villkor';
    }
    // const browser = this.iab.create(context.policyUrl, '_blank', {
    //   usewkwebview: 'yes',
    //   location: 'no',
    //   fullscreen: 'yes',
    //   enableViewportScale: 'yes',
    //   toolbar: 'yes',
    //   hidenavigationbuttons: 'yes',
    //   closebuttoncolor: '#8EA9F6',
    //   closebuttoncaption: this.languagePipe.transform('Stäng', 'CLOSED', this.languageProvider.currentLanguage),
    //   presentationstyle: 'fullscreen',
    //   transitionstyle: 'crossdissolve',
    //   toolbartranslucent: 'no',
    //   toolbarposition: 'bottom',
    // });
    // browser.on('loadstop').subscribe((ev) => {
    //   loading.dismiss();
    //   browser.show();
    // });
    window.open(context.policyUrl, '_blank');
  }
  getUser(id): Observable<{}> {
    return this.userProvider.getUser(id);
  }

  // async signInWithAppleId() {
  //     const provider = new firebase.auth.OAuthProvider('apple.com');
  //     return await this.afAuth.auth.signInWithPopup(provider)
  //       .then((appleResult) => {
  //         console.log('appleResult', appleResult);
  //       }).catch((error) => {
  //         console.log('error' , error);
  //       });
  //     this.signInWithApple.signin({
  //     requestedScopes: [
  //       ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
  //       ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
  //     ]
  //   }).then(async (appleLoginResponse: AppleSignInResponse) => {
  //     const credential = new firebase.auth.OAuthProvider(
  //       'apple.com'
  //     ).credential({
  //       accessToken: appleLoginResponse.authorizationCode,
  //       idToken: appleLoginResponse.identityToken,
  //       rawNonce: appleLoginResponse.state,
  //     });
  //     let clientSpecificApp: firebase.app.App;
  //     if (AppConfiguration.firebaseProject) {
  //       clientSpecificApp = firebase.app('clientSpecificProject');
  //     } else { clientSpecificApp = firebase.app(); }
  //     try {
  //       if (AppConfiguration.firebaseProject) {
  //         const loading = await this.loadingCtrl.create({
  //           message: this.languagePipe.transform('Gör klart det sista', 'LOGIN_FINALIZING', this.languageProvider.currentLanguage),
  //           spinner: 'circular',
  //           duration: 20000,
  //         });
  //         loading.present();
  //         try {
  //           const dbAuth = await clientSpecificApp
  //             .auth()
  //             .signInWithCredential(credential);
  //           const apiUrl = this.pubqConfigProvider.config.value.ApiUrl;
  //           const body = {
  //             uid: dbAuth.user.uid,
  //           };
  //           const response = await this.http
  //             .post<any>(`${apiUrl}/signInExternal`, body)
  //             .toPromise();
  //           const userCredential = await firebase
  //             .auth()
  //             .signInWithCustomToken(response.data);
  //           userCredential.user.updateEmail(dbAuth.user.email);
  //           const firstName = appleLoginResponse.fullName;
  //           const lastName = appleLoginResponse.fullName;
  //           await this.nav.push(LoginCompleteComponent, {
  //             userId: userCredential.user.uid,
  //             email: dbAuth.user.email,
  //             firstName,
  //             lastName,
  //             nav: this.nav,
  //           });
  //           loading.dismiss();
  //         } catch (error) {
  //           loading.dismiss();
  //           alert(JSON.stringify(error));
  //         }
  //       } else {
  //         try {
  //           const result = await firebase
  //             .auth()
  //             .signInWithCredential(credential);
  //           this.nav.push(LoginCompleteComponent, {
  //             userId: result.user.uid,
  //             nav: this.nav,
  //           });
  //         } catch (error) {
  //           alert(JSON.stringify(error));
  //         }
  //       }
  //     } catch (error) {
  //       alert(JSON.stringify(error));
  //     }
  //   })
  //     .catch((error: AppleSignInErrorResponse) => {
  //       alert(error.code + ' ' + error.localizedDescription);
  //       console.error(error);
  //     });
  // }

  // signInWithPhone() {
  //   this.nav.push(PhoneFormPage, { nav: this.nav });
  // }

  signInWithFacebook() {
    // this.signInWithFacebookCore();
    return this.AuthLogin(new firebase.auth.FacebookAuthProvider());
  }

  async AuthLogin(provider) {
    const loading = await this.loadingCtrl.create({
      spinner: 'circular',
      message: this.languagePipe.transform('Kontaktar Facebook...', 'LOGIN_CONTACTING_FACEBOOK', this.languageProvider.currentLanguage),
      duration: 8000,
    });
    await loading.present();
    const currentUser = firebase.auth().currentUser;
    let anonUserId = null;
    if (currentUser.isAnonymous) {
      anonUserId = currentUser.uid;
      await currentUser.delete();
    }
    try {
      const result = await this.afAuth.auth.signInWithPopup(provider);
      const fbUserId = result.user.uid;

      if (!result.additionalUserInfo.isNewUser) {
        // do not merge with anon user
        await this.nav.push(LoginCompleteComponent, { nav: this.nav, userId: fbUserId });
      } else {
        // merge with anon user
        await this.userProvider.moveUserData(anonUserId, fbUserId);
        const splitName = result.user.displayName.split(' ');
        const firstName = splitName[0];
        const lastName = splitName[1];
        const email = result.user.email;
        await this.nav.push(LoginCompleteComponent, {
          nav: this.nav,
          userId: fbUserId,
          firstName,
          lastName,
          email
        });
      }
    } catch (err) {
      // re-create anon user
      this.showError(JSON.stringify(err));
      loading.dismiss();
      await this.userProvider.createAnonymousUser();
    }
  }

  signInWithEmail() {
    this.nav.push(EmailForm, { nav: this.nav });
  }

  async showError(error) {
    const alert = await this.alertCtrl.create({
      header: this.languagePipe.transform('Fel', 'ERROR', this.languageProvider.currentLanguage),
      subHeader: error,
      buttons: [this.languagePipe.transform('OK', 'OK', this.languageProvider.currentLanguage)],
    });
    alert.present();
  }
}
