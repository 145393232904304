import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AppConfiguration } from 'src/app/app.configuration';
import { PubqConfig, PubqConfigProvider } from '../pubq-config/pubq-config';
import { ContextProvider } from '../context/context';

@Injectable({
  providedIn: "root"
})
export class EventsProvider {
  origin = AppConfiguration.userOrigin;
  config: PubqConfig;
  context = {};
  shouldLog = false

  constructor(
    private http: HttpClient,
    private pubqConfigProvider: PubqConfigProvider,
    private contextProvider: ContextProvider
  ) {
    this.pubqConfigProvider.config.subscribe((x: PubqConfig) => {
      if (!x) {
        return;
      }
      this.config = x;
      this.shouldLog = this.config.sendLogEvents ? this.config.sendLogEvents : false;
    });
    this.setContextValue('origin', this.origin);
  }

  logEvent(event: string, payload?) {
    if (!this.shouldLog) return;
    this.setContextValue('timestamp', +new Date());
    this.setContextValue('userId', firebase.auth().currentUser ? firebase.auth().currentUser.uid : 'N/A');
    this.setContextValue('routeName', this.contextProvider.client ? this.contextProvider.client.routeName : null);

    const body = {
      eventKey: event,
      payload,
      context: this.context
    };
    if (this.config) {
      this.http.post<any>(this.config.ApiUrl + '/api/v1/curator', body)
        .toPromise()
        .then(res => {
        }, err => {
          console.log('event adding error', err);
        });
    } else {
      setTimeout(() => {
        if (this.config) {
          this.http.post<any>(this.config.ApiUrl + '/api/v1/curator', body)
            .toPromise()
            .then(res => {
            }, err => {
              console.log('event adding error', err);
            });
        } else console.log("Could not log event: " + event);
      }, 1000);

    }
  }

  setContextValue(key, val) {
    this.context[key] = val;
  }
}