import {Component} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LanguagePipe } from 'src/app/pipes/language.pipe';
import { ContextProvider } from 'src/app/services/context/context';
import { DeliveryPlaceProvider } from 'src/app/services/delivery-place/delivery-place';
import { LanguageProvider } from 'src/app/services/language/language.provider';
import { OrderProvider } from 'src/app/services/order/order';


@Component({
  templateUrl: 'selectDeliveryPlace.html',
  selector: 'modal-select-delivery-place',
  styleUrls: ['selectDeliveryPlace.scss'],
})

export class SelectDeliveryPlaceModal {

  numberText: string = null;

  constructor(public modalCtrl: ModalController,
              public deliveryPlaceProvider: DeliveryPlaceProvider,
              public orderProvider: OrderProvider,
              public languageProvider: LanguageProvider,
              private languagePipe: LanguagePipe,
              private contextProvider: ContextProvider
  ) { }

  ngOnInit() {
    const context = this.contextProvider.clientContext.appInfo.value.Context
    this.numberText = context && context.Tables && context.Tables.numberText ? context.Tables.numberText : null;
  }
  

  dismiss() {

    this.modalCtrl.dismiss();
  }

  confirm() {
    this.modalCtrl.dismiss(true, "confirm");
  }

}
