import { CssSelector } from '@angular/compiler';
import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { NavController, AlertController, ModalController, LoadingController } from '@ionic/angular';
import * as moment from 'moment';
import { ReplaySubject, Subscription } from 'rxjs';
import { CartItemsOutput } from '../cart-items/cart-items';
import { LanguagePipe } from '../pipes/language.pipe';
import { CartProvider } from '../services/cart/cart.service';
import { ContextProvider } from '../services/context/context';
import { CurrencyProvider } from '../services/currency/currency.provider';
import { DeliveryPlaceProvider } from '../services/delivery-place/delivery-place';
import { LanguageProvider } from '../services/language/language.provider';
import { ActiveOrder, Order, OrderProvider } from '../services/order/order';
import { ReceiptProvider } from '../services/payment/receiptProvider';
import { ThemeProvider } from '../services/theme/theme-provider.service';
import { User, UserProvider, UserPurchaseHistoryV2 } from '../services/user/user';
import { SelectDeliveryPlaceModal } from './select-delivery-place/selectDeliveryPlace';

@Component({
  selector: "side-menu-component",
  templateUrl: "side-menu.component.html",
  styleUrls: ["side-menu.component.scss"],
})
export class SideMenuComponent {
  @Output() onHasChanged = new EventEmitter();
  @Output() onShowResturantInfo = new EventEmitter();
  @Input() show: boolean = true;
  @Input() open: boolean = true;

  modalCloseDelayTime = 500;

  menuClosedOnce = false;

  sideMenuOpen = false;
  updateCartPageData = false;
  backdropActive = false;
  hintEndabled = true;

  selectedView: string = "cart";

  orders: ActiveOrder[] = [];
  expandOrders: boolean = false;
  ordersSub: Subscription

  appInfo;
  appInfoSub: Subscription;
  userSubscription: Subscription;
  receiptSubscription: Subscription;
  user: User;
  purchaseHistory: UserPurchaseHistoryV2[];
  purchase: UserPurchaseHistoryV2;

  client = '';
  city = '';
  routeName = '';

  constructor(
    public languageProvider: LanguageProvider,
    public languagePipe: LanguagePipe,
    public cartProvider: CartProvider,
    public themeProvider: ThemeProvider,
    private navCtrl: NavController,
    private activatedRoute: ActivatedRoute,
    private userProvider: UserProvider,
    private contextProvider: ContextProvider,
    public deliveryPlaceProvider: DeliveryPlaceProvider,
    public orderProvider: OrderProvider,
    public currencyProvider: CurrencyProvider,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private loaderCtrl: LoadingController,
    private receiptProvider: ReceiptProvider
  ) {
    this.client = this.activatedRoute.snapshot.params.client;
    this.city = this.activatedRoute.snapshot.params.city;
    this.routeName = this.activatedRoute.snapshot.params.routeName;

    this.ordersSub = this.userProvider.activeOrders.subscribe((x: ActiveOrder[]) => {
      if (!x || x.length === 0) {
        this.orders = [];
        this.toggleMenu(false);
        return;
      }
      this.expandOrders = x.length < 3;
      this.orders = x;

      this.updateCartPageData = !this.updateCartPageData;
    });

    this.appInfoSub = this.contextProvider.clientContext.appInfo.subscribe((x) => {
      if (x && x.style && (x.style.facebookLink || x.style.instagramLink))
        this.appInfo = x;
    });

    this.userSubscription = this.userProvider.currentUser.subscribe(
      (x: User) => {
        if (!x) {
          return;
        }
        this.user = x;
        if (this.receiptSubscription) this.receiptSubscription.unsubscribe();
        this.receiptSubscription = this.userProvider.getPurchaseHistory(x).subscribe((x: UserPurchaseHistoryV2[]) => {
          this.purchaseHistory = x.sort((a, b) => {
            return moment(a.receipt.timestamp) < moment(b.receipt.timestamp) ? 1 : -1;
          });
        });
      }
    );

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    if (this.ordersSub) this.ordersSub.unsubscribe();
    if (this.appInfoSub) this.appInfoSub.unsubscribe();
    if (this.userSubscription) this.userSubscription.unsubscribe();
    if (this.receiptSubscription) this.receiptSubscription.unsubscribe();
  }

  ionViewDidEnter() {
    this.updateCartPageData = !this.updateCartPageData;
  }

  toggleMenu(state?: boolean) {
    this.sideMenuOpen = typeof state !== "undefined" ? state : !this.sideMenuOpen;

    if (!this.sideMenuOpen) {
      this.selectedView = 'cart';
      this.updateCartPageData = !this.updateCartPageData;
    } else {
      this.menuClosedOnce = true;
    }

    this.onHasChanged.emit({ open: this.sideMenuOpen, backdropActive: this.backdropActive });
  }

  onPaymentInitiation(event) {
    this.orderProvider.orderWaitingForApproval = this.createOrder(event);
  }

  createOrder(event: CartItemsOutput): Order {
    moment.locale('sv');
    const date = moment().unix();
    const order = new Order();
    order.version = '2';
    order.appType = 'web';
    order.client = this.contextProvider.getClient();
    order.date = date;
    order.deliveryDate = event.selectedDate;
    order.coupon = event.coupon;
    order.customerComment = event.customerComment;
    order.momsRegNr = this.contextProvider.clientContext.appInfo.getValue().MomsRegNr;
    order.totalCost = event.totalCost;
    order.totalFoodItems = event.totalFoodItems;
    order.totalFoodCost = event.totalFoodCost;
    order.totalDrinksCost = event.totalDrinksCost;
    order.totalDrinkItems = event.totalDrinkItems;
    if (this.deliveryPlaceProvider.tableNumber > 0) order.tableNumber = this.deliveryPlaceProvider.tableNumber
    if (event.totalItems) order.totalItems = event.totalItems;
    if (event.deliveryComment) order.deliveryComment = event.deliveryComment;
    if (event.deliveryRadius) order.deliveryRadius = event.deliveryRadius;
    if (event.deliveryLat) order.deliveryLat = event.deliveryLat;
    if (event.deliveryLong) order.deliveryLong = event.deliveryLong;
    if (event.deliveryFee) order.deliveryFee = event.deliveryFee;
    if (event.deliveryAddress) order.deliveryAddress = event.deliveryAddress;
    order.order = {
      food: this.cartProvider.getFoodItems(this.cartProvider.cart.items),
      drinks: this.cartProvider.getDrinkItems(this.cartProvider.cart.items),
    };
    return order;
  }

  onLanguageChanged(ev) {
    this.languageProvider.setLanguage(ev.detail.value);
  }

  gotoCart() {
    this.selectedView = 'cart';
    if (!this.sideMenuOpen) this.toggleMenu();

  }

  async gotoPayment() {

    try {
      this.routeName = this.contextProvider.client.routeName;
    } catch (err) {

    }

    if (!this.isDeliverySelected()) {
      let oneOption = await this.deliveryPlaceProvider.setOneDeliveryOptionIfOnlyOneActivated();
      if (!oneOption) {
        let modal = await this.modalCtrl.create({
          component: SelectDeliveryPlaceModal,
          componentProps: {
            appInfo: this.contextProvider.clientContext.appInfo.value,
            clientInfo: this.contextProvider.clientContext.currentClient.value,
          },
          cssClass: "modal-drawer",
          swipeToClose: true,
        });
        modal.present();

        let data = await modal.onDidDismiss();

        if (data.role === 'confirm') {
          this.navCtrl.navigateForward(`u/${this.routeName}/cart`);
        }
      }
      else
        this.navCtrl.navigateForward(`u/${this.routeName}/cart`);
    } else {
      this.navCtrl.navigateForward(`u/${this.routeName}/cart`);
    }



  }

  async sendEmailReceiptRequest() {
    let emailAdress = '';
    if (!this.user.email) {
      emailAdress = prompt(this.languagePipe.transform('Fyll i din mailadress', 'PURCHASE_FILL_MAIL', this.languageProvider.currentLanguage));
      if (!emailAdress) { return; }
      if (!confirm(this.languagePipe.transform(`Är ${emailAdress} korrekt?`, 'EMAIL_CORRECT', this.languageProvider.currentLanguage, [emailAdress]))) {
        this.sendEmailReceiptRequest();
      } else {
        this.user.email = emailAdress;
        this.userProvider.updateUser(this.user);
      }

    } else {
      emailAdress = this.user.email;
    }
    const loader = await this.loaderCtrl.create({
      message: `${this.languagePipe.transform('Skickar kvitto till', 'PURCHASE_HISTORY_SENDING_RECIEPT', this.languageProvider.currentLanguage)}, ${this.user.email}`,
      duration: 10000
    });
    loader.present();
    this.receiptProvider
      .sendEmailReceiptRequest(
        this.purchase,
        this.user,
        emailAdress
      )
      .subscribe(async x => {
        const alert = await this.alertCtrl.create({
          header: `${this.languagePipe.transform('Kvittot mailades till', 'PURCHASE_HISTORY_SENDING_SENT', this.languageProvider.currentLanguage)} ${this.user.email}`,
          buttons: ['OK']
        });
        alert.present();
        loader.dismiss();
      });
  }

  gotoAccount() {
    this.selectedView = 'account';
    if (!this.sideMenuOpen) this.toggleMenu();
  }

  goToActiveOrders() {
    this.selectedView = 'order';
    if (!this.sideMenuOpen) this.toggleMenu();
    // this.navCtrl.navigateForward(`active-orders/${this.client}/${this.city}/${this.routeName}`);
  }

  goToReceipts() {
    this.selectedView = 'receipts';
    if (!this.sideMenuOpen) this.toggleMenu();
    // this.navCtrl.navigateForward([`history/${this.client}/${this.city}/${this.routeName}`]);
  }

  // goToAccountTemp(){
  //   this.navCtrl.navigateForward(`account/${this.client}/${this.city}/${this.routeName}`)
  //   if(!this.sideMenuOpen) this.toggleMenu();
  // }



  showRestaurantInfo() {
    this.selectedView = 'info';
    if (!this.sideMenuOpen) this.toggleMenu();
  }

  showCart() {
    if (!this.menuClosedOnce) {
      this.showTab('cart')
    }
  }

  totalNotifications() {
    let cartItems = this.cartProvider.cart && this.cartProvider.cart.totalItems > 0 ? this.cartProvider.cart.totalItems : 0;
    let activeOrders = this.orders && this.orders.length > 0 ? this.orders.length : 0;
    return activeOrders + cartItems;
  }

  showTab(tabId: string) {
    if (tabId === 'info')
      this.showRestaurantInfo();
    if (tabId === 'order')
      this.goToActiveOrders();
    if (tabId === 'receipts')
      this.goToReceipts();
    if (tabId === 'cart')
      this.gotoCart();
    if (tabId === 'account')
      this.gotoAccount();
  }

  openSocialMediaLink(link) {
    window.open(link, '_blank');
  }

  getNiceDateWithTime(date) {
    return moment(date).format('YYYY-MM-DD kk:mm');
  }

  async showPurchaseHistoryDetails(item) {
    this.purchase = item;
  }

  closePurchase() {
    this.purchase = null;
  }

  hideHint() {
    this.hintEndabled = false;
  }

  isOpen() {
    return this.open;
  }

  isDeliverySelected() {
    return this.deliveryPlaceProvider.choseVariant;
  }


  hasOrder() {
    return this.orderProvider.orderWaitingForApproval;
  }


  isCartEmpty() {
    if (!this.hasOrder()) return true;

    if (!this.cartProvider.cart.items || this.cartProvider.cart.items.length === 0) return true;

    // if(
    //   (!this.orderProvider.orderWaitingForApproval.order.drinks || this.orderProvider.orderWaitingForApproval.order.drinks.length === 0) &&
    //   (!this.orderProvider.orderWaitingForApproval.order.food || this.orderProvider.orderWaitingForApproval.order.food.length === 0))
    //   return true;

    return false
  }

  isCartNotEmpty() {
    return !this.isCartEmpty();
  }

  async clearCart() {
    const alert = await this.alertCtrl.create({
      header: this.languagePipe.transform("Avbryt", 'CANCEL', this.languageProvider.currentLanguage),
      message: this.languagePipe.transform('Vill du avbryta din beställning och rensa kundkorgen?', 'CANCEL_ORDER', this.languageProvider.currentLanguage),
      buttons: [
        {
          text: this.languagePipe.transform("Nej", 'NO', this.languageProvider.currentLanguage),
          role: 'cancel'
        },
        {
          text: this.languagePipe.transform("Ja", 'YES', this.languageProvider.currentLanguage),
          handler: () => {
            this.cartProvider.empty();
            this.updateCartPageData = !this.updateCartPageData;
          }
        }
      ]
    });

    await alert.present();

  }

  // Delivery options
  ngAfterViewInit() {
    // if(this.orderProvider.orderWaitingForApproval){
    //   this.deliveryPlaceProvider.tableNumberObservable = new ReplaySubject<number>(1);

    //   try{

    //     this.deliveryPlaceProvider.tableNumber = parseInt(this.orderProvider.preSelectedTableNumber);

    //     if (Number(this.orderProvider.preSelectedTableNumber) >= Number(this.deliveryPlaceProvider.tableOption.minValue) &&
    //       Number(this.orderProvider.preSelectedTableNumber) <= Number(this.deliveryPlaceProvider.tableOption.maxValue)) {
    //         this.deliveryPlaceProvider.tableNumber = Number(this.orderProvider.preSelectedTableNumber);
    //         this.orderProvider.orderWaitingForApproval.tableNumber = this.deliveryPlaceProvider.tableNumber;
    //         if(this.deliveryPlaceProvider.tablesEnabled)
    //           this.deliveryPlaceProvider.checkWhatSelect(this.deliveryPlaceProvider.tablesText, this.deliveryPlaceProvider.tableServeImage, true);
    //       }
    //   }catch(err){
    //     this.deliveryPlaceProvider.tableNumber = -2;
    //   }

    //   if(this.deliveryPlaceProvider.takeawayEnabled)
    //     this.deliveryPlaceProvider.checkWhatSelect(this.deliveryPlaceProvider.takeawayText, this.deliveryPlaceProvider.takeAwayImage)
    //   if(this.deliveryPlaceProvider.takeawayEnabled)
    //     this.deliveryPlaceProvider.checkWhatSelect(this.deliveryPlaceProvider.sitAtRestaurantText, this.deliveryPlaceProvider.sitAtRestaurantIcon)
    // }
  }

  handlingRececiptClick(event) {
    this.showTab('receipts');
  }

  async promptForTableNumber() {
    setTimeout(async () => {
      this.deliveryPlaceProvider.tableNumber = -1;
      this.deliveryPlaceProvider.sitAtRestaurantClicked = false;
      this.deliveryPlaceProvider.incorrectTableNumber = true;

      let alert: any = await this.promptForTableNumber();
      let result = await alert.onDidDismiss();
      if (result.role === "cancel") return;
      this.deliveryPlaceProvider.incorrectTableNumber = false;
      this.orderProvider.orderWaitingForApproval.tableNumber = parseInt(result.data.values.tableNumber);
    }, this.modalCloseDelayTime);
  }
}
