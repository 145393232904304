import { Component, ViewChild, EventEmitter, Input } from "@angular/core";

import * as firebase from "firebase/app";
import "firebase/database";
import {
  NavParams,
  AlertController,
  LoadingController,
  NavController,
  ModalController
} from "@ionic/angular";
import { ContextProvider } from "src/app/services/context/context";
import { ActivatedRoute } from "@angular/router";
import { OrderProvider } from "src/app/services/order/order";
import { UserPaymentSource } from "src/app/services/user/user";
import { LanguageProvider } from 'src/app/services/language/language.provider';
import { LanguagePipe } from 'src/app/pipes/language.pipe';

@Component({
  selector: "page-customer-tab-key",
  templateUrl: "customer-tab-key.html",
  styleUrls: ["customer-tab-key.scss"]
})
export class CustomerTabKeyPage {
  customerTabKey;
  lastUsedCustomerTabKey;
  @ViewChild("inputField") inputField;
  order;
  constructor(
    private navCtrl: NavController,
    private orderProvider: OrderProvider,
    private contextProvider: ContextProvider,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private activatedRoute: ActivatedRoute,
    public languageProvider: LanguageProvider,
    private languagePipe:LanguagePipe
  ) {
    this.order = this.orderProvider.orderWaitingForApproval;
    this.lastUsedCustomerTabKey = this.orderProvider.lastUsedCustomerTabKey;
  }
  forward() {
    this.navCtrl.navigateForward(["../chargeCustomer"], {
      relativeTo: this.activatedRoute
    });
  }
  async inputChanged(ev) {
    if (ev.length > 3) {
      if (await this.verifyTabKey(ev)) {
        let customerTabKeyOption: UserPaymentSource = {
          nickname: "customerTabKey",
          recurringReference: ev,
          type: "customerTabKey"
        };
        this.orderProvider.paymentOfChoice = customerTabKeyOption;
        this.orderProvider.lastUsedCustomerTabKey = ev;
        this.forward();
      }
      this.customerTabKey = "";
    }
  }
  dismiss() {
    this.navCtrl.back();
  }
  ionViewDidEnter(){
    setTimeout(() => {
      this.inputField.setFocus();
    }, 200);
  }
  autoFillKey() {
    this.inputChanged(this.lastUsedCustomerTabKey);
  }
  async verifyTabKey(key) {
    let loading = await this.loadingCtrl.create({
      spinner: "circular"
    });
    loading.present();
    let snapshot = await firebase
      .database()
      .ref(
        `Clients/${this.contextProvider.client.routeName}/customerTabs/${key}`
      )
      .once("value");
    loading.dismiss();
    if (!snapshot.val()) {
      let wrongCodeAlert = await this.alertCtrl.create({
        message: this.languagePipe.transform("Fel bordskod",'CUSTOMER_TAB_KEY_WRONG_CODE',this.languageProvider.currentLanguage),
        buttons: [this.languagePipe.transform("Ok",'CONFIRM',this.languageProvider.currentLanguage)]
      });
      wrongCodeAlert.present();
      let result = await wrongCodeAlert.onDidDismiss();
      setTimeout(() => {
        this.inputField.setFocus();
      }, 200);
      return false;
    }
    return true;
  }
}
