import { Component, Input } from "@angular/core";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { LanguagePipe } from "src/app/pipes/language.pipe";
import { CartProvider } from "src/app/services/cart/cart.service";
import { LanguageProvider } from "src/app/services/language/language.provider";
import { Order } from "src/app/services/order/order";
import { PaymentV2Response } from "src/app/services/payment/payment";
import { PaymentUtils } from "src/app/services/payment/paymentUtils";
import { SwishPaymentProvider } from "src/app/services/payment/swishPaymentProvider";
import { User } from "src/app/services/user/user";
import { StockModalComponent } from "src/app/stock-balance/stock.component";

@Component({
    selector: "add-card",
    styleUrls: ["swish-dialog.component.scss"],
    templateUrl: "swish-dialog.component.html",
})
export class SwishDialogComponent {
    @Input() order: Order
    @Input() paymentOptions: { user: any, paymentSource: any, order: Order }
    @Input() phone: string
    @Input() user: User
    @Input() routeName: string = ''
    idempotencyKey: string = ''

    qrCode: string = null;
    payOnThisDevice: boolean = false;
    payOnOtherDevice: boolean = false;
    openedPaymentProvider = false;
    showOpenApp: boolean = false;
    paymentRequestToken
    verifying = false;
    prepering = false;

    lastResponse: PaymentV2Response;

    constructor(
        private modalController: ModalController,
        private platform: Platform,
        private swishPaymentProvider: SwishPaymentProvider,
        private paymentUtils: PaymentUtils,
        private alertCtrl: AlertController,
        public languageProvider: LanguageProvider,
        private languagePipe: LanguagePipe,
        private cartProvider: CartProvider,
        private alertController: AlertController
    )
    {
        this.idempotencyKey = this.cartProvider.idempotencyKey;
    }

    ngOnInit(){
        this.idempotencyKey = this.cartProvider.idempotencyKey;

        if(!this.order || !this.user || !this.routeName || !this.idempotencyKey){
            alert(this.languagePipe.transform("Saknar data för att kunna göra en Swish betalning, kontakta supporten.",
             'CONTACT_SUPPORT_SWISH', this.languageProvider.currentLanguage))
            this.cancel();
        }
    }

    isPhone(){
        return this.platform.is("mobile") || this.platform.is("mobileweb") || this.platform.is("ios") || this.platform.is("android") || this.platform.is("cordova") || this.platform.is("capacitor")
    }

    getIconFromResponse(response: PaymentV2Response){
        if(!response) return ""

        if(response.success){
            return "checkmark-circle-outline"
        }else if (this.hasError(response)) {
            return "warning-outline"
        }else{ // Not verified yet
            return "hourglass-outline"
        }
    }

    getHeaderFromResponse(response: PaymentV2Response){
        if(!response) return ""

        if(response.success) {
          return this.languagePipe.transform("Klart!", 'PAYMENT_DONE', this.languageProvider.currentLanguage)
        }
        else if (this.hasError(response)) {
          return this.languagePipe.transform("Betalningsfel", 'PAYMENT_ERROR_HEADER', this.languageProvider.currentLanguage)
        }
        else { // Not verified yet
           return this.languagePipe.transform("Väntar på bekräftelse", 'PAYMENT_WAIT_CONFIRM', this.languageProvider.currentLanguage)
        }
    }

    getColorFromResponse(response: PaymentV2Response){
        if(!response) return "primary"

        if(response.success){
            return "success"
        }else if (response.error && response.error.responseText) {
            return "danger"
        }else{ // Not verified yet
            return "primary"
        }
    }

    getTextFromResponse(response: PaymentV2Response){
        if(!response) return ""

        if(response.success){
          return this.languagePipe.transform("Din betalning är registrerad", 'PAYMENT_CONFIRMED', this.languageProvider.currentLanguage)
        }else if (response.error && response.error.responseText)
         {
          return this.languagePipe.transform(response.error.responseText, response.error.languageCode, this.languageProvider.currentLanguage)
        }else{ // Not verified yet
          return this.languagePipe.transform("Betalningen för din order har inte registrerats ännu.", 'PAYMENT_NOT_CONFIRMED', this.languageProvider.currentLanguage)
         }
    }

    async payOnThisDeviceSelected(){
        this.prepering = true;
        this.payOnThisDevice = true;
        this.payOnOtherDevice = false;
        this.openedPaymentProvider = false;
        this.lastResponse = null;

        let clientContextData = {
            user: {
                id: this.order.userId,
                isAnonymous: true,
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                deviceToken: null,
                email: this.user.email,
                preferredLocale: "se",
                origin: this.routeName,
            },
            origin: window.location.origin,
            locale: "se",
            appVersion: "web_booking@2.0.0",
            appProtocol: ``
        }

        let _options: any = {
            paymentSource: {
                nickname: "swish",
                recurringReference: null,
                type: "swish",
              },
            order: this.order,
            clientContextData: clientContextData,
            user: this.user
        }

        let response = await this.swishPaymentProvider.initiate(_options, this.idempotencyKey, this.order.id);
        this.prepering = false;
        // Charge payment if init was a success
        if (response && response.success) {
            this.paymentRequestToken = response.providerData.providerData.paymentRequestToken
            this.showOpenApp = true
            if(this.isPhone()){
                this.lastResponse = await this.swishPaymentProvider.verify(this.idempotencyKey, this.routeName, 240);
            }
        } else {
            this.handleResponse(response);
        }

    }

    async payOnOtherDeviceSelected(){
        this.prepering = true;
        this.payOnThisDevice = false;
        this.payOnOtherDevice = true;
        this.openedPaymentProvider = false;
        this.lastResponse = null;

        let clientContextData = {
            user: {
                id: this.order.userId,
                isAnonymous: true,
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                deviceToken: null,
                email: this.user.email,
                preferredLocale: "se",
                origin: this.routeName,
            },
            origin: window.location.origin,
            locale: "se",
            appVersion: "web_booking@2.0.0",
            appProtocol: ``
        }

        let _options: any = {
            paymentSource: {
                nickname: "swish",
                recurringReference: null,
                type: "swish",
              },
            order: this.order,
            clientContextData: clientContextData,
            user: this.user
        }

        let response = await this.swishPaymentProvider.initiate(_options, this.idempotencyKey, this.order.id);


        // Charge payment if init was a success
        if (response && response.success) {
            this.qrCode = await this.swishPaymentProvider.getQrCode(response.providerData.providerData.paymentRequestToken);
            this.prepering = false;
            this.openedPaymentProvider = true;
            this.lastResponse = await this.swishPaymentProvider.verify(this.idempotencyKey, this.routeName, 240);
        } else {
            this.handleResponse(response);
        }
    }

    async verify(){
        this.paymentUtils.cancelRetrying();
        this.verifying = true;
        let response = await this.swishPaymentProvider.verify(this.idempotencyKey, this.routeName, 0);
        this.lastResponse = response;
        console.dir(response)
        this.verifying = false;
        if(response.success){
            await this.showConfirm
            (
              this.languagePipe.transform("Din order är betald och beställd", 'PAYMENT_AND_ORDER_IS_PLACED', this.languageProvider.currentLanguage),
              this.languagePipe.transform("OBS! Stäng inte webbläsaren!", 'DONT_CLOSE_WEB_BROWSER', this.languageProvider.currentLanguage),
              this.languagePipe.transform("<p>När ordern är klar kommer en dialog visas i webbläsaren.</p> <p>Ert kvitto kommer finnas under <b>Mina kvitton</b> och är möjligt att maila till önskad e-postadress.</p> <p>Din order visas under aktiva order samt att kommer få ett SMS när din order är klar.</p>", 'ORDER_INFORMATION', this.languageProvider.currentLanguage),
            )
            this.modalController.dismiss(response, "success")
        } else if(response.error){
            this.handleResponse(response);
        }
    }

    async openSwish(){
        this.openedPaymentProvider = true;
        const ua = navigator.userAgent.toLowerCase();
        const isAndroid = ua.indexOf("android") > -1; // android check
        const isIphone = ua.indexOf("iphone") > -1; // ios check


        let appUrl = `swish://paymentrequest?token=${this.paymentRequestToken}`;

        if (isAndroid || isIphone) {
            setTimeout(function () {
                this.openedPaymentProvider = true;
                window.location.href = appUrl;
            }, 25);
        }

        this.lastResponse = await this.swishPaymentProvider.verify(this.idempotencyKey, this.routeName, 1);
    }

    async showConfirm(text, subHeader, message) : Promise<boolean> {
        return new Promise<boolean>(async (resolve)=>{

          let prompt = await this.alertController.create({
            header: text,
            subHeader: subHeader,
            message: message,
            backdropDismiss: false,
            buttons: [
              {
                text: this.languagePipe.transform("OK", 'OK', this.languageProvider.currentLanguage),
                role: "confirm",
                handler: ((event)=>{
                  resolve(false);
                })
              }
            ],
          });

          prompt.present();
        });
      }

    retry(otherDevice: boolean){
        this.clearSelection();
        this.cartProvider.updateIdempotencyKey();
        this.idempotencyKey = this.cartProvider.idempotencyKey;
        this.paymentUtils.cancelRetrying();
        if(otherDevice)
            this.payOnOtherDeviceSelected();
        else
            this.payOnThisDeviceSelected();
    }

    hasError(lastResponse: PaymentV2Response){
        if(!lastResponse) return false;
        try{
            if (lastResponse && lastResponse.missing) {
                this.showLowStockError(lastResponse);
                return true;
            }

            if(lastResponse && lastResponse.error){
                // Chaeck that the error is not that verify has timed out or lost connection
                if(lastResponse.error.responseText && lastResponse.error.languageCode !== 'SWISH_CANCELLED' )
                    return true;
            }
        }catch(err){
            alert(err);
            return false;
        }

        return false;
    }

    onlyOne = false;
    async showLowStockError(lastResponse: PaymentV2Response){
        if(!this.onlyOne){
            this.onlyOne = true;
            let modal = await this.modalController.create({
            component: StockModalComponent,
            componentProps: { routeName: this.routeName, data: lastResponse.missing, updatedCart: lastResponse.updatedCart },
            cssClass: 'stock-modal'
            });

            modal.present();
            await modal.onDidDismiss();
            this.cancel();
            this.onlyOne = false;
        }
    }


    handleResponse(response: PaymentV2Response){
        if(response.success){
            this.modalController.dismiss(response, "success")
        }else if (response.error && response.error.responseText) {
            this.clearSelection();
            this.lastResponse = response;
            //alert(response.error.responseText)
        }else{
            this.clearSelection();
        }
    }

    clearSelection(){
        //this.payOnOtherDevice = false;
        //this.payOnThisDevice = false;
        //this.showOpenApp = false;
        this.openedPaymentProvider = false;
        this.paymentRequestToken = null;
        this.verifying = false
        this.prepering = false;
    }

    async verifyAndCancel(){
        const alert = await this.alertCtrl.create({
            header: this.languagePipe.transform("Avbryt", 'CANCEL', this.languageProvider.currentLanguage),
            message: this.languagePipe.transform('Avbryt inte om du genomfört betalningen. Vill du avbryta?', 'CANCEL_SWISH_VERIFY', this.languageProvider.currentLanguage),
            buttons: [
                {
                text: this.languagePipe.transform("Nej", 'NO', this.languageProvider.currentLanguage),
                role: 'cancel'
                },
                {
                text: this.languagePipe.transform("Ja", 'YES', this.languageProvider.currentLanguage),
                handler: () => {
                    this.cancel();
                }
                }
            ]
        });

        await alert.present();
    }

    cancel(){
        this.clearSelection();
        this.paymentUtils.cancelRetrying();
        this.modalController.dismiss(null, "cancel")
    }
}
