import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, IonRouterOutlet, NavController } from '@ionic/angular';
import { LanguagePipe } from 'src/app/pipes/language.pipe';
import { ContextProvider } from 'src/app/services/context/context';
import { LanguageProvider } from 'src/app/services/language/language.provider';

@Component({
    selector: 'page-confirm-payment',
    templateUrl: 'paymentConfirmation.component.html',
    styleUrls: ['paymentConfirmation.component.scss']
})
export class PaymentConfirmationComponent {
    showCard = false;
    showIcon = false;
    routeName?: string = null;
    constructor(
        private routerOutlet: IonRouterOutlet,
        private navCtrl: NavController,
        private activatedRoute: ActivatedRoute,
        public languageProvider: LanguageProvider,
        private contextProvider: ContextProvider,
        private alertController: AlertController,
        private languagePipe: LanguagePipe,
        ) 
    {
        this.routeName = this.activatedRoute.snapshot.params.routeName;
    }
    ngOnInit() {
        /* If this is the loading page route to main */
        if(!this.contextProvider || !this.contextProvider.initDone)
            this.navCtrl.navigateBack(`u/${this.routeName}`);

        setTimeout(() => {
            this.showCard = true;
        }, 1000);
        setTimeout(() => {
            this.showIcon = true;
        }, 1500);
        setTimeout(() => {
            this.home();
        }, 4000);
    }

    async home() {

        await this.showConfirm
        (
          this.languagePipe.transform("Din order är betald och beställd", 'PAYMENT_AND_ORDER_IS_PLACED', this.languageProvider.currentLanguage),
          this.languagePipe.transform("OBS! Stäng inte webbläsaren!", 'DONT_CLOSE_WEB_BROWSER', this.languageProvider.currentLanguage),
          this.languagePipe.transform("<p>När ordern är klar kommer en dialog visas i webbläsaren.</p> <p>Ert kvitto kommer finnas under <b>Mina kvitton</b> och är möjligt att maila till önskad e-postadress.</p> <p>Din order visas under aktiva order samt att kommer få ett SMS när din order är klar.</p>", 'ORDER_INFORMATION', this.languageProvider.currentLanguage),
        )

        this.routerOutlet.swipeGesture = false;
        this.navCtrl.navigateBack(`u/${this.routeName}`, {
            // relativeTo: this.activatedRoute,
            queryParams: {
                showActiveOrder: true
            }
        });
    }

    async showConfirm(text, subHeader, message) : Promise<boolean> {
        return new Promise<boolean>(async (resolve)=>{
    
          let prompt = await this.alertController.create({
            header: text,
            subHeader: subHeader,
            message: message,
            backdropDismiss: false,
            buttons: [
              {
                text: this.languagePipe.transform("OK", 'OK', this.languageProvider.currentLanguage),
                role: "confirm",
                handler: ((event)=>{
                  resolve(false);
                })
              }
            ],
          });
    
          prompt.present();
        });
      }
}
