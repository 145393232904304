import { Component } from "@angular/core";
import { LanguageProvider } from "../services/language/language.provider";

@Component({
    selector: "not-activated",
    templateUrl: "notActivated.component.html",
})
export class NotActivated {

    constructor(public languageProvider: LanguageProvider,) {

    }
}
