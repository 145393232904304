import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { PubqConfig, PubqConfigProvider } from "../pubq-config/pubq-config";
import { User, UserPurchaseHistoryV2 } from "../user/user";
import * as moment from 'moment';

@Injectable({
    providedIn: "root"
})
export class ReceiptProvider {
    config: PubqConfig;

    constructor(public http: HttpClient, private pubqConfigProvider: PubqConfigProvider) {
        this.pubqConfigProvider.config.subscribe((x: PubqConfig) => {
            if (!x) return;
            this.config = x;
        });
    }
    sendEmailReceiptRequest(purchase: UserPurchaseHistoryV2, user: User, emailAdress: string) {
        var data = {
            timestamp: moment().unix(),
            purchase: purchase,
            user: user,
            emailAdress: emailAdress
        };

        return this.http.post(`${this.config.ApiUrl}/sendEmailReceipt`, data);
    }
}