import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from "moment";
import { ContextProvider } from 'src/app/services/context/context';
import { LanguageProvider } from 'src/app/services/language/language.provider';

@Component({
    selector: "time-picker",
    templateUrl: "timePicker.component.html"
})
export class TimePickerComponent {
    @Input() preSelectedMoment;
    selectedDate;
    @Input() selectedHour;
    @Input() selectedMinute;
    minutes = ["00", "15", "30", "45"];
    dates = [];
    showOkButton=false;
    constructor(private modalController: ModalController, private contextProvider: ContextProvider, public languageProvider: LanguageProvider) {

    }
    ngOnInit() {
        var selectedLanguageLocale = "sv";
        let openingHours = this.contextProvider.clientContext.appInfo.getValue().Context.openingHours;
        let weekday = moment().locale("en").format("dddd").toLowerCase();
        let today = openingHours.weekdays.find(x => x.type === weekday);
        const start = moment().locale(selectedLanguageLocale);
        const remainder = 15 - (start.minute() % 15);
        const dateTime = moment(start).add(remainder, "minutes").add(15, "minutes");

        //Todays available dates
        let todayObj = {
            date: start.startOf("day"),
            time: []
        };
        let endHour = parseInt(today.end.split(":")[0]);
        let times = endHour - dateTime.hour();
        let startHour = dateTime.hour();
        for (let index = 0; index < times; index++) {
            todayObj.time.push({
                hour: start.clone().add(startHour, "hours").format("kk"),
                minutes: this.minutes
            });
            startHour++;
        }
        if (todayObj.time.length > 0)
            todayObj.time[todayObj.time.length - 1].minutes = [];
        this.dates.push(todayObj);
        //Other dates
        for (let index = 0; index < 5; index++) {
            let aDate = moment().locale(selectedLanguageLocale).add(index + 1, "days").startOf("day");
            let weekday = aDate.clone().locale("en").format("dddd").toLowerCase();
            let opening = openingHours.weekdays.find(x => x.type === weekday);
            let myObj = {
                date: aDate,
                time: []
            };
            let splitMin = opening.start.split(":");
            let minHour = parseInt(splitMin[0]);
            let minMinute = parseInt(splitMin[1]);
            let splitMax = opening.end.split(":");
            let maxHour = parseInt(splitMax[0]);
            let maxMinute = parseInt(splitMax[1]);
            let times = maxHour - minHour;
            myObj.time.push({
                hour: aDate.clone().add(minHour, "hours").format("kk"),
                minutes: this.minutes
            });
            for (let index = minHour; index < maxHour; index++) {
                myObj.time.push({
                    hour: aDate.clone().add(index + 1, "hours").format("kk"),
                    minutes: this.minutes
                }
                );
            }
            this.dates.push(myObj);
        }
        if (this.preSelectedMoment)
            this.preSelect()
    }
    preSelect() {
        this.selectedDate = this.dates.find(x => x.date.date() === this.preSelectedMoment.date());
        this.selectedHour = this.selectedDate.time.find(x => x.hour === this.preSelectedMoment.format("kk"));
        this.selectedMinute = this.preSelectedMoment.minute().toString();
    }
    reset() {
        this.modalController.dismiss(null, "reset");
    }
    ok() {
        let final = this.selectedDate.date.clone().add(this.selectedHour.hour, "hours").add(this.selectedMinute, "minutes");
        this.modalController.dismiss({ moment: final }, "confirm");
    }
}