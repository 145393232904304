import { Directive, AfterViewInit, ElementRef, Renderer2, Input, HostListener } from '@angular/core';
import { DomController } from '@ionic/angular';

@Directive({
	selector: '[appParallaxHeader]',
})
export class ParallaxHeaderDirective implements AfterViewInit {

	@Input() parallaxImagePath: string;
	@Input() parallaxHeight: number;

	private headerHeight: number;
	private header: HTMLDivElement;
  	private mainContent: HTMLDivElement;

	constructor(private element: ElementRef, private renderer: Renderer2, private domCtrl: DomController) {

	}

	

    @HostListener('ionScroll', ['$event'])
    onContentScroll(ev){

        let translateAmt: number;
        let scaleAmt: number;

        const scrollTop = ev.detail.scrollTop;

        // Allready scrolled past the point at which the header image is visible
        if(scrollTop > this.parallaxHeight){
            return;
        }

        if(scrollTop >= 0){
            translateAmt = -(scrollTop / 2);
            scaleAmt = 1;
        }else{
            translateAmt = 0;
            scaleAmt = -scrollTop / this.headerHeight + 1
        }

        this.domCtrl.write(() => {
            this.renderer.setStyle(
                this.header,
                'transform',
                `transformY(${translateAmt}px) scale(${scaleAmt})`

            );

            this.renderer.setStyle(
                this.mainContent,
                'transform',
                `transformY(${-scrollTop}px)`

            );
        });
	}

    ngAfterViewInit(){

		this.headerHeight = this.parallaxHeight;
    	this.mainContent = this.element.nativeElement.querySelector('.main-content');

		this.domCtrl.write(() => {

			this.header = this.renderer.createElement('div');

			this.renderer.insertBefore(
                this.element.nativeElement, 
                this.header, 
                this.element.nativeElement.firstChild
            );
			

            this.renderer.setStyle(
                this.header, 
                'background-image'
                , `url(${this.parallaxImagePath})`
            );

            this.renderer.setStyle(this.header, 'height', this.headerHeight + 'px');
			this.renderer.setStyle(this.header, 'background-position', 'center');
            this.renderer.setStyle(this.header, 'background-size', 'cover');
            this.renderer.setStyle(this.header, 'background-repeat', 'no-repeat');

            this.renderer.setStyle(this.header, 'will-change', 'transform')
            this.renderer.setStyle(this.mainContent, 'will-change', 'transform')
		});

  	}

}