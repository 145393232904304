import { Component, Input } from '@angular/core';
import { IonNav, ModalController, LoadingController } from '@ionic/angular';
import { UserProvider, User } from 'src/app/services/user/user';

import { AppConfiguration } from 'src/app/app.configuration';
import * as moment from 'moment';
import { AdditionalAccountInfoForm } from './additionalAccountInfoForm.component';

@Component({
    styleUrls: [],
    selector: 'login-complete',
    templateUrl: 'login-complete.component.html'
})
export class LoginCompleteComponent {
    @Input() nav: IonNav;
    @Input() firstName: string;
    @Input() lastName: string;
    @Input() userId: string;
    @Input() phoneNumber: string;
    @Input() email: string;
    user: User;
    userOrigin = AppConfiguration.userOrigin;
    constructor(private modalCtrl: ModalController, private userProvider: UserProvider, private loadingCtrl: LoadingController) {

    }
    async ngAfterViewInit() {
      await this.checkForUserData(this.userId);
    }
    async checkForUserData(id: string) {
        const subscription = this.userProvider.getUser(id).subscribe(async (data: User) => {
          if (!data.firstName || !data.lastName) {
              data.firstName = this.firstName;
              data.lastName = this.lastName;
              const modal = await this.showAdditionalInfoModal(data);
              const result = await modal.onDidDismiss();
              this.user = result.data;
              this.user.origin = this.userOrigin;
              this.user.createdDate = moment().toISOString();
            } else {
              this.user = data as any;
              if (this.firstName && !this.lastName) {
                this.user.firstName = this.firstName;
              } else if (this.firstName && this.lastName) {
                this.user.firstName = this.firstName;
                this.user.lastName = this.lastName;
              }
            }
          if (this.phoneNumber) {
                this.user.phoneNumber = this.phoneNumber;
            }
          if (this.email) {
                this.user.email = this.email;
            }
          delete this.user.password;
          const loader = await this.loadingCtrl.create();
          try {
              await this.userProvider.updateUser(this.user);
              await loader.dismiss();
            } catch (error) {
              alert(JSON.stringify(error));
            }
          subscription.unsubscribe();
          this.modalCtrl.dismiss(this.user);
        });
    }
    async showAdditionalInfoModal(user: User) {
        const modal = await this.modalCtrl.create({ componentProps: { user }, component: AdditionalAccountInfoForm });
        modal.present();
        return modal;
    }
}
