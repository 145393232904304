import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PubqConfig, PubqConfigProvider } from '../pubq-config/pubq-config';
import { Order } from '../order/order';
import { WorldNetGateway } from './gateways/worldnet.gateway';
import { AppConfiguration } from 'src/app/app.configuration';
import { LoadingController, ToastController, Platform } from '@ionic/angular';
import { ContextProvider } from '../context/context';
import { UserProvider, UserPaymentSource, User } from '../user/user';
import { AddCreditcardOutput, MenuItem } from 'src/app/models';
import { Storage } from '@ionic/storage';
let appSettings = require("../../../../cordova-scripts/appsettings");
//import { MpiRequestResult } from './gateways/gateway.interface';

@Injectable({
  providedIn: "root"
})
export class PaymentProvider {

  config: PubqConfig;
  paymentProvider = AppConfiguration.paymentProvider;
  customUrlProtocol = AppConfiguration.customUrlProtocol;
  browserResponse: any;
  appBuild = "";
  validCoupon = null;
  constructor(
    private storage: Storage,
    private loadingController: LoadingController,
    private contextProvider: ContextProvider,
    private pubqConfigProvider: PubqConfigProvider,
    private http: HttpClient
  ) {
    // this.platform.ready().then(async x => {
    //   this.appBuild = `${await this.appVersion.getVersionNumber()}_${await this.appVersion.getVersionCode()}`
    // })

    this.appBuild = appSettings && appSettings.version ? appSettings.version : 'N/A'

    this.pubqConfigProvider.config.subscribe((x: PubqConfig) => {
      if (!x) return;
      console.log("PubqConfig loaded in PaymentProvider");
      this.config = x;
    });
  }
  getPaymentOptions() {
    return new Promise((resolve, reject) => {
      let sub = this.contextProvider.clientContext.appInfo.subscribe(x => {
        if (x) {
          resolve(x.Context.paymentOptions);
          setTimeout(() => {
            sub.unsubscribe();
          }, 100);
        }
      });
    });
  }

  async sendPaymentErrorToSlack(body: { user, appText, error, order }): Promise<void> {
    return await this.http.post<void>(`${this.config.ApiUrl}/payments/v1/skynet/swish/error`, body).toPromise()
  }

  checkStockBalance(routeName: string, items: MenuItem[]) {
    const body = {
      routeName: routeName,
      products: items
    }

    return this.http.post(this.config.ApiUrl + "/api/v1/stock/checkbalance", body);
  }

  validateCouponCode(code, clientRoute): Observable<{}> {
    const body = {
      couponCode: code,
      client: clientRoute
    }
    return this.http.post(this.config.ApiUrl + "/checkcoupon", body);
  }
  async createPaymentSourceXml(cardData: AddCreditcardOutput, opts?: { mpi: boolean }) {
    let loading = await this.loadingController.create({
      spinner: "lines",
      message: "Lägger till kort"
    });
    loading.present();
    if (opts && opts.mpi) {


      // let gateway = new WorldNetGateway(this.iab, this.http, this.config)
      let result: null;//MpiRequestResult = await gateway.getCardMpiRef(cardData, this.contextProvider.clientContext.paymentGateway.value, this.loadingController);
      let data = {
        card: cardData,
        routeName: this.contextProvider.client.routeName,
        mpiResult: result
      };
      try {
        let cardResult = await this.http.post(`${this.config.ApiUrl}/registerSecureCard`, data).toPromise();
        loading.dismiss();
        return cardResult;
      } catch (error) {
        loading.dismiss();
        return error;
      }

    }
    else {
      let data = {
        card: cardData,
        routeName: this.contextProvider.client.routeName
      };
      try {
        let result = await this.http.post(`${this.config.ApiUrl}/registerSecureCard`, data).toPromise();
        loading.dismiss();
        return result;
      } catch (error) {
        loading.dismiss();
        throw error;
      }
    }

  }
  async chargeReepayCustomer(user: User, paymentSource: UserPaymentSource, order: Order, amount: number, reepayData: any) {
    let receiptOnly = false;
    try {
      receiptOnly = this.contextProvider.clientContext.appInfo.value.Context.receiptOnly;
    } catch (error) {
      receiptOnly = false;
    }
    const body = {
      customerId: paymentSource.recurringReference,
      userPaymentSource: paymentSource,
      email: user.email,
      id: user.id,
      amount: amount,
      order: order,
      paymentType: AppConfiguration.paymentProvider,
      routeName: order.client.routeName,
      appVersion: this.appBuild,
      receiptOnly,
      reepayData
    };
    let url = `${this.config.ApiUrl}/chargecustomer`;
    try {
      let resp = await this.http.post(url, body, { observe: 'response' }).toPromise();
      return resp.body;
    } catch (error) {
      if (error.status === 404) {
        alert("404");
        return (await this.http.post(url, body, { observe: 'response' }).toPromise()).body;
      }
      return error.body;
    }
  }

  chargeRecurringCustomer(user: User, paymentSource: UserPaymentSource, amount, order: Order) {
    let receiptOnly = false;
    try {
      receiptOnly = this.contextProvider.clientContext.appInfo.value.Context.receiptOnly;
    } catch (error) {
      receiptOnly = false;
    }
    const body = {
      customerId: paymentSource.recurringReference,
      userPaymentSource: paymentSource,
      email: user.email,
      id: user.id,
      amount: amount,
      order: order,
      paymentType: AppConfiguration.paymentProvider,
      routeName: order.client.routeName,
      appVersion: this.appBuild,
      receiptOnly
    };
    if (AppConfiguration.paymentProvider === "worldnet") {
      // let gateway = new WorldNetGateway(this.iab, this.http, this.config);
      // return gateway.chargeCardReference(body)
    }
  }
  async chargeCustomerTab(user: User, recurringDetail: UserPaymentSource, order: Order) {
    const body = {
      email: user.email,
      id: user.id,
      amount: order.totalCost,
      order: order,
      paymentType: recurringDetail.type,
      customerId: recurringDetail.recurringReference,
      routeName: order.client.routeName,
      appVersion: this.appBuild
    };
    return this.http.post<TransactionResult>(this.config.ApiUrl + "/chargecustomer", body).toPromise();
  }
  async chargeSwishCustomer(user: User, recurringDetail: UserPaymentSource, amount, order: Order): Promise<TransactionResult> {
    let receiptOnly = false;
    try {
      receiptOnly = this.contextProvider.clientContext.appInfo.value.Context.receiptOnly;
    } catch (error) {
      receiptOnly = false;
    }
    const body = {
      paymentType: recurringDetail.type,
      paymentReference: "",
      email: user.email,
      amount: amount,
      order: order,
      receiptOnly
    };
    var data = {
      routeName: order.client.routeName,
      userId: user.id,
      amount: amount,
      order: order,
      appVersion: this.appBuild,
      mobileNumber: user.phoneNumber
    };
    var promise = new Promise<void>(async (resolve, reject) => {
      try {
        let response = await this.http.post<SwishResponse>(`${this.config.ApiUrl}/initiateSwishPaymentMobile`, data).toPromise();
        body.paymentReference = response.paymentReference;
        this.storage.set("swish", JSON.stringify(body)).then(x => {
          //let callbackUrl = `${this.config.ApiUrl}/swish/${order.client.routeName}/1234`;
          let swishPayload = `paymentrequest?token=${response.paymentRequestToken}`;
          resolve();
          //this.contextProvider.openSwish(swishPayload);
        });

      } catch (error) {
        reject(error);
      }
    });
    return promise.then(x => {
      return null;
    });

  }

  chargeSwishCustomerCachedOrder(body) {
    let promise = new Promise((resolve, reject) => {
      let subscription = this.pubqConfigProvider.config.subscribe((x: PubqConfig) => {
        if (!x) return;
        this.config = x;
        this.storage.remove("swish");
        this.http.post<TransactionResult>(x.ApiUrl + "/chargecustomer", body).toPromise().then(success => {
          resolve(success);
        }).catch(err => {
          reject(err);
        });
        setTimeout(() => {
          subscription.unsubscribe();
        }, 100);
      });

    });
    return promise;
  }


  /*
  >=>         >=> >=> >======>   >======>     >=>>=>   
   >=>       >=>  >=> >=>    >=> >=>    >=> >=>    >=> 
    >=>     >=>   >=> >=>    >=> >=>    >=>  >=>       
     >=>   >=>    >=> >======>   >======>      >=>     
      >=> >=>     >=> >=>        >=>              >=>  
       >===>      >=> >=>        >=>        >=>    >=> 
        >=>       >=> >=>        >=>          >=>>=>     
  */
  async chargeVippsCustomer(user: User, recurringDetail: UserPaymentSource, order: Order, routeParam: { client: string, city: string, routeName: string }, cart: any): Promise<TransactionResult> {
    let receiptOnly = false;
    try {
      receiptOnly = this.contextProvider.clientContext.appInfo.value.Context.receiptOnly;
    } catch (error) {
      receiptOnly = false;
    }

    console.log("Starting to charge customer with Vipps");

    const body = {
      paymentType: recurringDetail.type,
      paymentReference: "",
      paymentUrl: "",
      email: user.email,
      order: order,
      receiptOnly,
      cart: cart
    };

    var data = {
      routeName: order.client.routeName,
      userId: user.id,
      phoneNumber: user.phoneNumber ? user.phoneNumber : null,
      order: order,
      appVersion: this.appBuild,
      fallbackUrl: `${window.location.origin}/u/${routeParam.routeName}/cart?payment=vipps`,
      isApp: false
    };

    var promise = new Promise<void>(async (resolve, reject) => {
      try {
        let response = await this.http.post<VippsResponse>(`${this.config.ApiUrl}/payments/v1/vipps/initiatePayment`, data).toPromise();
        body.paymentReference = response.orderId;
        body.paymentUrl = response.url;
        this.storage.set("PUBQ_VIPPS", JSON.stringify(body)).then(x => {
          console.log("Response from initiatePayment with vipps");
          let vippsPayload = body.paymentUrl;
          resolve();
          //this.contextProvider.openVipps(vippsPayload);
        });
      } catch (error) {
        reject("error");
      }


    });
    return promise.then(x => {
      return null;
    });

  }

  chargeVippsCachedOrder(body) {

    let promise = new Promise((resolve, reject) => {

      const sub = this.pubqConfigProvider.config.subscribe((x: PubqConfig) => {
        if (!x) return;

        this.config = x;

        try {
          this.http.post<TransactionResult>(`${this.config.ApiUrl}/chargecustomer`, body).toPromise().then(success => {
            this.storage.remove("PUBQ_VIPPS");
            resolve(success);
          }).catch(err => {
            reject(err);
            sub.unsubscribe();
          });
        } catch (error) {
          reject("error");
          if (sub)
            sub.unsubscribe();
        }
      });

    });

    return promise;
  }

  async payTab2(user: User, recurringDetail: UserPaymentSource, routeName: string, tabKey: any, tipAmount: any, tabOrder: Order) {
    tabOrder.tabKey = tabKey;
    tabOrder.orderType = "customerTab";
    tabOrder.tipAmount = tipAmount;
    if (recurringDetail.type === "swish") {
      tabOrder.payment = {
        type: "swish",
        internalId: "",
        externalId: ""
      }
      let data = {
        routeName: routeName,
        userId: user.id,
        order: tabOrder,
        appVersion: this.appBuild
      };
      let response = await this.http.post<SwishResponse>(`${this.config.ApiUrl}/initiateSwishPaymentMobile`, data).toPromise();
      tabOrder.payment.internalId = response.paymentReference;
      let callbackUrl = `${this.customUrlProtocol}/swish/${routeName}/1234`;
      
    }
    let data = {
      userId: user.id,
      reference: recurringDetail.recurringReference,
      paymentType: AppConfiguration.paymentProvider,
      routeName,
      tabKey,
      tipAmount,
      appVersion: this.appBuild,
      tabOrder: tabOrder
    };
    let result = await this.http.post<TransactionResult>(this.config.ApiUrl + "/paytabforsingleuser", data).toPromise();
    return result;
  }
}

export class StripeCard {
  number: string;
  cvc: string;
  expMonth: string;
  expYear: string;
}
export class RecurringDetailsResponse {
  details: RecurringDetail[];
  shopperReference: string;
}
export class RecurringDetail {
  RecurringDetail: {
    card: any,
    paymentMethodVariant: string, // card type (credit debit),
    variant: string // card issuer
    recurringDetailReference: string
  }
}
export class CouponGoblin {
  static valueWithCouponDeducted(value, couponPercent) {
    const deductedValue = value - (value * (couponPercent / 100));
    return deductedValue;
  }
  static valueOfCoupon(value, couponPercent) {
    const deductedValue = value * (couponPercent / 100);
    return deductedValue;
  }
}
export class TaxGoblin {
  static valueWithoutTax(value, taxPercent) {
    const taxDecimal = taxPercent / 100 + 1;
    return parseFloat((value * (value / taxDecimal / value)).toFixed(2));
  }

  static taxOfValue(value, taxPercent) {
    const taxDecimal = taxPercent / 100 + 1;
    return parseFloat((value * (1 - (value / taxDecimal) / value)).toFixed(2));
  }
}
export class DateGoblin {
  static getNiceDate(dateString: string) {
    var date = new Date(dateString);
    var year = date.getFullYear();
    var month = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
    var day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
    return "" + year + month + day;
  }
  static getNiceDateWithTime(dateString: string) {
    var date = new Date(dateString);
    var year = date.getFullYear();
    var month = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
    var day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
    var niceHour = date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
    var niceMinute = date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();
    return year + "-" + month + "-" + day + " " + niceHour + ":" + niceMinute;
  }
}
export class TransactionResult {
  error?: any;
  charge?: any;
  missing?: any;
  updatedCart?: any;
  approvedOrder?: { queueIds: any[], order: Order };
}
export class MpiResult {
  error?;
  mpiRef: string;
}
export class SwishResponse {
  paymentRequestToken?: string;
  paymentReference: string;
}
export class VippsResponse {
  orderId: string;
  url: string;
}
export class PaymentV2Response {
  idempotencyKey: string;
  success: boolean;
  approvedOrder?: any;
  providerData?: any;
  error?: {
    showResponseText: boolean,
    languageCode: string,
    responseText: string,
    resetOrder?: boolean;
  };
  missing?: any;
  updatedCart?: any;
}
