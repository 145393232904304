import { Component, Input, ViewChild } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Subscription } from 'rxjs';
import { AppConfiguration } from '../app.configuration';

import {
  AlertController,
  IonContent, IonNav,
  IonRouterOutlet,
  LoadingController,
  MenuController,
  ModalController,
  NavController,
} from '@ionic/angular';
import { CartProvider } from '../services/cart/cart.service';
import { AppInfo, ContextProvider, DeliveryOption } from '../services/context/context';
import { User, UserPaymentSource, UserProvider } from '../services/user/user';
import { PaymentProvider, PaymentV2Response } from '../services/payment/payment';
import { Order, OrderProvider } from '../services/order/order';
import { CartItemsOutput } from '../cart-items/cart-items';
import { DeliveryPlaceProvider } from '../services/delivery-place/delivery-place';
import { ActivatedRoute } from '@angular/router';

import { Storage } from '@ionic/storage';
import { BonusProvider } from '../services/bonus/bonus';

import { LanguagePipe } from '../pipes/language.pipe';
import { LanguageProvider } from '../services/language/language.provider';
import { EventsProvider } from '../services/events/events.provider';
import { ReepayGateway } from '../services/payment/gateways/reepay.gateway';
import { PubqConfigProvider } from '../services/pubq-config/pubq-config';
import { UnitProvider } from '../services/unitProvider';
import { ThemeProvider } from '../services/theme/theme-provider.service';
import { CurrencyProvider } from '../services/currency/currency.provider';
import { CardPaymentProvider } from '../services/payment/cardPaymentProvider';
import { StockModalComponent } from '../stock-balance/stock.component';
import { PaymentUtils } from '../services/payment/paymentUtils';
import { SelectDeliveryPlaceModal } from '../side-menu/select-delivery-place/selectDeliveryPlace';
import { SwishDialogComponent } from '../payment/swish-dialog/swish-dialog.component';
import { CardDialogComponent } from '../payment/card-dialog/card-dialog.component';
import { VippsDialogComponent } from '../payment/vipps-dialog/vipps-dialog.component';

@Component({
  selector: 'page-cart',
  templateUrl: 'cart.html',
  styleUrls: ['cart.scss']
})
export class CartPage {
  @ViewChild('cartContent') content: IonContent;
  @Input() nav: IonNav;
  background = 'center url(\'./img/cartBackground.jpg\') no-repeat';
  showEstimatedWaitingTime: boolean;
  estimatedWaitingTimeText: string;
  estimatedWaitingTimeInMinutes: number;
  // globalOffersEnabled = false;
  customerTabEnabled = false;
  // weeklyMenu = { enabled: false };
  user: User;
  activeTabsCount = 0;
  activeTableBooking;
  subscriptions = new Subscription();
  activatedRouteSubscription: Subscription;
  lastUsedCustomerTabKey: string;
  currentUnitName: string;
  allergyInfo;
  isLoading = false;
  acceptingTerms = false;

  hasPushPermission = false;
  unitHasCustomTicketEnabled = false;
  handled3DSecurePayment = false;
  globalOffers = [];
  allergyString = '';
  myBookingString = '';
  bookTableString = '';

  // order: Order;
  modalCloseDelayTime = 500;

  customerCommentRequired = false;
  showMap = true;
  showAllTypes = false;
  stockBalanceEnabled = false;
  image: string;
  // items: CartItem[];
  userStoredPaymentOptions: any[];
  paymentOptions: any;
  ifCartNotSelected = true;
  updateCartPageData: boolean;
  userName: string;
  userPhone: string;
  tableOption: DeliveryOption;
  showTableNumber = false;
  incorrectTableNumber = false;
  showNameBlock = false;
  //thisUserIsAnon = firebase.auth().currentUser.isAnonymous;
  name = '';
  mobileNumber = '';
  validMobileNumber = false;
  anonUserFirstSecondName: any;
  split: any;
  orderFromCart: any;
  provider = AppConfiguration.paymentProvider;
  contextIsLoaded: boolean = false;
  userIsLoaded: boolean = false;
  unit;
  // client?: string = null;
  // city?: string = null;
  routeName?: string = null;
  appInfo: AppInfo;
  contextSubscription: Subscription;
  userSubscription: Subscription;
  constructor(
    public navCtrl: NavController,
    private alertCtrl: AlertController,
    private loaderCtrl: LoadingController,
    public cartProvider: CartProvider,
    public contextProvider: ContextProvider,
    private modalCtrl: ModalController,
    private userProvider: UserProvider,
    private paymentProvider: PaymentProvider,
    private storage: Storage,
    private activatedRoute: ActivatedRoute,
    public orderProvider: OrderProvider,
    private sideMenuCtrl: MenuController,
    //private router: Router,
    private routerOutlet: IonRouterOutlet,
    private bonusProvider: BonusProvider,
    //private modalController: ModalController,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe,
    private alertController: AlertController,
    private eventsProvider: EventsProvider,
    private unitProvider: UnitProvider,
    private http: HttpClient,
    private pubqConfig: PubqConfigProvider,
    public deliveryPlaceProvider: DeliveryPlaceProvider,
    public themeProvider: ThemeProvider,
    public currencyProvider: CurrencyProvider,
    private cardPaymentProvider: CardPaymentProvider,
    private modalController: ModalController,
    private paymentUtils: PaymentUtils
  ) {
    // this.client = this.activatedRoute.snapshot.params.client;
    // this.city = this.activatedRoute.snapshot.params.city;
    this.routeName = this.activatedRoute.snapshot.params.routeName;

    this.subscriptions.add(
      this.userProvider.currentUser.subscribe(async (x) => {
        if (!x) { return }
        this.user = x;
        if (this.user.firstName && this.user.lastName || this.user.firstName && this.user.lastName === '') {
          this.name = `${this.user.firstName} ${this.user.lastName}`;
          this.userName = `${this.user.firstName} ${this.user.lastName}`;
        }
        if (this.user.phoneNumber) {
          this.mobileNumber = this.user.phoneNumber;
        }
        if (this.user.activeTabs) {
          this.activeTabsCount = Object.keys(this.user.activeTabs).length;
        } else {
          this.activeTabsCount = 0;
        }


        let paymentSourcesSubscription = (await this.userProvider.getUserPaymentSources(x.id)).subscribe((x) => {
          this.userStoredPaymentOptions = x;
          // Set vipps to default payment if enabled
        });
        this.subscriptions.add(paymentSourcesSubscription);


        this.userIsLoaded = true;
      })
    );

    this.subscriptions.add(
      this.contextProvider.clientContext.appInfo.subscribe((x) => {
        if (!x) { return; }
        // this.splashScreen.hide();
        this.estimatedWaitingTimeText = x.Context.estimatedWaitingTimeText;
        this.showEstimatedWaitingTime = x.Context.showEstimatedWaitingTime;
        // this.weeklyMenu =
        //   x.Context.weeklyMenu && x.Context.weeklyMenu.enabled
        //     ? { enabled: true }
        //     : { enabled: false };
        this.customerTabEnabled =
          x.Context.paymentOptions &&
          x.Context.paymentOptions.customerTab &&
          x.Context.paymentOptions.customerTab.enabled;
        this.allergyInfo = x.Context.allergyInfo;
        this.unitHasCustomTicketEnabled = x.Context.customTickets;
        // this.globalOffersEnabled = x.Context.globalOffersEnabled;
        // if (this.globalOffersEnabled) {
        //   this.checkGlobalOffers();
        // }

        this.appInfo = x;
        const appInfo = this.appInfo;

        if (appInfo && appInfo.Context.paymentProvider) {
          if (!AppConfiguration.originalPaymentProvider)
            AppConfiguration.originalPaymentProvider = AppConfiguration.paymentProvider;
          AppConfiguration.paymentProvider = appInfo.Context.paymentProvider;
          console.dir(AppConfiguration.paymentProvider);
          this.provider = AppConfiguration.paymentProvider;
        }

        if (appInfo.Context.customerCommentRequired)
          this.customerCommentRequired = appInfo.Context.customerCommentRequired;

        // this.items = this.cartProvider.cart.items;

        // this.order = this.orderProvider.orderWaitingForApproval;
        this.stockBalanceEnabled = appInfo.Context.stockBalanceEnabled;
        this.contextIsLoaded = true;
      })
    );

    this.subscriptions.add(
      this.userProvider.activeTableBooking.subscribe((x) => {
        this.activeTableBooking = x;
      })
    );

    // NOT IMPLEMENTED YET!!!
    //this.chargePossibleSwishCustomerCached();
    //this.checkTopicSubscription(this.contextProvider.client.routeName);

    // NOT IMPLEMENTED YET!!!
    // if (this.allergyInfo) {
    //   this.allergyString = this.languagePipe.transform(
    //     `, mellan kl ${this.allergyInfo.open}-${this.allergyInfo.close}, så svarar vi gärna på dina frågor om innehåll`,
    //     'CART_ALLERGY_CONTACT', this.languageProvider.currentLanguage,
    //     [this.allergyInfo.open, this.allergyInfo.close]);
    // }

    this.myBookingString = this.languagePipe.transform('Min bokning', 'CART_MY_BOOKING', this.languageProvider.currentLanguage);
    this.bookTableString = this.languagePipe.transform('Boka bord', 'CART_BOOK_TABLE', this.languageProvider.currentLanguage);
  }

  async handelQueryParam() {
    if (this.activatedRouteSubscription) this.activatedRouteSubscription.unsubscribe();
    this.activatedRouteSubscription = this.activatedRoute.queryParams.subscribe(async params => {
      if (!params) {
        if (!this.contextProvider.initDone) {
          console.log("Going home...........")
          this.goToMenu();
        }
        return;
      }

      this.isLoading = true;

      if (!this.contextProvider.initDone) {
        await this.contextProvider.init(this.routeName);
      }


      let automaticCardOnFilePayment = params['automaticCardOnFilePayment'];
      let payment = params['payment'];
      if (automaticCardOnFilePayment) {

        if (!this.handled3DSecurePayment) {
          this.isLoading = false;
          this.handled3DSecurePayment = true;
          this.loaderCtrl.create();
          setTimeout((context) => {
            //this.useStoredPaymentOption(this.paymentOptions);
            // this.orderFromCart = this.setUserForOrder(this.orderFromCart, this.user);
            // this.orderProvider.orderWaitingForApproval = this.orderFromCart;
            this.payWithReepayCardOnFile();
          }, 1000, this);
        }
      }

      // Handling a started vipps payment
      // This url should only be called from Vipps 
      else if (payment && payment === 'vipps') {

        let vippsOrder = await this.storage.get("PUBQ_VIPPS");
        vippsOrder = JSON.parse(vippsOrder);
        if (vippsOrder && vippsOrder.cart && vippsOrder.order) {

          this.cartProvider.cart = vippsOrder.cart;
          this.orderProvider.orderWaitingForApproval = <Order>vippsOrder.order;

          await this.verifyPaymentWithVipps();
          this.isLoading = false;
        } else {
          alert("Could not find any stored vipps order!");
          this.goBack();
          this.isLoading = false;
        }
      }

      // Handling a started swish payment
      // This url should only be called from Swish 
      else if (payment && payment === 'swish') {

        let swishOrder = await this.storage.get("PUBQ_SWISH");
        swishOrder = JSON.parse(swishOrder);
        if (swishOrder && swishOrder.cart && swishOrder.order && swishOrder.idempotencyKey && swishOrder.routeName) {

          this.cartProvider.cart = swishOrder.cart;
          this.orderProvider.orderWaitingForApproval = <Order>swishOrder.order;

          await this.verifyPaymentWithSwish();
          this.isLoading = false;

        } else {
          alert("Could not find any stored Swish order!");
          this.goBack();
          this.isLoading = false;
        }
      }


      // Handling a started stripe payment
      // This url should only be called from Stripe 
      else if (payment && payment === 'stripe' || payment === 'stripeorder') {

        let order = await this.storage.get("PUBQ_STRIPE");
        order = JSON.parse(order);
        if (order && order.cart && order.order && order.idempotencyKey && order.routeName) {

          this.cartProvider.cart = order.cart;
          this.orderProvider.orderWaitingForApproval = <Order>order.order;

          await this.verifyPaymentWithStripe();
          this.isLoading = false;

        } else {
          alert("Could not find any stored Swish order!");
          this.goBack();
          this.isLoading = false;
        }
      } else {
        if (!this.contextProvider.initDone) {
          console.log("Going home...........")
          this.goToMenu();
        }
        this.isLoading = false;
      }
      setTimeout(() => {
        this.activatedRouteSubscription.unsubscribe();
      }, 10)
    });
  }

  //   /****************** ViewModel Events ******************/
  async ngOnInit() {

  }
  getTermsUrl() {
    if (!this.appInfo || !this.appInfo.Context.restaurantInfo) return "https://info.pubq.se/terms";
    let webname = encodeURI(this.appInfo.Context.restaurantInfo.companyName);
    let webno = encodeURI(this.appInfo.Context.restaurantInfo.orgNo);
    return `https://info.pubq.se/terms?c=${webname}&nr=${webno}`;
  }
  ngOnDestroy() {
    if (this.contextSubscription)
      this.contextSubscription.unsubscribe();
    if (this.activatedRouteSubscription)
      this.activatedRouteSubscription.unsubscribe();
    if (this.userSubscription)
      this.userSubscription.unsubscribe();
  }

  ionViewWillEnter() {
    this.handelQueryParam();
  }

  ionViewDidEnter() {
    if (this.content) this.content.scrollToTop();
    this.acceptingTerms = false;
    // If active retrying cancel it.
    this.paymentUtils.cancelRetrying();
    this.updateCartPageData = !this.updateCartPageData;
  }

  /**************** End ViewModel Events **************************/

  isAllLoaded() {
    return this.userIsLoaded && this.contextIsLoaded;
  }

  getPaymentMethod(event) {
    this.paymentOptions = event;
    this.ifCartNotSelected = this.paymentOptions && this.paymentOptions.length > 2;
  }

  async userAddressInfo(event) {
    this.userName = event.userName;
    this.userPhone = event.userPhone;
  }

  formValid() {
    return this.userName && this.userPhone;
  }

  async showOffers() {

  }

  async checkGlobalOffers() {
    // this.subscriptions.add(this.bonusProvider.globalOffers.subscribe(x => {
    //   this.globalOffers = x;
    // }));
  }

  async checkTopicSubscription(routeName) {
    const hasSeen = await this.userProvider.hasSeenPushPermissionRequest();
    const hasPermission = await this.userProvider.hasPushPermission();

  }

  async startTakeaway() {

  }

  openMenu() {
    this.sideMenuCtrl.enable(true, 'first');
    this.sideMenuCtrl.open('first');
  }

  showTypes() {
    this.showAllTypes = !this.showAllTypes;
  }





  selectedClientName() {
    return this.contextProvider.getClient().name;
  }

  resetOrderCoupon(order): Order {
    order.coupon = undefined;
    order.totalCost = this.cartProvider.cart.sum;
    return order;
  }

  async onPaymentInitiation(event?: CartItemsOutput) {
    try {
      // Update order
      this.orderProvider.orderWaitingForApproval.deliveryDate = event.selectedDate;
      this.orderProvider.orderWaitingForApproval.coupon = event.coupon;
      this.orderProvider.orderWaitingForApproval.customerComment = event.customerComment;
      this.orderProvider.orderWaitingForApproval.totalCost = event.totalCost;
      this.orderProvider.orderWaitingForApproval.totalFoodItems = event.totalFoodItems;
      this.orderProvider.orderWaitingForApproval.totalFoodCost = event.totalFoodCost;
      this.orderProvider.orderWaitingForApproval.totalDrinksCost = event.totalDrinksCost;
      this.orderProvider.orderWaitingForApproval.totalDrinkItems = event.totalDrinkItems;
      this.orderProvider.orderWaitingForApproval.totalItems = event.totalItems;
      this.orderProvider.orderWaitingForApproval.order = {
        food: this.cartProvider.getFoodItems(event.items),
        drinks: this.cartProvider.getDrinkItems(event.items),
      };

      if (this.orderProvider.orderWaitingForApproval.order.food.length === 0 && this.orderProvider.orderWaitingForApproval.order.drinks.length === 0) {
        this.goToMenu();
      }
      await this.setDeviceTokenForOrder(this.orderProvider.orderWaitingForApproval);
    } catch (error) {
      console.error(`Redirecting to menu - ${error.message}`);
      this.sideMenuCtrl.close();
      this.goToMenu();
    }
  }

  setUserForOrder(order: Order, user: User): Order {
    try {
      order.userId = user.id;

      if (!user.firstName && !user.lastName) {
        order.orderCode = user.id.substring(0, 4);
      } else if (user.firstName && !user.lastName) {
        order.orderCode = user.firstName.substring(0, 2) + user.id.substring(0, 2);
      } else {
        order.orderCode = user.firstName.substring(0, 2) + user.lastName.substring(0, 2);
      }

      this.user.phoneNumber = this.mobileNumber;

      if (order.orderCode.toLowerCase() === 'anal') { order.orderCode = 'lana'; }
      order.orderCode = order.orderCode.toUpperCase();
      return order;
    } catch (error) {
      console.log(error);
    }

  }

  async setDeviceTokenForOrder(order: Order): Promise<Order> {
    order.deviceToken = await this.userProvider.getDeviceToken();
    order.deviceToken_v2 = {
      token: order.deviceToken,
      projectId: AppConfiguration.firebaseProject
        ? AppConfiguration.firebaseProject.projectId
        : 'default',
    };
    return order;
  }

  paymentMethodValid() {
    if (this.orderProvider.orderWaitingForApproval.totalCost <= 0) {
      this.paymentOptions = { recurringReference: "free", type: this.provider, nickname: "Free" };
    }
    if (typeof this.paymentOptions === "object")
      return Object.keys(this.paymentOptions).length !== 0;
    return this.paymentOptions;
  }

  async showRefusedAlert(error, msg) {
    if (error === 'cordova_not_available') { return; }
    let text;
    if (error.error.code && error.error.code === 'custom') {
      text = error.error.message;
    } else if (error.error.pubqmaintenance) {
      text = this.languagePipe.transform('Just nu pågår en uppdatering och ditt köp kan inte genomföras',
        'PAYMENT_ERROR_MAINTENANCE', this.languageProvider.currentLanguage);
    } else { text = msg; }
    const alert = await this.alertCtrl.create({
      header: 'Oops!',
      subHeader: text,
      message: error.error.additionalInfo,
      buttons: [this.languagePipe.transform('OK', 'OK', this.languageProvider.currentLanguage)],
    });
    alert.present();
  }

  async chargePossibleSwishCustomerCached() {
    const loader = await this.loaderCtrl.create({
      message: this.languagePipe.transform('Förbereder Swish-betalning', 'CART_PREPARNG_SWISH', this.languageProvider.currentLanguage),
    });
    try {
      let body = await this.storage.get('swish');
      if (!body) {
        return;
      }
      loader.present();
      body = JSON.parse(body);

      await this.paymentProvider.chargeSwishCustomerCachedOrder(body);
      loader.dismiss();
      this.clearCart();
      this.resetOrderFlow();
    } catch (err) {
      loader.dismiss();
      this.showRefusedAlert(err, this.languagePipe.transform('Betalningen avbröts',
        'CART_ITEM_REMOVE_CODE', this.languageProvider.currentLanguage));
    }
  }

  async addToCustomerTab(user, customerTab: UserPaymentSource, order: Order) {

    const loader = await this.loaderCtrl.create({
      message: this.languagePipe.transform('Skapar order och lägger till på notan',
        'CART_CREATING_ORDER', this.languageProvider.currentLanguage),
    });
    loader.present();
    try {
      await this.paymentProvider.chargeCustomerTab(user, customerTab, order);
      loader.dismiss();
      this.clearCart();
      this.resetOrderFlow();
    } catch (err) {
      loader.dismiss();
      this.showRefusedAlert(err, this.languagePipe.transform('Något gick fel', 'CART_SOME_ERROR', this.languageProvider.currentLanguage));
    }
  }

  goToMenu() {
    this.navCtrl.navigateBack(`u/${this.routeName}`);
  }

  goBack() {
    this.goToMenu();
  }

  async close() {
    const alert = await this.alertCtrl.create({
      header: this.languagePipe.transform("Avbryt", 'CANCEL', this.languageProvider.currentLanguage),
      message: this.languagePipe.transform('Vill du avbryta din beställning och rensa kundkorgen?', 'CANCEL_ORDER', this.languageProvider.currentLanguage),
      buttons: [
        {
          text: this.languagePipe.transform("Nej", 'NO', this.languageProvider.currentLanguage),
          role: 'cancel'
        },
        {
          text: this.languagePipe.transform("Ja", 'YES', this.languageProvider.currentLanguage),
          handler: () => {
            this.cartProvider.empty();
            this.goBack();
          }
        }
      ]
    });

    await alert.present();
  }

  async chargeRecurringCustomer(
    user: User,
    recurringDetailReference: string,
    order: Order
  ) {
    const amount = order.totalCost;
    const loader = await this.loaderCtrl.create({});
    await loader.present();

    // Need to be fixed
    // this.paymentProvider
    //   .chargeRecurringCustomer(user, recurringDetailReference, amount, order)
    //   .subscribe(
    //     (transactionResult: TransactionResult) => {
    //       loader.dismiss();
    //       if (transactionResult.error) {
    //         this.showRefusedAlert(
    //           transactionResult.error,
    //           this.languagePipe.transform(
    //             'Se så ditt kort är öppet för internetköp och att tillräcklig summa finns tillgänglig',
    //             'CART_CHECK_CARD', this.languageProvider.currentLanguage)
    //         );
    //       } else {
    //         this.clearCart();
    //         this.resetOrderFlow();
    //       }
    //     },
    //     (error) => {
    //       loader.dismiss();
    //       this.showRefusedAlert(
    //         error,
    //         this.languagePipe.transform(
    //           'Se så ditt kort är öppet för internetköp och att tillräcklig summa finns tillgänglig',
    //           'CART_CHECK_CARD', this.languageProvider.currentLanguage)
    //       );
    //     }
    //   );
  }



  // promptForTableNumber(tableNumber?: number) {
  //   this.showTableNumber = true;
  //   this.incorrectTableNumber = true;
  //   if (tableNumber === undefined) {
  //     tableNumber = 1;
  //     this.tableNumber = tableNumber;
  //   }
  //   if (Number(tableNumber) >= Number(this.deliveryPlaceProvider.tableOption.minValue) &&
  //       Number(tableNumber) <= Number(this.deliveryPlaceProvider.tableOption.maxValue)) {
  //     this.tableNumber = parseInt(String(tableNumber), 10);
  //     this.incorrectTableNumber = false;
  //   }
  // }

  async showError(error) {
    const alert = await this.alertCtrl.create({
      header: this.languagePipe.transform('Fel', 'ERROR', this.languageProvider.currentLanguage),
      subHeader: error,
      buttons: [this.languagePipe.transform('OK', 'OK', this.languageProvider.currentLanguage)]
    });
    alert.present();
  }

  validName() {
    const reg = new RegExp(/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}\s{1,1}[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/);
    return reg.test(this.name)
  }

  // validMobileNumber() {
  //   const reg = new RegExp(/^\+(\d{2,4}\-)?(\d{7,10})$/);
  //   return reg.test(this.mobileNumber)
  // }



  clearCart() {
    this.cartProvider.empty();
    this.orderProvider.activatedOfferIds = [];
    this.orderProvider.orderWaitingForApproval = undefined;
  }

  resetOrderFlow() {
    this.deliveryPlaceProvider.tableNumberObservable.next(null);
    this.deliveryPlaceProvider.selectedTableNumber = null;
  }

  disablePayment() {
    // Disable payment if option required customer comment is activated
    // and the user has not entered a comment
    if (this.customerCommentRequired) {
      if (this.orderProvider.orderWaitingForApproval && this.orderProvider.orderWaitingForApproval.customerComment && this.orderProvider.orderWaitingForApproval.customerComment !== '')
        return false
      else
        return true;
    }
    return false;
  }

  canPayForOrder() {
    let ok = true;
    let terms = !this.acceptingTerms;
    let cart = !this.ifCartNotSelected && this.orderProvider.orderWaitingForApproval && this.orderProvider.orderWaitingForApproval.totalCost && this.orderProvider.orderWaitingForApproval.totalCost > 0;
    let hasalcohol = this.cartProvider.cart.hasAlcohol && this.deliveryPlaceProvider.choseVariant === this.deliveryPlaceProvider.takeawayText;
    //this.userName === ''  ||
    // !this.validMobileNumber ||
    // !this.validName() ||
    let tablenumber = (this.deliveryPlaceProvider.choseVariant === this.deliveryPlaceProvider.tablesText && !this.deliveryPlaceProvider.validateTable());
    let nopayment = this.disablePayment();
    let delivery = !this.deliveryPlaceProvider.choseVariant
    let text = (this.deliveryPlaceProvider.choseVariant === this.deliveryPlaceProvider.deliveryText && !this.deliveryPlaceProvider.validateDelivery());
    ok = cart || hasalcohol || tablenumber || nopayment || text || terms || delivery;
    return ok;
  }
  /*
  >======>         >>       >=>      >=>       >=======>     >===>      >======>           
  >=>    >=>      >>=>       >=>    >=>        >=>         >=>    >=>   >=>    >=>         
  >=>    >=>     >> >=>       >=> >=>          >=>       >=>        >=> >=>    >=>         
  >======>      >=>  >=>        >=>            >=====>   >=>        >=> >> >==>            
  >=>          >=====>>=>       >=>            >=>       >=>        >=> >=>  >=>           
  >=>         >=>      >=>      >=>            >=>         >=>     >=>  >=>    >=>         
  >=>        >=>        >=>     >=>            >=>           >===>      >=>      >=>       
                                                                                           
      >===>      >======>     >====>     >=======> >======>                                
    >=>    >=>   >=>    >=>   >=>   >=>  >=>       >=>    >=>                              
  >=>        >=> >=>    >=>   >=>    >=> >=>       >=>    >=>                              
  >=>        >=> >> >==>      >=>    >=> >=====>   >> >==>                                 
  >=>        >=> >=>  >=>     >=>    >=> >=>       >=>  >=>                                
    >=>     >=>  >=>    >=>   >=>   >=>  >=>       >=>    >=>                              
      >===>      >=>      >=> >====>     >=======> >=>      >=>                     
  */
  async payForOrder() {
    try {
      this.handled3DSecurePayment = false;

      this.useStoredPaymentOption(this.paymentOptions);
      // PABI-2021-03-17 :  Removed unit we need it
      this.orderProvider.orderWaitingForApproval = this.setUserForOrder(this.orderProvider.orderWaitingForApproval, this.user);

      //this.orderProvider.orderWaitingForApproval.version = "web-" + appSettings && appSettings.version ? appSettings.version : 'N/A';

      const order = this.orderProvider.orderWaitingForApproval;

      // PABI - Removed 2021-09-09
      order.tableNumber = this.deliveryPlaceProvider.tableNumber;


      if (this.deliveryPlaceProvider.choseVariant !== this.deliveryPlaceProvider.deliveryText) {
        delete order.deliveryComment;
        // delete order.userName;
        delete order.phoneNumber;
        delete order.deliveryRadius;
        delete order.deliveryLat;
        delete order.deliveryLong;
        delete order.deliveryFee;
        delete order.deliveryAddress;
      }
      // const user = this.userProvider.currentUser.value;
      // const paymentSource = this.orderProvider.paymentOfChoice;
      // const amount = order.totalCost;
      // await this.paymentProvider.chargeRecurringCustomer(
      //   user,
      //   paymentSource,
      //   amount,
      //   order
      // );

      if (this.paymentOptions === "vipps") {
        await this.payWithVippsV2();
        //await this.payWithVipps();
      } // Handle diffrent payment options 
      else if (this.paymentOptions === "swish") {
        if (this.validMobileNumber) {
          await this.payWithSwish();
        } else {
          await this.showPaymentPrompt();
        }
      }
      else if (this.provider === "reepay" && this.paymentOptions === "newcard") {
        if (this.validMobileNumber) {
          await this.payWithReepayNewCard();
        } else {
          await this.showPaymentPrompt();
        }
      }
      else if (this.provider === "reepay") {
        //await this.payWithReepayCardOnFile();
        if (this.validMobileNumber) {
          await this.payWithReepayCharge();
        } else {
          await this.showPaymentPrompt();
        }
      }
      // else if (this.provider === "stripe" && this.paymentOptions !== "newcard") {
      //   if (this.validMobileNumber) {
      //     await this.payWithExistingCard(AppConfiguration.paymentProvider, this.paymentOptions);
      //   } else {
      //     await this.showPaymentPrompt();
      //   }
      // }
      else if (this.provider === "stripe") {
        if (this.validMobileNumber) {
          await this.payWithCard(this.provider, this.userStoredPaymentOptions)
        } else {
          await this.showPaymentPrompt();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async changeDelivery(provider: DeliveryPlaceProvider) {
    let modal = await this.modalCtrl.create({
      component: SelectDeliveryPlaceModal,
      componentProps: {
        appInfo: this.contextProvider.clientContext.appInfo.value,
        clientInfo: this.contextProvider.clientContext.currentClient.value,
      },
      cssClass: "modal-drawer",
      swipeToClose: true,
    });
    modal.present();

    let data = await modal.onDidDismiss();
  }

  async showPaymentPrompt(): Promise<void> {
    return new Promise<void>(async (resolve) => {

      let prompt = await this.alertController.create({
        header: this.languagePipe.transform('Kontaktuppgifter', 'CONTACT_INFORMATION', this.languageProvider.currentLanguage),
        message: this.languagePipe.transform('<p>Ni måste ange ett giltigt nummer för att kunna betala.</p> <p>Er order meddelas på SMS när den är klar.</p>', 'SWISH_DEMAND_NUMBER', this.languageProvider.currentLanguage),
        buttons: [
          {
            text: this.languagePipe.transform("OK", 'OK', this.languageProvider.currentLanguage),
            role: "confirm",
            handler: ((event) => {
              resolve();
            })
          },
        ],
      });

      prompt.present();
    });
  }

  useStoredPaymentOption(option: UserPaymentSource) {
    this.orderProvider.paymentOfChoice = option;
  }

  async addUserToOrder() {
    let order = this.orderProvider.orderWaitingForApproval;

    let firstName = '';
    let userLastName = '';
    if (this.userName) {
      this.split = this.userName.split(' ');
      order.userName = this.userName;
    } else if (this.name) {
      this.split = this.name.split(' ');
      order.userName = this.name;
    }


    if (this.split && this.split.length >= 1)
      firstName = this.split[0];
    else
      firstName = this.name;

    if (this.split && this.split.length >= 2) {
      userLastName = typeof this.split[1] === 'undefined' ? '' : this.split[1];
    }

    if (this.user) {
      if (this.user.firstName && this.user.lastName || this.user.firstName && this.user.lastName === '') {
        this.name = `${this.user.firstName} ${this.user.lastName}`;
      }
    }

    this.anonUserFirstSecondName = await this.userProvider.updateUser({
      firstName: firstName,
      lastName: userLastName,
      id: this.user.id,
      phoneNumber: this.mobileNumber
    });

    order.userName = firstName + ' ' + userLastName;
    order.phoneNumber = this.mobileNumber;
  }

  async forward() {

    await this.addUserToOrder()

    this.navCtrl.navigateForward([`u/${this.routeName}/charge`]);
  }

  /***********************************************************/
  /*
  >======>         >>       >=>      >=>       >=>        >=> >=> >===>>=====> >=>    >=> 
  >=>    >=>      >>=>       >=>    >=>        >=>        >=> >=>      >=>     >=>    >=> 
  >=>    >=>     >> >=>       >=> >=>          >=>   >>   >=> >=>      >=>     >=>    >=> 
  >======>      >=>  >=>        >=>            >=>  >=>   >=> >=>      >=>     >=====>>=> 
  >=>          >=====>>=>       >=>            >=> >> >=> >=> >=>      >=>     >=>    >=> 
  >=>         >=>      >=>      >=>            >> >>    >===> >=>      >=>     >=>    >=> 
  >=>        >=>        >=>     >=>            >=>        >=> >=>      >=>     >=>    >=> 
                                                                                          
  >=>         >=> >=> >======>   >======>     >=>>=>                                      
   >=>       >=>  >=> >=>    >=> >=>    >=> >=>    >=>                                    
    >=>     >=>   >=> >=>    >=> >=>    >=>  >=>                                          
     >=>   >=>    >=> >======>   >======>      >=>                                        
      >=> >=>     >=> >=>        >=>              >=>                                     
       >===>      >=> >=>        >=>        >=>    >=>                                    
        >=>       >=> >=>        >=>          >=>>=>    
  */
  async payWithVippsV2() {

    this.eventsProvider.logEvent("USER_STARTING_TO_PAY_WITH_VIPPS", { user: this.user });

    await this.addUserToOrder()
    this.loaderCtrl.create()

    var vippsPaymentRef: UserPaymentSource = {
      nickname: "vipps",
      recurringReference: null,
      type: "vipps"
    };
    this.orderProvider.paymentOfChoice = vippsPaymentRef;

    let vippsPay = await this.modalController.create({
      component: VippsDialogComponent,
      componentProps: {
        order: this.orderProvider.orderWaitingForApproval,
        user: this.user,
        routeName: this.routeName
      },
      backdropDismiss: false
    });
    vippsPay.present();
    let result = await vippsPay.onDidDismiss();

    const loader = await this.loaderCtrl.create({});
    loader.present();

    if (!result.data || result.role != "success") {
      this.cartProvider.updateIdempotencyKey();
      loader.dismiss();
      return
    }

    this.eventsProvider.logEvent("USER_PAYED_WITH_VIPPS", { user: this.user, vippsPaymentRef: vippsPaymentRef });
    this.orderProvider.orderWaitingForApproval = null;
    this.orderProvider.paymentOfChoice = null;
    this.orderProvider.activatedOfferIds = [];
    this.cartProvider.empty();

    // Go directly to the active order page.
    this.routerOutlet.swipeGesture = false;
    this.navCtrl.navigateBack(`u/${this.routeName}`, {
      // relativeTo: this.activatedRoute,
      queryParams: {
        showActiveOrder: true
      }
    });
    loader.dismiss();

    //this.forward();
  }
  async payWithVipps() {
    this.eventsProvider.logEvent("USER_STARTING_TO_PAY_WITH_VIPPS", { user: this.user });
    var vipps: UserPaymentSource = {
      nickname: "vipps",
      recurringReference: null,
      type: "vipps",
    };
    this.orderProvider.paymentOfChoice = vipps;
    //this.eventsProvider.logEvent("USER_PAYED_WITH_VIPPS", { user: this.user });
    this.forward();
  }

  async verifyPaymentWithVipps() {
    this.eventsProvider.logEvent("USER_STARTING_TO_VERIFY_PAYMENT_WITH_VIPPS", { user: this.user });
    var vipps: UserPaymentSource = {
      nickname: "vipps_verify",
      recurringReference: null,
      type: "vipps_verify",
    };
    this.orderProvider.paymentOfChoice = vipps;
    this.eventsProvider.logEvent("USER_PAYED_WITH_VIPPS", { user: this.user });
    this.forward();
  }

  async payWithSwish() {

    this.eventsProvider.logEvent("USER_STARTING_TO_PAY_WITH_SWISH", { user: this.user });

    await this.addUserToOrder()
    this.loaderCtrl.create()

    var swishPaymentRef: UserPaymentSource = {
      nickname: "swish",
      recurringReference: null,
      type: "swish"
    };
    this.orderProvider.paymentOfChoice = swishPaymentRef;

    let swishPay = await this.modalController.create({
      component: SwishDialogComponent,
      componentProps: {
        order: this.orderProvider.orderWaitingForApproval,
        user: this.user,
        routeName: this.routeName
      },
      backdropDismiss: false
    });
    swishPay.present();
    let result = await swishPay.onDidDismiss();

    const loader = await this.loaderCtrl.create({});
    loader.present();

    if (!result.data || result.role != "success") {
      this.cartProvider.updateIdempotencyKey();
      loader.dismiss();
      return
    }

    this.eventsProvider.logEvent("USER_PAYED_WITH_SWISH", { user: this.user, swishPaymentRef: swishPaymentRef });
    this.orderProvider.orderWaitingForApproval = null;
    this.orderProvider.paymentOfChoice = null;
    this.orderProvider.activatedOfferIds = [];
    this.cartProvider.empty();

    // Go directly to the active order page.
    this.routerOutlet.swipeGesture = false;
    this.navCtrl.navigateBack(`u/${this.routeName}`, {
      // relativeTo: this.activatedRoute,
      queryParams: {
        showActiveOrder: true
      }
    });
    loader.dismiss();

    //this.forward();
  }

  async verifyPaymentWithSwish() {
    this.eventsProvider.logEvent("USER_STARTING_TO_VERIFY_PAYMENT_WITH_SWISH", { user: this.user });
    var swish: UserPaymentSource = {
      nickname: "swish_verify",
      recurringReference: null,
      type: "swish_verify",
    };
    this.orderProvider.paymentOfChoice = swish;
    this.eventsProvider.logEvent("USER_PAYED_WITH_SWISH", { user: this.user });
    this.forward();
  }

  /*
  >======>     >=======> >=======> >======>         >>       >=>      >=> 
  >=>    >=>   >=>       >=>       >=>    >=>      >>=>       >=>    >=>  
  >=>    >=>   >=>       >=>       >=>    >=>     >> >=>       >=> >=>    
  >> >==>      >=====>   >=====>   >======>      >=>  >=>        >=>      
  >=>  >=>     >=>       >=>       >=>          >=====>>=>       >=>      
  >=>    >=>   >=>       >=>       >=>         >=>      >=>      >=>      
  >=>      >=> >=======> >=======> >=>        >=>        >=>     >=>      
  */
  async payWithReepayNewCard() {

    this.eventsProvider.logEvent("USER_STARTING_TO_PAY_WITH_ADD_CARD", { user: this.user });

    let merchantConfig = this.contextProvider.clientContext.paymentGateway.value;
    let pubqConfig = this.pubqConfig.config.value;

    let gateway = new ReepayGateway(this.http, pubqConfig, merchantConfig, this.loaderCtrl, this.modalCtrl, this.cardPaymentProvider, this.orderProvider, this.cartProvider, this.userProvider);

    // Will show a modal with Reepay component
    await gateway.pay_embedded({
      cardOnFile: "",
      order: this.orderProvider.orderWaitingForApproval,
      routeName: this.contextProvider.client.routeName,
      userId: this.user.id,
      orderText: "",
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email ? this.user.email : null,
      locale: this.languageProvider.currentLanguage,
    })
      .then(async (payResult) => {
        if (payResult.error) {
          this.handlePaymentResponse(payResult);
          return;
        }
        let orderId = payResult.invoice;
        let recurringReference = payResult.payment_method;
        let originalMerchant = this.contextProvider.client.routeName;
        // let alert = await this.alertCtrl.create({
        //   header: this.languagePipe.transform("Spara ditt kort?", 'SAVE_CARD_QUESTION_HEADER', this.languageProvider.currentLanguage),
        //   subHeader: '',
        //   message: this.languagePipe.transform("Lägg till kort för framtida köp.", 'SAVE_CARD_QUESTION', this.languageProvider.currentLanguage),
        //   buttons: [{ role: "cancel", text: this.languagePipe.transform("Cancel", 'CANCEL', this.languageProvider.currentLanguage) }, { role: "confirm", text: this.languagePipe.transform("OK", 'OK', this.languageProvider.currentLanguage) }]
        // });
        // alert.present();
        // let result = await alert.onDidDismiss();
        // let saveCard = false;

        // if (result.role === "confirm")
        // {
        //   this.eventsProvider.logEvent("USER_SAVED_CARD",{user: this.user, orderId: orderId});
        //   saveCard = true;
        // }
        this.orderProvider.orderWaitingForApproval.id = orderId;
        this.orderProvider.reepayData = { orderId, recurringReference, originalMerchant, saveCard: false };
        this.orderProvider.paymentOfChoice = <UserPaymentSource>{ recurringReference, nickname: "TBD", type: "reepay" };
        this.eventsProvider.logEvent("USER_PAYED_WITH_ADD_CARD", { user: this.user, orderId: orderId, savedCard: false });
        this.forward();
      })
      // If user cancelled payment
      .catch(() => {
        this.eventsProvider.logEvent("USER_CANCEL_TO_PAY_WITH_ADD_CARD", { user: this.user });
      })
      .finally(() => {
        return;
      });

  }

  // Obsolete do not use -> use payWithReepayCharge
  // IS USE IF 3D SECURE CONTROL IS NEEDED!
  async payWithReepayCardOnFile() {
    this.eventsProvider.logEvent("USER_STARTING_TO_PAY_WITH_SAVED_CARD", { user: this.user });

    let merchantConfig = this.contextProvider.clientContext.paymentGateway.value;
    let pubqConfig = this.pubqConfig.config.value;

    let gateway = new ReepayGateway(this.http, pubqConfig, merchantConfig, this.loaderCtrl, this.modalCtrl, this.cardPaymentProvider, this.orderProvider, this.cartProvider, this.userProvider);
    const paymentSource = this.orderProvider.paymentOfChoice;

    await gateway.pay_embedded({
      cardOnFile: paymentSource.recurringReference,
      order: this.orderProvider.orderWaitingForApproval,
      routeName: this.contextProvider.client.routeName,
      userId: this.user.id,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      orderText: this.contextProvider.client.name,
      email: this.user.email ? this.user.email : null,
      locale: this.languageProvider.currentLanguage,
    })
      .then(async (payResult) => {
        if (payResult.error) {
          this.handlePaymentResponse(payResult);
          return;
        }

        let orderId = payResult.invoice;
        let recurringReference = paymentSource.recurringReference;
        let originalMerchant = this.contextProvider.client.routeName;
        this.orderProvider.orderWaitingForApproval.id = orderId;
        this.orderProvider.reepayData = { orderId, recurringReference, originalMerchant, saveCard: false };
        this.eventsProvider.logEvent("USER_PAYED_WITH_SAVED_CARD", { user: this.user, orderId: orderId });
        this.forward();
      })
      // If user cancelled payment
      .catch(() => {
        this.eventsProvider.logEvent("USER_CANCEL_TO_PAY_WITH_SAVED_CARD", { user: this.user });
      })
      .finally(() => {
        return;
      });
  }

  async handlePaymentResponse(x: PaymentV2Response) {
    try {

      if (x.error || x.success !== true) {
        if (x.missing) {
          let modal = await this.modalController.create({
            component: StockModalComponent,
            componentProps: { routeName: this.contextProvider.client.routeName, data: x.missing, updatedCart: x.updatedCart },
            cssClass: 'stock-modal'
          });
          modal.present();
          await modal.onDidDismiss();
        } else {
          this.cartProvider.updateIdempotencyKey();
          this.showErrorPromptWithHeader(
            this.languagePipe.transform('BETALNINGSFEL', 'PAYMENT_ERROR_HEADER', this.languageProvider.currentLanguage),
            x.error.showResponseText ? x.error.responseText : this.languagePipe.transform(x.error.responseText, x.error.languageCode, this.languageProvider.currentLanguage)
          );
          if (x.error.resetOrder) {
            this.resetOrderFlow();
            this.goToMenu();
          }
        }
      }
    } catch (error) {
      this.cartProvider.updateIdempotencyKey();
    }
  }

  async showErrorPromptWithHeader(header, errorMsg): Promise<void> {
    return new Promise<void>(async (resolve) => {

      let prompt = await this.alertController.create({
        header: header,
        message: errorMsg,
        buttons: [
          {
            text: this.languagePipe.transform("OK", 'OK', this.languageProvider.currentLanguage),
            role: "confirm",
            handler: ((event) => {
              resolve();
            })
          },
        ],
      });

      prompt.present();
    });
  }

  async payWithReepayCharge() {
    this.eventsProvider.logEvent("USER_STARTING_TO_PAY_WITH_SAVED_CARD", { user: this.user });

    let orderId = 'N/A';
    const paymentSource = this.orderProvider.paymentOfChoice;
    let recurringReference = paymentSource.recurringReference;
    let originalMerchant = this.contextProvider.client.routeName;

    this.orderProvider.reepayData = { orderId, recurringReference, originalMerchant, saveCard: false };
    this.eventsProvider.logEvent("USER_PAYED_WITH_SAVED_CARD", { user: this.user });
    this.forward();
  }

  async payWithCard(paymentProvider: "stripe", options: any[]) {
    this.eventsProvider.logEvent("USER_STARTING_TO_PAY_WITH_CARD", { user: this.user });

    let cardPay = await this.modalController.create({
      component: CardDialogComponent,
      componentProps: {
        provider: paymentProvider,
        order: this.orderProvider.orderWaitingForApproval,
        user: this.user,
        routeName: this.routeName,
        options: options
      },
      backdropDismiss: false
    });
    cardPay.present();
    let result = await cardPay.onDidDismiss();


    if (!result.data || result.role != "success") {
      this.cartProvider.updateIdempotencyKey();
      // loader.dismiss();
      return
    }

    //this.eventsProvider.logEvent("USER_PAYED_WITH_SWISH", { user: this.user, swishPaymentRef: swishPaymentRef });
    this.orderProvider.orderWaitingForApproval = null;
    this.orderProvider.paymentOfChoice = null;
    this.orderProvider.activatedOfferIds = [];
    this.cartProvider.empty();

    // Go directly to the active order page.
    this.routerOutlet.swipeGesture = false;
    this.navCtrl.navigateBack(`restaurant/${this.routeName}`, {
      // relativeTo: this.activatedRoute,
      queryParams: {
        showActiveOrder: true
      }
    });
    //loader.dismiss();
  }

  async payWithExistingCard(paymentProvider: "stripe", option: UserPaymentSource) {
    this.eventsProvider.logEvent("USER_PAYING_WITH_STORED_CARD", { user: this.user, paymentProvider, orderId: this.cartProvider.orderId });
    this.orderProvider.paymentOfChoice = option;
    this.forward();
  }
  async payWithNewCard(paymentProvider: "stripe") {
    this.eventsProvider.logEvent("USER_STARTING_TO_ADD_CARD", { user: this.user, paymentProvider, orderId: this.cartProvider.orderId });
    var cardIntent: UserPaymentSource = {
      nickname: "newcard this should not be needed lets remove this in the future, ok?",
      recurringReference: null,
      type: "newcard",
    };
    this.orderProvider.paymentOfChoice = cardIntent;
    this.forward();
  }

  async verifyPaymentWithStripe() {
    this.eventsProvider.logEvent("USER_STARTING_TO_VERIFY_PAYMENT_WITH_STRIPE", { user: this.user });
    var stripe: UserPaymentSource = {
      nickname: "stripe_verify",
      recurringReference: null,
      type: "stripe_verify",
    };
    this.orderProvider.paymentOfChoice = stripe;
    this.forward();
  }

  getTableNr(order) {
    if (!order) return 'N/A';

    if (!order.tableNumber) return 'N/A';

    return order.tableNumber;
  }

  getServingOption(delivery: DeliveryPlaceProvider) {
    if (!delivery) return 'Välj ett giltigt leveranssätt'
    if (!delivery.choseVariant) return 'Välj ett giltigt leveranssätt'
    return delivery.choseVariant;
  }

  isTableServing(delivery: DeliveryPlaceProvider) {
    if (!delivery) return false;
    if (!delivery.tablesText || !delivery.choseVariant) return false;
    if (delivery.tablesText === delivery.choseVariant) return true
    else return false
  }
}




// @Component({
//   selector: 'cart-welcome',
//   templateUrl: 'cart-welcome.html',
//   styleUrls: ['cart.scss'],
// })
// export class CartWelcome {
//   @Input() clientName;
//   newsItems = [];
//   loading = true;
//   subscriptions: Subscription = new Subscription();
//   toggleItemIndex = 999;
//   appInfo;
//   sliderOpts = { spaceBetween: -40 };
//   clientInfo: ClientInfo;
//   aboutUs = null;
//   menu;
//   @ViewChild('newsSlider') slides: IonSlides;
//   constructor(
//     private navCtrl: NavController,
//     private contextProvider: ContextProvider,
//     // private inAppBrowser: InAppBrowser,
//     private modalController: ModalController,
//     private routerOutlet: IonRouterOutlet,
//     public languageProvider: LanguageProvider,
//     private languagePipe: LanguagePipe,
//     @Inject(DOCUMENT) private ngDocument
//   ) { }
//   // async showOfferInfo(newsItem) {
//   //   const modal = await this.modalController.create({
//   //     component: NewsItemDetailsComponent,
//   //     componentProps: { newsItem },
//   //     swipeToClose: true,
//   //     presentingElement: this.routerOutlet.nativeEl
//   //   });
//   //   modal.present();
//   //   const result = await modal.onDidDismiss();
//   //   if (result.role === 'confirm') {
//   //     const tab = newsItem.displayProduct.tab === 'food' ? 'tab2' : 'tab3';
//   //     this.navCtrl.navigateForward(
//   //       [`main/tabs/${tab}/${newsItem.displayProduct.tab}`],
//   //       { queryParams: { product: newsItem.displayProduct.productId } }
//   //     );
//   //   }
//   // }
//   ngOnInit() {
//     this.clientInfo = this.contextProvider.client;
//     this.subscriptions.add(
//       this.contextProvider.clientContext.appInfo.subscribe((appInfo) => {
//         if (!appInfo || !appInfo.Context.aboutUsDescription) {
//           this.aboutUs = null;
//           return;
//         }
//         this.aboutUs = appInfo.Context.aboutUsDescription;
//       })
//     );
//     this.subscriptions.add(
//       combineLatest([
//         this.contextProvider.clientContext.news,
//         this.contextProvider.clientContext.menu
//       ]
//       ).subscribe(async ([news, menu]) => {
//         this.menu = menu;
//         this.newsItems = [...news];
//         this.newsItems.sort((a, b) => {
//           if (typeof (a.index) === 'undefined' || typeof (b.index) === 'undefined') {
//             return -1;
//           }
//           return a.index - b.index;
//         });
//         this.newsItems = this.newsItems.splice(0, 8);
//         this.newsItems.forEach((x, i) => {
//           this.loadAndApplyImage(x);
//         });
//       })
//     );
//     this.subscriptions.add(
//       this.contextProvider.clientContext.appInfo.subscribe((x) => {
//         if (x && x.style && (x.style.facebookLink || x.style.instagramLink)) {
//           this.appInfo = x;
//         }
//       })
//     );
//   }
//   showSocialMediaHeadline() {
//     return (
//       this.appInfo &&
//       this.appInfo.style &&
//       (this.appInfo.style.facebookLink.shown ||
//         this.appInfo.style.instagramLink.shown)
//     );
//   }
//   openSocialMediaLink(url) {
//     this.contextProvider.launchExternalApp(url, '');
//   }
//   loadAndApplyImage(news) {
//     const image = new Image();
//     image.src = news.imageUrl;
//     image.onload = (x) => {
//       news.styling = {
//         'background-image': `url('${news.imageUrl}')`,
//         'background-color': 'transparent',
//         'background-size': 'cover',
//         'background-position': 'center',
//         'background-repeat': 'no-repeat',
//         animation: 'hd-quality 0.3s',
//         'animation-delay': '0.5s',
//         'animation-fill-mode': 'forwards',
//       };
//       this.loading = false;
//     };
//   }
//   update() {
//     this.slides.update();
//   }

//   ngOnDestroy() {
//     this.subscriptions.unsubscribe();
//   }
//   // openNews(newsItem) {
//   //   switch (newsItem.type) {
//   //     case 'url': {
//   //       const url = newsItem.url;
//   //       if (!url) { return; }
//   //       // const browser = this.inAppBrowser.create(url, '_blank', {
//   //       //   usewkwebview: 'yes',
//   //       //   location: 'no',
//   //       //   fullscreen: 'yes',
//   //       //   enableViewportScale: 'yes',
//   //       //   toolbar: 'yes',
//   //       //   hidenavigationbuttons: 'yes',
//   //       //   closebuttoncolor: '#8EA9F6',
//   //       //   closebuttoncaption: 'Stäng',
//   //       //   presentationstyle: 'fullscreen',
//   //       //   transitionstyle: 'crossdissolve',
//   //       //   toolbartranslucent: 'no',
//   //       //   toolbarposition: 'top',
//   //       // });
//   //       // browser.show();
//   //       window.open(url, '_blank');
//   //       break;
//   //     }
//   //     case 'product': {
//   //       const tab = newsItem.displayProduct.tab === 'food' ? 'tab2' : 'tab3';
//   //       this.navCtrl.navigateForward(
//   //         [`main/tabs/${tab}/${newsItem.displayProduct.tab}`],
//   //         { queryParams: { product: newsItem.displayProduct.productId } }
//   //       );
//   //       break;
//   //     }
//   //     case 'offer': {
//   //       this.showOfferInfo(newsItem);
//   //       break;
//   //     }
//   //   }
//   // }
//   newsItemLoaded(id) {
//     const s = this.ngDocument.getElementsByClassName(id);
//     for (let index = 0; index < s.length; index++) {
//       const element = s[index];
//       element.style.visibility = 'visible';
//       element.classList.add('fadeIn');
//     }
//   }
//   async showRestaurantInfo() {
//     const modal = await this.modalController.create({
//       component: ClientInfoComponent,
//       componentProps: {
//         appInfo: this.contextProvider.clientContext.appInfo.value,
//         clientInfo: this.contextProvider.clientContext.currentClient.value,
//       },
//       cssClass: 'modal-drawer',
//       swipeToClose: true,
//     });
//     modal.present();
//   }
// }
