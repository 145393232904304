import { Injectable } from '@angular/core';


@Injectable({ providedIn: "root" })
export class ModalOverseerProvider {
  public currentlyOpenModals = [];
  constructor() {

  }

}
