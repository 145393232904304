import { Injectable } from '@angular/core';
// import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
// import { AppLauncher } from '@ionic-native/app-launcher/ngx';

import { decode } from 'blurhash';
// import { Platform } from '@ionic/angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { Location } from '../location';
import { ThemeProvider } from '../theme/theme-provider.service';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { PubqConfig, PubqConfigProvider } from '../pubq-config/pubq-config';
import { Storage } from '@ionic/storage';


@Injectable({
  providedIn: 'root'
})
export class ContextProvider {
  client: ClientInfo;
  clientsCount = 0;
  clientId: string;
  clientContext = new ClientContext();
  menu;
  initDone = false;
  accessability = { hasAccessability: false };
  selectedKioskDevice;
  originalMenu;
  constructor(
    private db: AngularFireDatabase,
    private themeProvider: ThemeProvider,
    private titleService: Title,
    private http: HttpClient,
    private pubqConfig: PubqConfigProvider,
    private storage: Storage

  ) { }

  getClient() {
    return this.client;

  }
  toggleAccessability() {
    this.accessability.hasAccessability = !this.accessability.hasAccessability;
  }

  async init(routeName: string) {
    this.client = null;
    this.clientId = null;
    const clientRef = 'Clients/' + routeName;

    this.clientId = (await this.db.database.ref(clientRef + '/client').once('value')).val();

    if (this.clientId) {
      const clients = (await this.db.database.ref(`clientUnits/${this.clientId}/all`).once('value')).val();

      if (!clients && clients.length <= 0) {
        console.log("No defined clientUnits for " + routeName);
        return false
      }
      this.clientsCount = clients.length;
      let filterUnits = clients.filter(x => x.routeName == routeName);
      if (filterUnits.length > 0) {
        this.client = filterUnits[0];
      }
      if (!this.client) {
        console.log("clientUnits has no unit named " + routeName);
        return false;
      }
    }
    else {
      const restaurantInfo = (await this.db.database.ref(
        `adminApp/clients/${routeName}/info/public`).once('value')).val();
      this.client = {
        address: restaurantInfo.address,
        location: { latitude: 0, longitude: 0 },
        name: restaurantInfo.name,
        routeName,
        phoneNumber: restaurantInfo.phoneNumber
      }
    }


    try {
      this.titleService.setTitle(`${this.client.name} order`);
    } catch (error) {
      console.error(error.message);
    }

    // Get styles
    await this.themeProvider.init(routeName);


    this.db
      .object(clientRef + '/AppInfo')
      .valueChanges()
      .subscribe(async (x: AppInfo) => {
        if (!x) return;

        const openingHours = (await this.db.database.ref(
          `adminApp/clients/${routeName}/settings/openingHours`).once('value')).val();
        x.Context.openingHours = openingHours;
        if (!this.client.location) {
          this.client.location = x.Context.location;
        }
        const restaurantInfo = (await this.db.database.ref(
          `adminApp/clients/${routeName}/info/public`).once('value')).val();
        x.Context.restaurantInfo = restaurantInfo;
        this.clientContext.appInfo.next(x);
      });
    this.db
      .list(clientRef + '/activeMenu/categories')
      .valueChanges()
      .subscribe((x: [{ items: any[] }]) => {
        this.originalMenu = JSON.parse(JSON.stringify(x));
        x.forEach(category => {
          if (category.items) {
            category.items.forEach(item => {
              if (item.blurHash) {
                const height = 8;
                const width = 8;
                const pixels = decode(item.blurHash, width, height);
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                const imageData = ctx.createImageData(width, height);
                imageData.data.set(pixels);

                ctx.putImageData(imageData, 0, 0);
                item.sdStyling = {
                  'background-image': `url(${canvas.toDataURL()})`,
                  'background-size': 'cover',
                  'background-repeat': 'no-repeat'
                };

              } else {
                item.sdStyling = {
                  'background-image': 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAA7UlEQVQoUwXBvU6DUBiA4fcgVWNSGbwAJ+P1ODt7A96Co5txcWhi4sgVaGLUxdXBxZ/Y1gSxlMIBD3D47efzKHV4JLubA/s7NWPXMDQ5y1Qzm2mgQR2fnMrGumfUlIjVFOWKhUl405Y4EdTk5lqoG9ZJShWFxFlIYDULO1AXDur+6VakLGmDgHz+RZj+EEiHHlxU5aAeHu9kMDn19J3044XfvxXR9h7VaIzbCMr3fRlMhp2+kn0+E9c9qXdAu+Xhti3q4nIiYgu6aE4VfZN1a/TIw4hLV1aos/MrcfoOxxb01mBqy9I2BHlFGBv+AbCTmBgUfFQ3AAAAAElFTkSuQmCC)',
                  'background-size': 'cover',
                  'background-repeat': 'no-repeat'
                };
              }
            });
          }
        });
        this.clientContext.menu.next(x);
      });

    const paymentGateway = (await this.db.database.ref(`adminApp/clients/${routeName}/settings/paymentGateway`).once('value')).val();
    this.clientContext.paymentGateway.next(paymentGateway);

    this.clientContext.currentClient.next(this.client);

    this.initDone = true;

    return true;
  }
  filterCurrentMenu(options: { tableNumber: any, app: "kiosk" | "web" }) {
    if (options.tableNumber > 0)
      options.tableNumber = "tableOrder";
    let currentMenu = JSON.parse(JSON.stringify(this.originalMenu));
    if (!currentMenu) return;
    let categoriesToSplice = [];
    for (let categoryIndex = 0; categoryIndex < currentMenu.length; categoryIndex++) {
      const category = currentMenu[categoryIndex];
      let filteredItems = [];
      if (category.items) {
        for (let itemIndex = 0; itemIndex < category.items.length; itemIndex++) {
          const item = category.items[itemIndex];
          let shouldAddItem = false;
          if (item.displayConfig) {
            if (item.displayConfig[options.app] && item.displayConfig[options.tableNumber])
              shouldAddItem = true;
          }
          else
            filteredItems.push(item);
          if (item.priceConfig) {
            if (item.priceConfig[options.tableNumber])
              item.Cost = item.priceConfig[options.tableNumber];
            else
              item.Cost = item.basePrice;
          }
          else {

          }
          shouldAddItem && filteredItems.push(item);
        }

        category.items = filteredItems;
      }
      if (filteredItems.length === 0)
        categoriesToSplice.push(categoryIndex);
    }
    categoriesToSplice.forEach(x => {
      currentMenu.splice(x, 1);
    });
    this.clientContext.menu.next(currentMenu);
  }
}

export interface IMerchantConfig {
  terminalId: string;
  secret: string;
  merchantRef: string;
  hostedPaymentPageUrl: string;
  hostedPreAuthPageUrl: string;
  acceptUrl: string;
  useXml: boolean;
  mpi?: boolean;
  mpiAmount?: number;
}
export class DeliveryOption {
  name = '';
  fee = 0;
  minValue = "0";
  maxValue = "0";
}
export class ClientContext {
  appInfo = new BehaviorSubject<AppInfo>(null);
  menu = new BehaviorSubject<[any]>(null);
  theme = new BehaviorSubject<[any]>(null);
  news = new BehaviorSubject<any[]>([]);
  paymentGateway = new BehaviorSubject<IMerchantConfig>(null);
  currentClient = new BehaviorSubject<ClientInfo>(null);
}
export class AppInfo {
  Context: Context;
  MaxArticles: number;
  MomsRegNr: string;
  style: { menuCards: boolean, facebookLink: { url: string, shown: boolean }, instagramLink: { url: string, shown: boolean } };
  appName: string;
}
export class Context {
  menuStyle: "list" | "cards";
  paymentProvider: "reepay" | "worldnet";
  currencyToUse?: string;
  aboutUsDescription?: string;
  showItemsComment: boolean;
  showCustomerComment: boolean;
  deactivateAnonymousLogin: boolean;
  policyUrl: string;
  defaultLanguage: string;
  allergyInfo: { phone: string, open: string, close: string };
  drinksTab: { enabled: boolean, hidden: boolean };
  foodTab: { enabled: boolean, hidden: boolean };
  customTickets: boolean;
  weeklyMenu: { enabled: boolean, content: any[] };
  globalOffersEnabled: boolean;
  stockBalanceEnabled: boolean;
  languageHandlingEnabled: boolean;
  minimumBonusVoucherAmount: number;
  tabKeyAcceptsText: boolean;
  Tables: {
    LowerValue: string;
    UpperValue: string;
    fee: number;
    enabled: boolean;
    icon?: string;
    text?: string;
    numberText?: string
  };
  advancedWeeklyMenu?: boolean;
  restaurantPhoneNumber: string;
  restaurantEmail: string;
  restaurantInfo: any;
  openingHours: { weekdays: any[], deviations: any[] };
  orderByCustomerTabKey: boolean;
  homeDelivery: { enabled: boolean, text?: string, radiuses: any[] };
  takeawayDelivery: { enabled: boolean, icon?: string, text?: string };
  sitAtRestaurantDelivery: { enabled: boolean, icon?: string; text?: string; minimumFee?: { enabled: boolean, value: number } };
  deliverySetLocations: { enabled: boolean, icon?: string; text?: string; minimumFee: number; locations: [] };
  drinks: { isOpen: boolean; closedComment: string };
  food: { isOpen: boolean; closedComment: string };
  estimatedWaitingTimeText: string;
  showEstimatedWaitingTime: boolean;
  useDefaultCoupon: boolean;
  defaultCouponCode: string;
  paymentOptions: {
    kioskCard: PaymentOption;
    kioskSwish: PaymentOption;
    card: PaymentOption;
    swish: PaymentOption;
    customerTab: PaymentOption;
    vipps: PaymentOption;
  };
  tableBooking: { enabled: boolean };
  signInMethods: {
    ios: { apple: boolean, facebook: boolean, email: boolean, phone: boolean },
    android: { apple: boolean, facebook: boolean, email: boolean, phone: boolean }
  };
  deliveryDate: { enabled: boolean };
  couponCode: { enabled: boolean };
  location: any;
  receiptOnly: boolean;
  nameBurgerDisabled: boolean;
  customerCommentLabelText: string;
  customerCommentRequired: boolean;
  maxAlcoholItemsPerOrder: number;
  navigateToHomepage?: {
    enabled: boolean,
    url: string
  }
  generalInfo?: { address: string, city: string, companyName: string, email: string, logoUrl: string, name: string, orgNo: string, phoneNumber: string, zipCode: string };
}
export class PaymentOption {
  enabled: boolean;
  id: string;
}
export class ClientInfo {
  name: string;
  routeName: string;
  address: string;
  phoneNumber: string;
  location: Location;
  imageUrl?: string;
  distanceInKm?: number;
}
export class ClientContextData {
  user: any;
  appVersion: string;
  appProtocol: string;
  locale: string;
  origin;

}
