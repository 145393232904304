import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ClientInfo} from './context/context';
import {AppConfiguration} from '../app.configuration';
import {HttpClient} from '@angular/common/http';
import {AngularFireDatabase} from '@angular/fire/database';
import {LocationSorter} from './locationSorter';
// import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Location } from './location';
import { AnalyticsService } from './analytics/analytics';

@Injectable({
    providedIn: 'root'
})
export class UnitProvider {
    public units = new BehaviorSubject<ClientInfo[]>(null);
    public unitsCount = 0;
    private clientRoute = AppConfiguration.clientRoute;
    clientName = '';
    client: ClientInfo;

    constructor(
      public http: HttpClient,
      private db: AngularFireDatabase,
      private locationSorter: LocationSorter,
      private analyticsService: AnalyticsService
    ) {
    }

    getUnits(clientRoute:string, cityRoute: string): BehaviorSubject<ClientInfo[]> {
      const subscription = this.db.list('clientUnits/' + clientRoute + '/' + cityRoute)
        .valueChanges()
        .subscribe((data: ClientInfo[]) => {
          subscription.unsubscribe();
          this.unitsCount = data.length;
          this.units.next(data);
        });
      return this.units;
    }

    // getUnit(clientRoute:string, cityRoute: string, routeName: string): BehaviorSubject<ClientInfo>{
    //   const subscription = this.db.list('clientUnits/' + clientRoute + '/' + cityRoute)
    //     .valueChanges()
    //     .subscribe((data: ClientInfo[]) => {
    //       subscription.unsubscribe();
    //       this.units.next(data);
    //     });
    //   let unit = 
    //   return this.units;
    // }

    async sortUnitsByProximity(units: any[]): Promise<any> {
        // return this.geolocation
        //     .getCurrentPosition({ enableHighAccuracy: false, timeout: 10000, maximumAge: 20000 })
        //     .then((resp) => {
        //         let userLocation = new Location(resp.coords.latitude, resp.coords.longitude);
        //         units = this.locationSorter.sortUnits(userLocation, units);
        //         return units;
        //     })
        //     .catch((error) => {
        //         console.log('Error getting location', error);
        //         return units;
        //     })
        //     .then(() => {
        //         return units;
        //     });
      return new Promise(resolve => {
        navigator.geolocation.getCurrentPosition((position) => {
          const userLocation = new Location(position.coords.latitude, position.coords.longitude);
          units = this.locationSorter.sortCities(userLocation, units);
          resolve(units);
        });
      }).catch(() => {
          this.analyticsService.trackEvent('LOC_DENY');
      });
    }

    getClientInfo(client) {
      if (client) {
        this.clientName = client;
      }
    }
}
