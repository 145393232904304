import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { LanguageProvider } from "src/app/services/language/language.provider";


@Component({
    templateUrl: "paymentStatus.component.html",
    styleUrls: ["paymentStatus.component.scss"]

})
export class PaymentStatusComponent {
    unitName = "";
    status = "";
    constructor(private activatedRoute: ActivatedRoute, private http: HttpClient, private router: Router, public languageProvider: LanguageProvider,) {

    }
    ngOnInit() {
        this.status = this.activatedRoute.snapshot.params.status;
        let app = decodeURIComponent(this.activatedRoute.snapshot.queryParams.app);
        this.unitName = decodeURIComponent(this.activatedRoute.snapshot.queryParams.u);
        if (app !== "undefined")
            window.location.href = `${app}order`;
    }
}
