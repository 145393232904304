import { Component, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LanguageProvider } from 'src/app/services/language/language.provider';
import { UserProvider, User } from 'src/app/services/user/user';
@Component({
  templateUrl: 'additionalaccountinfoform.html',
  styleUrls: ['login.scss'],
  selector: 'page-additional-info',
})
export class AdditionalAccountInfoForm {
  user: User;
  name = '';
  @ViewChild('nameInputField') nameInputField;

  constructor(
    private modalCtrl: ModalController,
    private userProvider: UserProvider,
    public languageProvider: LanguageProvider
  ) {
    this.user = this.userProvider.currentUser.value;
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.user.firstName && !this.user.lastName) {
        this.name = this.user.firstName;
      } else if (this.user.firstName && this.user.lastName) {
        this.name = `${this.user.firstName} ${this.user.lastName}`;
      }
      this.nameInputField.setFocus();
    }, 700);
  }
  ok() {
    const split = this.name.split(' ');
    const firstName = split[0];
    let lastName = split[1];
    if (!lastName) { lastName = 'ZZ'; }
    this.user.firstName = firstName.trim();
    this.user.lastName = lastName.trim();
    this.modalCtrl.dismiss(this.user);
  }
  nameValid() {
    return this.name.length > 3;
  }
}
