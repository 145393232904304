import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, keys: string[]): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      let hit = false;
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        hit = it[key].toLowerCase().includes(searchText);
        if (hit)
          return hit;
      }
      return hit;

    });
  }
}