import { Injectable } from '@angular/core';

import { ContextProvider } from '../context/context';
import { PubqConfig, PubqConfigProvider } from '../pubq-config/pubq-config';
import { HttpClient } from '@angular/common/http';
import { PaymentV2Response } from './payment';
import { Subscription } from 'rxjs';
import { Order } from '../order/order';
import { PaymentUtils } from './paymentUtils';
import { environment } from 'src/environments/environment';

let appSettings = require("../../../../cordova-scripts/appsettings");

@Injectable({
    providedIn: "root"
})
export class SwishPaymentProvider {
    appBuild = "";
    config: PubqConfig;
    sub: Subscription;

    constructor(
        private contextProvider: ContextProvider,
        private pubqConfigProvider: PubqConfigProvider,
        private http: HttpClient,
        private paymentUtils: PaymentUtils
    ) {

        this.appBuild = appSettings && appSettings.version ? appSettings.version : 'N/A';

        this.sub = this.pubqConfigProvider.config.subscribe((x: PubqConfig) => {
            if (!x) return;
            this.config = x;
        });
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe();
    }

    async initiate(options: { user: any, clientContextData: any, paymentSource: any, order: Order }, idempotencyKey: string, orderId: string): Promise<PaymentV2Response> {
        let receiptOnly = false;
        try {
            receiptOnly = this.contextProvider.clientContext.appInfo.value.Context.receiptOnly;
        } catch (error) {
            receiptOnly = false;
        }

        options.order.id = orderId;

        const providerData = {
            UserId: options.clientContextData.user.id,
            userId: options.clientContextData.user.id
            //mobileNumber: options.order.phoneNumber
        }

        //testPayment needs to be removed (set to false!) when backend is ready
        const body = {
            idempotencyKey: idempotencyKey,
            userPaymentSource: options.paymentSource,
            order: options.order,
            paymentType: options.paymentSource.type,
            appVersion: this.appBuild && this.appBuild !== '' ? this.appBuild : 'N/A',
            routeName: options.order.client.routeName,
            receiptOnly,
            providerData,
            testPayment: false
        };

        // let url = `${this.config.ApiUrl}/payments/v1/idempotency/initiate`;
        // let resp = await this.http.post<PaymentV2Response>(url, body).toPromise();
        // return resp;
        return this.paymentUtils.initiatePaymentWithRetry(body, this.config, this.http);
    }

    async charge(idempotencyKey: string, routeName: string): Promise<PaymentV2Response> {

        const body = {
            idempotencyKey: idempotencyKey,
            routeName: routeName,
        };

        let url = `${this.config.ApiUrl}/payments/v1/idempotency/charge`;
        let resp = await this.http.post<PaymentV2Response>(url, body).toPromise();
        return resp;
    }

    private delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async verify(idempotencyKey: string, routeName: string, retry?: number): Promise<PaymentV2Response> {

        return this.paymentUtils.verifyPaymentWithRetry(idempotencyKey, routeName, this.config, this.http, retry);
    }
    abortVerify() {
        this.paymentUtils.cancelRetrying();
    }
    async getQrCode(token): Promise<any> {
        return new Promise<any>(async (resolve) => {

            try {
                const body = {
                    token,
                    format: 'png',
                    size: 300
                };

                let url = `${environment.apiUrl}/v1/swish/qr`;
                let image = await this.http.post(url, body, { responseType: 'blob' }).toPromise();

                // with file reader you will transform the file in a data url file;
                var reader = new FileReader();
                reader.readAsDataURL(image);

                reader.onloadend = () => {
                    // just putting the data url to img element
                    resolve(reader.result as string);
                }
            } catch (err) {
                resolve(null);
            }
        })
    }
}
