import { Component, Input, ElementRef, Renderer2,   } from '@angular/core';
import { DomController } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'shrinking-segment-header',
  templateUrl: 'shrinking-segment-header.html',
  styleUrls: ['shrinking-segment-header.scss'],
})
export class ShrinkingSegmentHeader {

  @Input('scrollArea') scrollArea: any;
  @Input('headerHeight') headerHeight: number;
  @Input('hideOnHeight') hideOnHeight: number;
  @Input('headerContent') headerContent: any;

  newHeaderHeight: any;

  constructor(public element: ElementRef, public renderer: Renderer2, private domCtrl: DomController) {

  }

  ngAfterViewInit(){

    this.renderer.setStyle(this.element.nativeElement, 'height', this.headerHeight + 'px');

    this.scrollArea.ionScroll.subscribe((ev) => {
      this.resizeHeader(ev);
    });

    // });
    // this.renderer.setStyle(this.element.nativeElement, 'height', this.headerHeight + 'px');

    // if(this.scrollSub) this.scrollSub.unsubscribe();
    // this.scrollSub = this.scrollArea.ionScroll.subscribe((ev) => {
    //   console.dir(ev)

    //   this.newHeaderHeight = this.headerHeight - Math.round(ev.detail.scrollTop + 2 * 0.7) ;
    //   console.log(this.headerHeight)

    //   if(this.newHeaderHeight < 0){
    //     this.newHeaderHeight = 0;
    //   }   

    //   console.dir(this.endElement)

    //   if(this.shrink && ev.detail.isScrolling && !this.isEndOfContent()) 
    //     this.resizeHeader(this.newHeaderHeight);
    // });

  }

  async resizeHeader(ev){

    var height = "innerHeight" in window
  ? window.innerHeight
  : document.documentElement.offsetHeight;

    this.domCtrl.write(async () => {

      let currentPosition = ev.srcElement.offsetHeight + ev.detail.scrollTop

      // the ion content has its own associated scrollElement
      const scrollElement = await this.scrollArea.getScrollElement()
      let scrollHeight = scrollElement.scrollHeight;

      this.newHeaderHeight = this.headerHeight - (ev.detail.scrollTop * 1);

      if(this.newHeaderHeight < 0){
        this.newHeaderHeight = 0;
      }   

      if(this.newHeaderHeight <= this.hideOnHeight){
        this.renderer.setStyle(this.headerContent, 'display', 'none');
      }else{
        this.renderer.setStyle(this.headerContent, 'display', 'block');
      }

      if(currentPosition < scrollHeight - height){
        this.renderer.setStyle(this.element.nativeElement, 'height', this.newHeaderHeight + 'px');
      }
    });

  }

  // ngOnDestory(){
  //   if(this.scrollSub) this.scrollSub.unsubscribe();
  // }

  // isEndOfContent(){

  //   return false;
  // }

  // resizeHeader(height){

  //   this.domCtrl.write(() => {

  //     // console.log(this.headerHeight)
  //     // console.log(Math.round(ev.detail.scrollTop * 0.7))
      

  //     this.renderer.setStyle(this.element.nativeElement, 'height', this.newHeaderHeight + 'px');
  //   });

  // }

}