import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { StockModalComponent } from './stock-balance/stock.component';
import { TimePickerComponent } from './cart-items/time-picker/timePicker.component';
import { LanguagePipe } from './pipes/language.pipe';
import { EditItemComponent } from './cart-items/edit-items/editItem.component';
import { CurrencyPipe } from './pipes/currency.pipe';
import { ActiveOrderComponent } from './active-orders/active-order/active-order';
import { AccountPage } from './account/account';
import { OrderTicketComponent } from './active-orders/order-ticket/orderTicket.component';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { CartItemsComponent } from './cart-items/cart-items';
import { SwishDialogComponent } from './payment/swish-dialog/swish-dialog.component';


@NgModule({
  declarations: [
    StockModalComponent,
    TimePickerComponent,
    LanguagePipe,
    CurrencyPipe,
    EditItemComponent,
    ActiveOrderComponent,
    AccountPage,
    OrderTicketComponent,
    CartItemsComponent,
    SwishDialogComponent
  ],
  entryComponents: [TimePickerComponent, EditItemComponent, ActiveOrderComponent, AccountPage, OrderTicketComponent],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule
  ],
  exports: [
    StockModalComponent,
    TimePickerComponent, LanguagePipe, CurrencyPipe,
    EditItemComponent, ActiveOrderComponent, AccountPage,
    CartItemsComponent,
    SwishDialogComponent
  ],
  providers: [
    LanguagePipe,
    CurrencyPipe,
    Deploy
  ]
})
export class SharedComponentsModule { }
