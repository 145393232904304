import { HttpClient } from "@angular/common/http";
import { 
    LoadingController,
    ModalController,
    Platform
  } from "@ionic/angular";
import { PubqConfig } from "../../pubq-config/pubq-config";
import { AddCardComponent } from "../../../payment/add-card/addCard.component"
import { CardPaymentProvider } from "../cardPaymentProvider";
import { OrderProvider } from "../../order/order";
import { CartProvider } from "../../cart/cart.service";
import { ClientContextData } from "../../context/context";
import { UserPaymentSource, UserProvider } from "../../user/user";
import { AppConfiguration } from "../../../app.configuration";
let appSettings = require("../../../../../cordova-scripts/appsettings");
export class ReepayGateway {

    constructor(
        private http: HttpClient, 
        private config: PubqConfig, 
        private merchantConfig: any, 
        private loadingController: LoadingController,
        private modalCtrl: ModalController,
        private cardPaymentProvider: CardPaymentProvider,
        private orderProvider: OrderProvider,
        private cartProvider: CartProvider,
        private userProvider: UserProvider
        ) {

    }

    ///
    /// Uses Reepay Javascript SDK for embedded checkout
    ///
    pay_embedded(options: ReepayPaymentOptions): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let loading = await this.loadingController.create({
                spinner: "bubbles"
            });
            loading.present();
            
            try {
                let response: any = await this.createChargeSession(options);
                loading.dismiss();
                console.dir(response);
                

                if(!response.success){
                    resolve(response);
                    return;
                }

                if (options.order.totalCost === 0) {
                    resolve(response);
                    return;
                }

                let id = response.providerData.id;
                
                // Show modal
                let modal = await this.modalCtrl.create({
                    component: AddCardComponent,
                    componentProps: {sessionId: id},
                    cssClass: "modal-drawer-small",
                    swipeToClose: true,
                    backdropDismiss: true,
                });
                modal.present();

                let result = await modal.onDidDismiss();

                if (result.role === "confirm") {
                    resolve(result.data);
                }

                resolve({error: true});
                loading.dismiss();
            } catch (error) {
                resolve({error: {data:error}});
                loading.dismiss();
            }
        });
    }

    ///
    /// Create session id in backend to be used with checkout flows
    ///
    async createChargeSession(options: any){

        // let url = `${this.config.ApiUrl}/payments/v1/createCheckoutSession`;
        // let body = {
        //     routeName: options.routeName,
        //     user: {
        //         id: options.userId,
        //         firstName: options.firstName,
        //         lastName: options.lastName,
        //     },
        //     order: options.order,
        //     orderText: options.orderText,
        //     cardOnFile: options.cardOnFile,
        //     locale: options.locale
        // };

        const _user = this.userProvider.currentUser.value;
        console.log("--------------------------------")
        console.dir(appSettings && appSettings.version ? appSettings.version : 'N/A')
        console.log("--------------------------------")
        let clientContextData: ClientContextData = {
            user: {
                id: _user.id,
                isAnonymous: _user.isAnonymous,
                firstName: _user.firstName,
                lastName: _user.lastName,
                deviceToken: _user.deviceToken,
                email: _user.email,
                phoneNumber: _user.phoneNumber,
                preferredLocale: _user.preferedLanguage ? _user.preferedLanguage : AppConfiguration.defaultLanguage,
                origin: _user.origin,
              },
              origin: window.location.origin,
              locale: AppConfiguration.defaultLanguage,
              appVersion: appSettings && appSettings.version ? appSettings.version : 'N/A',
              appProtocol: `${window.location.origin}/u/${options.routeName}/cart?payment=stripe`
        }

        let _options: any = {
            paymentSource: this.orderProvider.paymentOfChoice, 
            order: options.order, 
            clientContextData: clientContextData,
        }

        if(!_options.paymentSource || typeof _options.paymentSource === 'string' || _options.paymentSource instanceof String) 
            _options.paymentSource = <UserPaymentSource>{ action : 'newcard', recurringReference: null, nickname: "TBD", type: "reepay" };

        if(_options.paymentSource && _options.paymentSource.recurringReference && _options.paymentSource.recurringReference === undefined)
            _options.paymentSource.recurringReference = null;

        const idempotencyKey = this.cartProvider.idempotencyKey;
        const orderId = this.cartProvider.orderId;
        
        // return this.http.post<any>(url, body).toPromise();
        return await this.cardPaymentProvider.initiate(_options, idempotencyKey, orderId)
    }
}

export interface ReepayEventData{
    id: string;           // The current session id
    invoice: string;      // Invoice/charge handle
    customer: string;     // Customer handle
    subscription: string;     // Subscription handle
    payment_method: string;   // Payment method if a new one is created
    error: string;        // The error code
  }

export interface ReepayPaymentOptions {
    userId: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    order: any;
    tipAmount?: number;
    routeName: string;
    cardOnFile: string;
    orderText: string;
    locale?: string;
}