import { Injectable } from "@angular/core";
import { ContextProvider, ClientInfo } from "../context/context";
import { AngularFireDatabase } from "@angular/fire/database";
import { Coupon, CartItem, TabCartItem } from "src/app/cart-items/cart-items";
import { UserPaymentSource } from "../user/user";
import { ItemFunctions } from "../itemFunctions";
import { BonusProvider, Voucher } from '../bonus/bonus';
import { CouponGoblin } from '../payment/payment';
import { Storage } from "@ionic/storage";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class OrderProvider {
  food = new PartialOrder();
  drinks = new PartialOrder();
  appInfo: any;
  currentActiveOrder = new Order();
  orderWaitingForApproval = new Order();
  tabOrder = new Order();
  tabItemsWaitingForApproval: {
    [key: string]: TabCartItem[];
  } = {};
  tabBonusVoucherWaitingForApporval: {
    [key: string]: { voucher: Voucher, amountUsedInPurchase: number }
  } = {};
  reepayData;
  paymentOfChoice: UserPaymentSource;
  timeLeftOfActiveOrder: CountdownTimer;
  momsCategories;
  lastUsedCustomerTabKey: string;
  activatedOfferIds = [];
  preSelectedTableNumber: string;
  selectedTableNumber = new BehaviorSubject(0);
  constructor(
    private contextProvider: ContextProvider,
    public db: AngularFireDatabase,
    private bonusProvider: BonusProvider,
    private storage: Storage
  ) {
    this.contextProvider.clientContext.currentClient.subscribe(x => {
      this.clearActivatedOffers();
    });
    this.contextProvider.clientContext.appInfo.subscribe((appInfo) => {
      this.appInfo = appInfo;
    });
  }
  async setLastUsedTabKey(tabKey) {
    this.lastUsedCustomerTabKey = tabKey;
    let routeName = this.contextProvider.clientContext.currentClient.value.routeName;
    return await this.storage.set(`${routeName}_tabkey`, tabKey);
  }
  selectTableNumber(number: number) {
    this.orderWaitingForApproval.tableNumber = number;
    this.selectedTableNumber.next(number);
  }
  getLastUsedTabKey() {
    return new Promise((resolve, reject) => {
      let sub = this.contextProvider.clientContext.currentClient.subscribe(async x => {
        resolve(await this.storage.get(`${x.routeName}_tabkey`));
        setTimeout(() => {
          sub.unsubscribe();
        }, 300);
      });
    });

  }

  activateOffer(offer) {
    this.activatedOfferIds.push(offer.id);
  }
  deActivateOffer(offer) {
    this.activatedOfferIds.splice(this.activatedOfferIds.indexOf(offer.id), 1);
  }
  clearActivatedOffers() {
    this.activatedOfferIds = [];
  }
  isOfferActivated(offerId) {
    return this.activatedOfferIds.find((x) => x === offerId);
  }
  async applyPreSelectedOffers(items: CartItem[], deliveryTypeId: number) {
    let offers = this.bonusProvider.globalOffers.value;
    let activatedOffers = [];
    offers.forEach(x => {
      this.activatedOfferIds.forEach(id => {
        if (x.id === id)
          activatedOffers.push(x);
      })
    });
    let ineligibleOffers = [];
    let numberOfActivatedOffers = 0;
    activatedOffers.forEach(offer => {
      let itemsAppliedOffer = this.applyOffer(offer, items, deliveryTypeId);
      if (itemsAppliedOffer) {
        numberOfActivatedOffers++;
        items = itemsAppliedOffer;
      }
      else {
        this.deActivateOffer(offer);
        ineligibleOffers.push(offer);
        numberOfActivatedOffers--;
      }

    });
    return { items, ineligibleOffers, numberOfActivatedOffers };
  }
  applyOffer(offer, items: CartItem[], deliveryTypeId: number) {
    if (this.offerHasDeliveryType(offer, deliveryTypeId)) {
      items.forEach(item => {
        this.applyOfferToItemIfValid(item, offer);
      });
      return items;
    }
    else
      return null;
  }
  applyOfferWithoutDeliverype(offer, items: CartItem[]) {
    items.forEach(item => {
      this.applyOfferToItemIfValid(item, offer);
    });
    return items;

  }
  applyOfferToSingleItem(offer, item) {
    let i = this.applyOfferToItemIfValid(item, offer);
    return i;
  }

  removeOffer(offer, items: CartItem[]) {
    let itemsWithOffer = items.filter(x => x.appliedOffer && x.appliedOffer.id === offer.id);
    itemsWithOffer.forEach(x => {
      x.discountPercent = 0;
      x.discountValue = 0;
      x.totalPrice = this.getTotalCostOfItemExclDiscount(x);
      x.appliedOffer = null;
    });
    return items;
  }
  applyOfferToItemIfValid(item, offer) {
    if (item.type.containsAlcohol) return; //Lets not mess with alcohol vouchers just yet
    let typeIsValid = this.offerHasProductType(offer, item.type.productTypeId);
    let productCategoryIsValid = item.type.productCategoryType && this.offerHasProductCategory(offer, item.type.productCategoryType.id);
    let productIdIsValid = this.offerHasProductId(offer, item.key);
    if (typeIsValid || productCategoryIsValid || productIdIsValid) {
      if (!item.discountPercent || item.discountPercent < offer.discountPercent) {
        item.discountPercent = parseFloat(offer.discountPercent);
        item.discountValue = Math.round(CouponGoblin.valueOfCoupon(this.getTotalCostOfItemExclDiscount(item), offer.discountPercent));
        item.totalPrice = Math.round(this.getTotalCostOfItemExclDiscount(item) - item.discountValue);
        item.appliedOffer = offer;
      }
    }
    return item;
  }
  offerHasProductCategory(offer, productCategoryId) {
    let productCategoryIsValid = typeof (offer.productCategories.find(x => x.id == productCategoryId)) !== "undefined";
    return productCategoryIsValid;
  }
  offerHasProductId(offer, productId) {
    let productIdIsValid = typeof (offer.productIds.find(x => x.key == productId)) !== "undefined";
    return productIdIsValid;
  }
  offerHasProductType(offer, productTypeId) {
    let typeIsValid = typeof (offer.productTypes.find(x => x.id == productTypeId)) !== "undefined";
    return typeIsValid;
  }
  offerHasDeliveryType(offer, deliveryTypeId) {
    let deliveryTypeIsValid = typeof (offer.deliveryTypes.find(x => x.id == deliveryTypeId)) !== "undefined";
    return deliveryTypeIsValid;
  }
  getOrders(): PartialOrder[] {
    return [this.drinks, this.food];
  }
  clearOrder(keyName) {
    this[keyName] = new PartialOrder();
  }
  getDrinkItems(cartItems) {
    var drinkItems = [];
    for (var i = 0; i < cartItems.length; i++) {
      if (cartItems[i].Category.startsWith("0")) {
        continue;
      }
      drinkItems.push(cartItems[i]);
    }
    return drinkItems;
  }
  getFoodItems(cartItems) {
    const foodItems = [];
    for (let i = 0; i < cartItems.length; i++) {
      if (cartItems[i].Category.startsWith("0")) {
        foodItems.push(cartItems[i]);
      }
    }
    return foodItems;
  }
  getTotalCostOfItemExclDiscount(item: CartItem) {
    return ((item.Cost + ItemFunctions.costOfItemOptions(item))) * (item.NrOfItems ? item.NrOfItems : 1);
  }
  addOrder(order: Order) {
    this.currentActiveOrder = order;
    if (order.order.drinks && order.order.drinks.length > 0) {
      this.drinks.orderContents = order.order.drinks;
    }
    if (order.order.food && order.order.food.length > 0) {
      this.food.orderContents = order.order.food;
    }
  }

  startOrderTimer(estimatedFinishTimeString) {
    const finished = new Date(estimatedFinishTimeString);
    this.timeLeftOfActiveOrder = new CountdownTimer(finished);
    this.timeLeftOfActiveOrder.start();
  }
  stopOrderTimer() {
    this.timeLeftOfActiveOrder.stop();
    this.timeLeftOfActiveOrder = null;
  }

}
export class Order {
  id?: string;
  version: string;
  appType: string;
  userId: string;
  orderCode: string;
  order: OrderContainer;
  date: number;
  dateISO: string;
  deliveryDate: number;
  tableNumber: number;
  client: ClientInfo;
  momsRegNr: string;
  deviceToken: string;
  deviceToken_v2: { token: string; projectId: string };
  totalItems: number;
  totalCost: number;
  totalFoodCost: number;
  totalDrinksCost: number;
  totalFoodItems: number;
  totalDrinkItems: number;
  estimatedFinishTime: Date;
  customerComment: string;
  coupon: Coupon;
  bonusVoucher: { amountUsedInPurchase: number, voucher: any; };
  payment?: any;
  deliveryComment?: string;
  deliveryRadius?: any;
  deliveryAddress?: string;
  deliveryLat?: number;
  deliveryLong?: number;
  deliveryFee?: number;
  phoneNumber?: string;
  userName?: string;
  tabKey?: string;
  orderType?: string;
  tipAmount?: number;
  receiptOnly?: boolean;
}
export class ActiveOrder {
  key: string;
  queueId: string;
  completed: string;
  foodCompleted: string;
  drinksCompleted: string;
  order: Order;
}
export class OrderContainer {
  food: CartItem[];
  drinks: CartItem[];
}
export class PartialOrder {
  completed = false;
  orderContents = [];
}
export class PartialOrderContainer {
  drinks: PartialOrder;
  food: PartialOrder;
}



export class CountdownTimer {
  private minutes: number;
  private seconds: number;
  display: string;
  totalSecondsLeft: number;
  totalSecondsDifference: number;
  totalSecondsStart: number;
  private timerPromise;
  constructor(public date: Date) { }
  start() {
    const totalSeconds = Math.floor(
      (this.date.getTime() - new Date().getTime()) / 1000
    );
    this.totalSecondsStart = totalSeconds > 0 ? totalSeconds : 100;
    this.timerPromise = setInterval(this.timerTick, 1000, this);
  }
  stop() {
    clearInterval(this.timerPromise);
    this.timerPromise = null;
    this.display = "00:00";
    this.minutes = 0;
    this.seconds = 0;
    this.totalSecondsDifference = 100;
  }
  reset() { }
  private getPrettyDisplayString() {
    const prettyMinutesString =
      this.minutes < 10 ? "0" + this.minutes : this.minutes;
    const prettySecondsString =
      this.seconds < 10 ? "0" + this.seconds : this.seconds;
    return prettyMinutesString + ":" + prettySecondsString;
  }
  private timerTick(thisTimer: CountdownTimer) {
    if (thisTimer.minutes === 0 && thisTimer.seconds === 0) return;
    const now = new Date();
    if (thisTimer.date < now) {
      thisTimer.stop();
      return;
    }
    const totalSeconds = Math.floor(
      (thisTimer.date.getTime() - now.getTime()) / 1000
    );
    thisTimer.minutes = Math.floor(totalSeconds / 60);
    thisTimer.seconds = totalSeconds % 60;
    thisTimer.display = thisTimer.getPrettyDisplayString();
    thisTimer.totalSecondsLeft = totalSeconds;
    thisTimer.totalSecondsDifference =
      thisTimer.totalSecondsStart - totalSeconds;
  }
}