import { Component } from '@angular/core';
import { NavParams, ModalController, AlertController, ToastController } from '@ionic/angular';
import * as moment from 'moment';
import { CartItem } from 'src/app/cart-items/cart-items';
import { LanguagePipe } from 'src/app/pipes/language.pipe';
import { ItemFunctions } from 'src/app/services/itemFunctions';
import { LanguageProvider } from 'src/app/services/language/language.provider';
import { ActiveOrder } from 'src/app/services/order/order';
import { ThemeProvider } from 'src/app/services/theme/theme-provider.service';

@Component({
  selector: 'page-order-ticket',
  templateUrl: 'orderTicket.html',
  styleUrls: ['../active-order/active-order.scss']
})
export class OrderTicketComponent {
  activeOrder: ActiveOrder;
  completedItems: CartItem[];
  partiallyCompletedType: string;
  deliveryMessage;
  deliveryTitle;
  dateView;
  constructor(public params: NavParams,
    public modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private languagePipe: LanguagePipe,
    public languageProvider: LanguageProvider,
    public themeProvider: ThemeProvider,
    private toastCtrl: ToastController
  ) {
    window.onpopstate = (event) => {
      this.modalCtrl.dismiss();
      window.onpopstate = null;      
    }
    

    this.activeOrder = params.get('order');
    moment.locale('sv-se');
    this.dateView = moment(this.activeOrder.order.date * 1000).format('LLLL');
    const completedFood: CartItem[] = params.get('completedFood') ? params.get('completedFood') : [];
    const completedDrinks: CartItem[] = params.get('completedDrinks') ? params.get('completedDrinks') : [];
    if (completedFood.length > 0 && completedDrinks.length > 0) {
      this.deliveryTitle = this.languagePipe.transform(this.languagePipe.transform('Din order är redo', 'ACTIVE_ORDER_READY', this.languageProvider.currentLanguage), 'ORDER_READY', this.languageProvider.currentLanguage);
    } else if (completedFood.length > 0) {
      this.deliveryTitle = this.languagePipe.transform(this.languagePipe.transform('Din mat är redo', 'ACTIVE_ORDER_FOOD_READY', this.languageProvider.currentLanguage), 'FOOD_READY', this.languageProvider.currentLanguage);
    } else if (completedDrinks.length > 0) {
      this.deliveryTitle = this.languagePipe.transform('Din dryck är redo', 'ACTIVE_ORDER_DRINK_READY', this.languageProvider.currentLanguage);
    }
    if (this.activeOrder.order.tableNumber === -1) {
      this.deliveryTitle = this.languagePipe.transform('Här är din biljett', 'ACTIVE_ORDER_TICKET', this.languageProvider.currentLanguage);
    }
    this.completedItems = completedFood.concat(completedDrinks);
    switch (this.activeOrder.order.tableNumber) {
      case 0: {
        this.deliveryMessage = this.languagePipe.transform('Visa upp den här skärmen vid disken', 'ACTIVE_ORDER_SHOW_SCREEN', this.languageProvider.currentLanguage);
        break;
      }
      case -1: {
        this.deliveryMessage = this.languagePipe.transform('Visa upp den här skärmen vid disken', 'ACTIVE_ORDER_SHOW_SCREEN', this.languageProvider.currentLanguage);
        break;
      }
      case -2: {
        this.deliveryMessage = this.languagePipe.transform('Den lämnar restaurangen nu och är påväg mot dig!', 'ACTIVE_ORDER_HOME_DELIVERY_OTW', this.languageProvider.currentLanguage);
        break;
      }
      default:
        {
          this.deliveryMessage = this.languagePipe.transform('Den levereras strax till ditt bord', 'ACTIVE_ORDER_TABLE_DELIVERY_OTW', this.languageProvider.currentLanguage);
        }
    }
  }
  ngOnInit() {
    this.toastCtrl.dismiss();
  }
  getTotalItemCost(item: CartItem) {
    return (item.Cost + ItemFunctions.costOfItemOptions(item)) * item.NrOfItems;
  }
  // cancel() {
  //   this.modalCtrl.dismiss();
  // }
  async ok() {
    const confirm = await this.alertCtrl.create({
      header: this.languagePipe.transform('Har du fått din order?', 'ACTIVE_ORDER_RECIEVED_Q', this.languageProvider.currentLanguage),
      subHeader: this.languagePipe.transform('Detta fönster försvinner om du väljer \'Ja\'', 'ACTIVE_ORDER_CLOSE_WINDOW', this.languageProvider.currentLanguage),
      buttons: [
        {
          text: this.languagePipe.transform('Nej', 'NO', this.languageProvider.currentLanguage),
          handler: () => {
            return null;
          }
        },
        {
          text: this.languagePipe.transform('Ja', 'YES', this.languageProvider.currentLanguage),
          handler: () => {
            this.modalCtrl.dismiss('ok');
          }
        }
      ],
      backdropDismiss: false
    });
    confirm.present();
  }
}
